import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useIntl } from "react-intl";
import { useIonToast } from "@ionic/react";
import { lazy, Suspense } from "react";

import AiApi from "../../../api/ai";
import SkeletonText from "../../SkeletonText";

const TextEditor = lazy(() => import("../../common/text-editor"));

export default function CustomerContext() {
  const queryClient = useQueryClient();
  const intl = useIntl();
  const [present] = useIonToast();

  const {
    data: aiData,
    isLoading: isGettingAi,
    isRefetching: isRefetchingAi,
  } = useQuery({
    queryFn: () => AiApi.getCustomerContext(),
    queryKey: ["getCustomerContext"],
    refetchOnWindowFocus: false,
  });
  

  const mutateUpdateText =
    useMutation({
      mutationFn: AiApi.createUpdateCustomerContext,
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: ["getCustomerContext"] });

        return present({
          message: intl.formatMessage({ id: "aiUpdateSucces" }),
          duration: 4000,
          color: "light",
          position: "top",
          cssClass: "success-toast",
        });
      },
      onError: () => {
        return present({
          message: intl.formatMessage({ id: "common.tryItLater" }),
          duration: 5000,
          color: "light",
          position: "top",
          cssClass: "error-toast",
        });
      },
    });

  const isRequesting = isGettingAi || isRefetchingAi || mutateUpdateText.isPending;
  const textEmbedding = aiData?.embedding || null;

  return (
    isRequesting ? (
      <SkeletonText rows={10} />
    ) : (
      <Suspense fallback={<SkeletonText rows={10} />}>
        <TextEditor
          text={textEmbedding?.embededContent || null}
          onSave={(embededContent) => mutateUpdateText.mutate({
            embededContent,
            embeddingId: textEmbedding?._id,
          })}
          maxLength={3000}
          searchBar={false}
        />
      </Suspense>
    )
  )
}
