import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useIntl } from "react-intl";
import { useIonToast } from "@ionic/react";
import { lazy, Suspense } from "react";

import AiApi from "../../../api/ai";
import SkeletonText from "../../SkeletonText";

const TextEditor = lazy(() => import("../../common/text-editor"));

export default function Instructions() {
  const queryClient = useQueryClient();
  const intl = useIntl();
  const [present] = useIonToast();

  const {
    data: aiData,
    isLoading: isGettingAi,
    isRefetching: isRefetchingAi,
  } = useQuery({
    queryFn: () => AiApi.getAiSettings(),
    queryKey: ["getAiSettings"],
    refetchOnWindowFocus: false,
  });
  

  const mutateUpdateSystemPrompt =
    useMutation({
      // @ts-ignore
      mutationFn: AiApi.putAiSettingsByFieldV2,
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ["getAiSettings"],
        });
        return present({
          message: intl.formatMessage({ id: "aiUpdateSucces" }),
          duration: 4000,
          color: "light",
          position: "top",
          cssClass: "success-toast",
        });
      },
      onError: () => {
        return present({
          message: intl.formatMessage({ id: "common.tryItLater" }),
          duration: 5000,
          color: "light",
          position: "top",
          cssClass: "error-toast",
        });
      },
    });

  const isRequesting = isGettingAi || isRefetchingAi || mutateUpdateSystemPrompt.isPending;
  const systemPrompt = aiData?.data?.aiSettings?.systemPrompt || null;

  return (
    isRequesting ? (
      <SkeletonText rows={10} />
    ) : (
      <Suspense fallback={<SkeletonText rows={10} />}>
        <TextEditor
          text={systemPrompt}
          onSave={(newPrompt) => mutateUpdateSystemPrompt.mutate({
            field: "systemPrompt",
            value: newPrompt,
            embeddingId: undefined
          })}
          searchBar={false}
          maxLength={3000}
        />
      </Suspense>
    )
  )
}
