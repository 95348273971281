import { create } from "zustand";
import { devtools } from "zustand/middleware";

export type UrlWithStatus = {
  url: string;
  completed: boolean;
  error: boolean;
  active?: boolean;
  embeddingId?: string;
  source?: string;
  embededContent?: string;
  _id?: string;
  failed?: boolean;
};

export type FullCrawl = {
  urls?: UrlWithStatus[];
  progress?: number;
  isOptimizing?: boolean;
  isScanning?: boolean;
  failed?: boolean;
};

export type CrawlingInitialState = {
  fullCrawl: FullCrawl
  singleCrawl: UrlWithStatus[]
}

interface CrawlingState {
  fullCrawl: FullCrawl
  singleCrawl: UrlWithStatus[]
  setFullCrawl: (fullCrawl: FullCrawl) => void;
  setSingleCrawl: (singleCrawl: UrlWithStatus[]) => void;
  reset: () => void;
};

const crawlingInitialState: CrawlingInitialState = {
  fullCrawl: {
    urls: undefined,
    progress: 0,
    isOptimizing: false,
    isScanning: false,
    failed: undefined,
  },
  singleCrawl: [],
};

const useCrawlingStore = create(
  devtools<CrawlingState>(
    (set, get) => ({
      ...crawlingInitialState,
      setFullCrawl: (fullCrawl) => {
        const fullCrawlState = get().fullCrawl;
        return set({ fullCrawl: { ...fullCrawlState, ...fullCrawl } })
      },
      setSingleCrawl: (singleCrawl) => {
        return set({ singleCrawl });
      },
      reset: () => set({ ...crawlingInitialState })
    }),
    { name: "useCrawlingStore" }
  ),
);

export const useCrawlingData = () =>
  useCrawlingStore((state) => ({
    fullCrawl: state.fullCrawl,
    singleCrawl: state.singleCrawl,
  }));

export const useCrawlingActions = () =>
  useCrawlingStore((state) => ({
    setFullCrawl: state.setFullCrawl,
    setSingleCrawl: state.setSingleCrawl,
    reset: state.reset,
  }))

export default useCrawlingStore;

