import { IconClock, IconCreditCard } from "@tabler/icons-react";
import { IonHeader, IonToolbar } from "@ionic/react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Settings } from "../interfaces";
import classNames from "classnames";
import "./NavBanner.css";

const NavBanner: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const { plan, payment } = props.settings.status;
  const { mode, hide } = props;

  const { messagesLeft } = plan;
  const { pastDue, retries } = payment;

  const hasMessagesLeft = messagesLeft <= 0 && !pastDue;

  return mode === "desktop" ? (
    messagesLeft > 0 && !pastDue ? null : (
      <div
        className={classNames("navbanner", {
          trial: hasMessagesLeft, // display yellow
          payment: pastDue, // Display red
        })}
      >
        <div className="navbanner-item">
          <IconCreditCard size={16} />
        </div>
        <div className="navbanner-item">
          <MessagesBanner
            messagesLeft={messagesLeft}
            pastDue={pastDue}
            retries={retries}
          ></MessagesBanner>
        </div>
      </div>
    )
  ) : messagesLeft > 0 && !pastDue ? null : hide ? null : (
    <IonHeader>
      <IonToolbar
        className={classNames("navbanner", {
          trial: hasMessagesLeft, // display yellow
          payment: pastDue, // Display red
        })}
      >
        <Link to="/settings/payment" className="link-unset">
          <div className="navbanner">
            <div className="navbanner-item">
              <IconCreditCard size={16} />
            </div>
            <MessagesBannerMobile
              messagesLeft={messagesLeft}
              pastDue={pastDue}
            ></MessagesBannerMobile>
          </div>
        </Link>
      </IonToolbar>
    </IonHeader>
  );
};

const MessagesBanner: React.FC<any> = ({ messagesLeft, pastDue, retries }) => {
  let title;
  let description;

  if (pastDue) {
    title = "nav.pastDue";
    description = "nav.pastDueCTA";
  } else if (messagesLeft <= 0) {
    title = "nav.payment";
    description = "nav.paymentCTA";
  }
  return (
    <span className="fs-2 fw-semibold-1">
      <>
        <FormattedMessage
          values={{
            count: retries,
          }}
          id={title}
        />{" "}
        <Link to="/settings/payment" className="link-unset fw-semibold">
          <FormattedMessage id={description} />
        </Link>
      </>
    </span>
  );
};

const MessagesBannerMobile: React.FC<any> = ({ messagesLeft, pastDue }) => {
  let title;

  if (pastDue) {
    title = "nav.pastDue";
  } else if (messagesLeft <= 0) {
    title = "nav.payment";
  }

  return (
    <div className="navbanner-item">
      <FormattedMessage id={title} />
    </div>
  );
};

interface ILayoutProps {
  settings: Settings;
  mode: string;
  hide?: boolean;
}

export default NavBanner;
