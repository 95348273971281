const moment = require("moment");

export function getTimeStampForMessage(time) {
  const momentize = moment(time);
  return momentize.format("HH:mm");
}

export function getTimeStampForContact(time) {
  const momentize = moment(time);

  function logics(date) {
    const YESTERDAY = moment().subtract(1, "days").startOf("day");
    const LASTWEEK = moment().subtract(7, "days").startOf("day");
    return {
      yesterday: momentize.isSame(YESTERDAY, "d"),
      older: momentize.isBefore(YESTERDAY, "d"),
      mucholder: momentize.isBefore(LASTWEEK, "d"),
    };
  }

  const flag = logics(time);

  if (flag.yesterday) {
    return "yesterday";
  }

  if (flag.mucholder) {
    return momentize.format("DD/MM/YYYY");
  }

  if (flag.older) {
    return momentize.format("dddd");
  }

  return momentize.format("HH:mm");
}

export function getTimeStampForMessageGrouping(time) {
  const momentize = moment(time);

  function logics(date) {
    const YESTERDAY = moment().subtract(1, "days").startOf("day");
    const LASTWEEK = moment().subtract(7, "days").startOf("day");
    return {
      yesterday: momentize.isSame(YESTERDAY, "d"),
      older: momentize.isBefore(YESTERDAY, "d"),
      mucholder: momentize.isBefore(LASTWEEK, "d"),
    };
  }

  const flag = logics(time);

  if (flag.yesterday) {
    return "Yesterday";
  }

  if (flag.mucholder) {
    return momentize.format("DD/MM/YYYY");
  }

  if (flag.older) {
    return momentize.format("dddd");
  }

  return "Today";
}

export function getTodayPlus(plus, format) {
  return moment().add(plus, "days").startOf("day").format(format);
}

export function parseDateTimeZone(date, format) {
  return moment(date).format(format);
}

export function parseDate(date, format) {
  return moment.utc(date).format(format);
}

export function today() {
  return moment().toDate();
}

export function momentToday() {
  return moment();
}

export function getTodayForFilter() {
  const currentDate = moment();

  return `${currentDate.format("YYYY-MM-DD")},${currentDate.format(
    "YYYY-MM-DD"
  )}`;
}

export function parseDateForFilter(date) {
  return moment(date).format("YYYY-MM-DD");
}

export function getCurrentWeek() {
  const currentDate = moment();
  const weekStart = currentDate.clone().startOf("isoWeek");
  const weekEnd = currentDate.clone().endOf("isoWeek");

  return `${weekStart.format("YYYY-MM-DD")},${weekEnd.format("YYYY-MM-DD")}`;
}

export function getCurrentMonth() {
  const currentDate = moment();
  const monthStart = currentDate.clone().startOf("month");
  const monthEnd = currentDate.clone().endOf("month");

  return `${monthStart.format("YYYY-MM-DD")},${monthEnd.format("YYYY-MM-DD")}`;
}

export function getLastMonth() {
  const monthStart = moment().subtract(1, "months").startOf("month");
  const monthEnd = moment().subtract(1, "months").endOf("month");

  return `${monthStart.format("YYYY-MM-DD")},${monthEnd.format("YYYY-MM-DD")}`;
}

export function getLastDays({ days = 7 }) {
  const currentDate = moment();
  const startDate = moment().subtract(days, "days");

  return `${startDate.format("YYYY-MM-DD")},${currentDate.format(
    "YYYY-MM-DD"
  )}`;
}

export function parseFilteredDates(date) {
  const [start, end] = date.split(",");

  const parsedStart = moment(start).toDate();
  const parsedEnd = moment(end).toDate();

  return [parsedStart, parsedEnd];
}

export function formatPlanDates(date) {
  return moment(date).format("D MMM YYYY");
}
