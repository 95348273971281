import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useState, useMemo } from "react";
import { IconTrash } from "@tabler/icons-react";
import { FormattedMessage, useIntl } from "react-intl";
import { IonAlert, IonSpinner, useIonToast } from "@ionic/react";

import AiApi from "../../../api/ai";
import SkeletonText from "../../SkeletonText";
import PdfPreview from "../../common/pdf-viewer";
import ImagePreview from "../../common/image-viewer";
import { Embedding, GlobalError } from "../../../interfaces";

import File from "./file";
import Alert from "../../common/alert";

import styles from "./styles.module.css";
import websiteStyles from "../website/styles.module.css";
import globalOutboundStyles from "../../outbound/styles.module.css";

const MAX_FILE_SIZE = 15 * 1024 * 1024; // 15MB en bytes
const MAX_ACTIVE_FILES = 30;

export default function Files() {
  const [newFile, setNewFile] = useState<File | null>(null);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [currentEmbedding, setCurrentEmbedding] = React.useState<{ fileName: string; embeddingId: string }>();

  const [present] = useIonToast();
  const intl = useIntl();
  const queryClient = useQueryClient();

  const isPDF = useMemo(() => newFile?.type?.includes("pdf"), [newFile]);
  const isImage = useMemo(() => newFile?.type?.startsWith("image/"), [newFile]);

  const {
    data: statusData,
  } = useQuery({
    queryFn: () => AiApi.getAiStatus(),
    queryKey: ["getAiStatus"],
    refetchOnWindowFocus: false,
    staleTime: 300000,
  });

  const { data, isLoading } = useQuery({
    queryFn: AiApi.getFiles,
    queryKey: ["getAiFiles"],
    refetchOnWindowFocus: false,
  });

  const files = data?.embeddings || [];
  const isMaxFiles = useMemo(() => files?.filter((f: any) => f.active)?.length >= MAX_ACTIVE_FILES, [files]);

  const refreshData = async () => {
    const queriesToInvalidate = ["getAiFiles"];
  
    if (!statusData?.status?.files) {
      queriesToInvalidate.push("getAiStatus");
    }
  
    await Promise.all(queriesToInvalidate.map(queryKey =>
      queryClient.invalidateQueries({ queryKey: [queryKey] })
    ));
  };

  const mutateProcessFile =
    useMutation({
      mutationFn: AiApi.processFile,
      onSuccess: async (data) => {
        if (data.error) {
          return present({
            message: data.error?.[intl.locale],
            duration: 5500,
            color: "light",
            position: "top",
            cssClass: "error-toast",
          });
        }
        await refreshData();
        present({
          message: intl.formatMessage({ id: "aiUpdateSucces" }),
          duration: 6000,
          color: "light",
          position: "top",
          cssClass: "success-toast",
        });
        setNewFile(null);
        return;
      },
      onError: (e: GlobalError) => {
        if (e?.error) {
          return present({
            message: e.error?.[intl.locale],
            duration: 5500,
            color: "light",
            position: "top",
            cssClass: "error-toast",
          });
        }
        setNewFile(null);
        return present({
          message: intl.formatMessage({ id: "common.tryItLater" }),
          duration: 5000,
          color: "light",
          position: "top",
          cssClass: "error-toast",
        });
      },
    });

  const mutateDeleteFile =
    useMutation({
      mutationFn: AiApi.deleteFile,
      onSuccess: async () => {
        setCurrentEmbedding(undefined);

        const queriesToInvalidate = ["getAiFiles"];
        if (files.length === 1) {
          queriesToInvalidate.push("getAiStatus");
        }

        await Promise.all(
          queriesToInvalidate.map(queryKey =>
            queryClient.invalidateQueries({ queryKey: [queryKey] })
          )
        );

        return present({
          message: intl.formatMessage({ id: "aiUpdateSucces" }),
          duration: 4000,
          color: "light",
          position: "top",
          cssClass: "success-toast",
        });
      },
      onError: () => {
        setCurrentEmbedding(undefined);
        return present({
          message: intl.formatMessage({ id: "common.tryItLater" }),
          duration: 5000,
          color: "light",
          position: "top",
          cssClass: "error-toast",
        });
      },
    });

  const mutateActivateFile =
    useMutation({
      mutationFn: AiApi.patchFile,
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ["getAiFiles"],
        });
        return present({
          message: intl.formatMessage({ id: "aiUpdateSucces" }),
          duration: 4000,
          color: "light",
          position: "top",
          cssClass: "success-toast",
        });
      },
      onError: () => {
        return present({
          message: intl.formatMessage({ id: "common.tryItLater" }),
          duration: 5000,
          color: "light",
          position: "top",
          cssClass: "error-toast",
        });
      },
    });

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;

    if (file && file.size > MAX_FILE_SIZE) {
      (e.target as HTMLInputElement).value = "";
      present({
        message: intl.formatMessage({ id: "ai.sources.files.limit" }),
        duration: 5500,
        color: "light",
        position: "top",
        cssClass: "error-toast",
      });
      return;
    }

    setNewFile(file);
  };

  const onDeleteLocalFile = () => setNewFile(null);

  const onSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (newFile) {
      const formData = new FormData();
      formData.append("files", newFile);
      if (!statusData?.status?.trained) {
        formData.append("updateTrain", "true");
      }
      mutateProcessFile.mutate(formData);
    }
  }

  const onBeforeDeleteEmbedding = (data: { embeddingId: string; fileName: string }) => {
    setCurrentEmbedding(data);
    setOpenAlert(true);
  }

  const onDeleteEmbedding = () => {
    if (currentEmbedding) {
      setOpenAlert(false);
      mutateDeleteFile.mutate(currentEmbedding);
    }
  }

  const onHideAlert = () => {
    setCurrentEmbedding(undefined);
    return setOpenAlert(() => false);
  };

  const renderFileUpload = () => (
    <label
      htmlFor="new-file"
      className={`${styles.inputLabel} flex flex-column align-items-center justify-content-center description`}
    >
      <p className="mb-0">
        <FormattedMessage id="ai.sources.files.upload.label"  />
      </p>
      <input
        id="new-file"
        type="file"
        className="display-none"
        accept=".pdf, .csv, .jpeg, .jpg, .png, application/pdf, text/csv, image/jpeg, image/png"
        onChange={handleFileChange}
      />
    </label>
  );

  const renderFilePreview = () => {
    if (isMaxFiles) return (
      <div className="flex flex-column gap-3">
        <Alert
          type="danger"
          className="p-3"
          message={intl.formatMessage({ id: "ai.sources.files.max" }, { limit: MAX_ACTIVE_FILES })}
        />
      </div>
    );

    if (!newFile) return renderFileUpload();
  
    if (isPDF) {
      return (
        <PdfPreview
          file={newFile}
          onDelete={onDeleteLocalFile}
          disableDelete={mutateProcessFile.isPending}
        />
      );
    }
  
    if (isImage) {
      return (
        <ImagePreview
          file={newFile}
          onDelete={onDeleteLocalFile}
          disableDelete={mutateProcessFile.isPending}
        />
      );
    }
  
    return (
      <div
        className={`${styles.inputLabel} flex flex-column gap-2 align-items-center justify-content-center cursor-default`}
      >
        <p className="mb-0">{newFile.name}</p>
        {!mutateProcessFile.isPending && (
          <button
            type="button"
            onClick={onDeleteLocalFile}
            disabled={mutateProcessFile.isPending}
            className="flex align-items-center justify-content-end gap-1 color-danger bg-color-0"
          >
            <FormattedMessage id="ai.sources.files.delete" />
            <IconTrash size={20} />
          </button>
        )}
      </div>
    );
  };

  const isRequesting = isLoading || mutateDeleteFile.isPending;

  return (
    isRequesting ? (
      <SkeletonText rows={10} />
    ) : (
      <div className="w-100 flex flex-column gap-3">
        {renderFilePreview()}
  
        {newFile && (
          <button
            className="btn btn-visito-primary w-100"
            onClick={onSubmit}
            disabled={mutateProcessFile.isPending}
            type="submit"
          >
            {mutateProcessFile.isPending ? (
              <IonSpinner
                name="circular"
                style={{
                  color: "var(--ion-color-light)",
                  width: "19px",
                  height: "19px",
                }}
              />
            ) : (
              <FormattedMessage id="ai.sources.files.upload" />
            )}
          </button>
        )}

        {!newFile && files && files.length > 0 && (
          <div className="flex flex-column gap-5 w-100 h-100">
            <div className={websiteStyles.siteMapContainer}>
              <h3 className="fs-3 px-4 py-3 mb-0">
                <FormattedMessage id="ai.sources.files.analyzed" />
              </h3>
              <div className={`${websiteStyles.linksContainer} flex flex-column gap-3`}>
                {files.map((f: Embedding) => (
                  <File
                    file={f}
                    key={f.url}
                    onDelete={onBeforeDeleteEmbedding}
                    onEnableFile={(data) => mutateActivateFile.mutate(data)}
                  />
                ))}
              </div>
            </div>
          </div>
        )}
        <IonAlert
          isOpen={openAlert}
          header={intl.formatMessage({ id: "ai.sources.files.delete.confirmation" })}
          message={intl.formatMessage({ id: "ai.sources.files.delete.confirmationDesc" })}
          buttons={[
            {
              text: intl.formatMessage({ id: "common.cancel" }),
              role: "cancel",
              handler: onHideAlert,
              cssClass: `${globalOutboundStyles.alertCancel} ${globalOutboundStyles.alertBtn}`,
            },
            {
              text: intl.formatMessage({ id: "common.confirmDelete" }),
              role: "confirm",
              handler: onDeleteEmbedding,
              cssClass: `${globalOutboundStyles.alertConfirm} ${globalOutboundStyles.alertBtn}`,
            },
          ]}
          onDidDismiss={onHideAlert}
        />
      </div>
    )
  );
}
