import React, { useRef, useState, useEffect, useCallback } from "react";
import { CallServerEvent, Audio as CustomAudio } from "../../../interfaces";
import { WavRecorder, WavStreamPlayer } from "./wavtools/index.js";
import { WavRenderer } from "./wavRenderer";
import { base64ToArrayBuffer, arrayBufferToBase64 } from "./utils";
import CallModal from "./call-modal";
const ringer = require("../../../sounds/dialing.mp3");
import "./styles.css";
import { FormattedMessage } from "react-intl";
import { useSockets } from "../../../providers/socket";

interface Props {
  onCall: Function;
  onHangUp: Function;
  className: string;
  icon: React.ReactNode;
  contactId?: string;
  playgroundId?: string | undefined;
  displayName: string;
  type?: string;
}

const Call: React.FC<Props> = ({
  onHangUp,
  onCall,
  playgroundId,
  contactId,
  className,
  icon,
  displayName,
  type,
}) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isVisitoActive, setIsVisitoActive] = useState<boolean>(false);
  const playgroundIdRef = useRef<string>(playgroundId || "");
  const contactIdRef = useRef<string>(contactId || "");
  const dialToneRef = useRef<HTMLAudioElement | null>(null);
  const clientCanvasRef = React.useRef<HTMLCanvasElement>(null);
  const serverCanvasRef = React.useRef<HTMLCanvasElement>(null);

  const { userSocket } = useSockets();

  const wavRecorderRef = useRef<WavRecorder>(
    new WavRecorder({ sampleRate: 24000 })
  );

  const wavStreamPlayerRef = useRef<WavStreamPlayer>(
    new WavStreamPlayer({ sampleRate: 24000 })
  );

  useEffect(() => {
    dialToneRef.current = new Audio(ringer);
    dialToneRef.current.loop = true;
    return () => {
      if (dialToneRef.current) {
        dialToneRef.current.pause();
        dialToneRef.current.currentTime = 0;
      }
    };
  }, []);

  useEffect(() => {
    if (playgroundId) {
      playgroundIdRef.current = playgroundId;
    }
  }, [playgroundId]);

  useEffect(() => {
    if (contactId) {
      contactIdRef.current = contactId;
    }
  }, [contactId]);

  const connectConversation = useCallback(async () => {
    const wavRecorder = wavRecorderRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;
    onCall();
    setIsActive(true);
    dialToneRef.current?.play();
    try {
      // Connect to microphone
      await wavRecorder.begin();
      // Connect to audio output
      await wavStreamPlayer.connect();
      await wavRecorder.record((data) => {
        if (data.mono) {
          onEvent({
            audio: {
              format: "base64",
              // @ts-ignore
              payload: arrayBufferToBase64(data.mono as ArrayBuffer),
            },
            eventName: "call",
          });
        }
      });
      onEvent({ eventName: "dial" });
    } catch (e) {
      dialToneRef.current?.pause();
      console.log(e);
      setIsActive(false);
      disconnectConversation();
    }
  }, []);

  const disconnectConversation = useCallback(async () => {
    onEvent({ eventName: "hangup" });
    onHangUp();
    setIsActive(false);
    setIsVisitoActive(false);
    dialToneRef.current?.pause();
    try {
      const wavRecorder = wavRecorderRef.current;
      await wavRecorder.end();

      const wavStreamPlayer = wavStreamPlayerRef.current;
      await wavStreamPlayer.interrupt();
    } catch (e) {
      console.log(e);
    }
  }, []);

  const interruptConversation = useCallback(async () => {
    const wavStreamPlayer = wavStreamPlayerRef.current;
    const trackSampleOffset = await wavStreamPlayer.interrupt();
    if (trackSampleOffset?.trackId) {
      const { trackId, offset } = trackSampleOffset;
      console.log(trackId, offset);
    }
  }, []);

  useEffect(() => {
    if (!userSocket) return;
    const handleAudioEvent = (data: CallServerEvent) => {
      if (
        data.event === "audio" &&
        data.audio?.format === "base64" &&
        wavRecorderRef.current.recording
      ) {
        const { itemId, payload } = data.audio;
        const audio = base64ToArrayBuffer(payload);
        wavStreamPlayerRef.current.add16BitPCM(audio, itemId);
      }

      if (data.event === "connected") {
        setIsVisitoActive(true);
        dialToneRef.current?.pause();
      }

      if (data.event === "close") {
        disconnectConversation();
      }

      if (data.event === "interrupt") {
        interruptConversation();
      }
    };

    userSocket.on("call-event", (data) => {
      handleAudioEvent(data);
    });

    return () => {
      console.log("cleaning up call socket");
      userSocket.off("call-event", handleAudioEvent);
    };
  }, [userSocket]);

  const onEvent = ({
    eventName,
    audio,
  }: {
    eventName: string;
    audio?: CustomAudio;
  }) => {
    if (!userSocket) return; // Ensure the socket is available
    const event = {
      playgroundId: playgroundIdRef.current,
      contactId: contactIdRef.current,
      audio,
      type,
    };
    userSocket.emit(eventName, event);
  };

  useEffect(() => {
    let isLoaded = true;

    const wavRecorder = wavRecorderRef.current;
    const clientCanvas = clientCanvasRef.current;
    let clientCtx: CanvasRenderingContext2D | null = null;

    const wavStreamPlayer = wavStreamPlayerRef.current;
    const serverCanvas = serverCanvasRef.current;
    let serverCtx: CanvasRenderingContext2D | null = null;

    const render = () => {
      if (isLoaded) {
        if (clientCanvas) {
          if (!clientCanvas.width || !clientCanvas.height) {
            clientCanvas.width = clientCanvas.offsetWidth;
            clientCanvas.height = clientCanvas.offsetHeight;
          }
          clientCtx = clientCtx || clientCanvas.getContext("2d");
          if (clientCtx) {
            clientCtx.clearRect(0, 0, clientCanvas.width, clientCanvas.height);
            const result = wavRecorder.recording
              ? wavRecorder.getFrequencies("voice")
              : { values: new Float32Array([0]) };
            WavRenderer.drawBars(
              clientCanvas,
              clientCtx,
              result.values,
              "#b3c6ff",
              10,
              0,
              8
            );
          }
        }
        if (serverCanvas) {
          if (!serverCanvas.width || !serverCanvas.height) {
            serverCanvas.width = serverCanvas.offsetWidth;
            serverCanvas.height = serverCanvas.offsetHeight;
          }
          serverCtx = serverCtx || serverCanvas.getContext("2d");
          if (serverCtx) {
            serverCtx.clearRect(0, 0, serverCanvas.width, serverCanvas.height);
            const result = wavStreamPlayer.analyser
              ? wavStreamPlayer.getFrequencies("voice")
              : { values: new Float32Array([0]) };
            WavRenderer.drawBars(
              serverCanvas,
              serverCtx,
              result.values,
              "#c1c3cc",
              10,
              0,
              8
            );
          }
        }
        window.requestAnimationFrame(render);
      }
    };
    render();
    return () => {
      isLoaded = false;
    };
  }, [isVisitoActive]);

  // isRecording -> Microphone in local computer is connected
  // isActive -> User has clicked on Call button
  // isVisitoActive -> Visito's socket is ready

  const { recording } = wavRecorderRef.current;
  return (
    <>
      <div style={{ display: "flex", gap: "1em" }}>
        {!isActive ? (
          <button className={className} onClick={connectConversation}>
            {icon}
          </button>
        ) : recording ? (
          <button
            className={`${className} callActive`}
            onClick={disconnectConversation}
          >
            {icon}
          </button>
        ) : (
          <button className={className} disabled={true}>
            <FormattedMessage id="aiSettingsCalling" /> ...
          </button>
        )}
      </div>
      <CallModal
        isOpen={isActive}
        onHangUp={() => disconnectConversation()}
        isDialing={isActive && recording && !isVisitoActive}
        isConnected={isVisitoActive}
        clientCanvasRef={clientCanvasRef}
        serverCanvasRef={serverCanvasRef}
        displayName={displayName}
      />
    </>
  );
};

export default Call;
