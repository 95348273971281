import { FormattedMessage, useIntl } from "react-intl";
import { useEffect, useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonTitle,
  IonModal,
  IonButtons,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonToggle,
  InputChangeEventDetail,
  IonInput,
  IonAlert,
} from "@ionic/react";
import styles from "../styles.module.css";
import { Integration } from "../../../interfaces";
import { IconTrash } from "@tabler/icons-react";
import { Connection } from "../../../interfaces";

interface Properties {
  isOpen: boolean;
  onWillDismiss: () => void;
  integration: Integration;
  toggle: Function;
  onReconnect: Function;
  onDelete: Function;
  isMobile: boolean;
}

interface FormData {
  disabled: boolean;
  key: string;
  name?: string;
  propertyId?: string;
  widgetId?: string;
  publisher?: string;

  currency?: string;
  countryCode?: string;
  timezone?: string;
  phoneNumber?: number | null;
}

const IntegrationDetails = ({
  onWillDismiss,
  onDelete,
  isOpen,
  integration,
  toggle,
  onReconnect,
  isMobile,
}: Properties) => {
  const intl = useIntl();

  const [openAlert, setOpenAlert] = useState(false);
  const [formsData, setFormsData] = useState<FormData[]>([]);
  const [connection, setConnection] = useState<Connection | null>(null);

  const handleChange = (
    key: string,
    name: keyof FormData,
    e: CustomEvent<InputChangeEventDetail>
  ) => {
    const value = e.detail.value || "";
    const newFormData = formsData.map((f) => {
      if (f.key === key) {
        return {
          ...f,
          [name]: value,
        };
      }
      return f;
    });
    setFormsData(newFormData);
  };

  const toggleForm = (key: string) => {
    const newFormData = formsData.map((f) => {
      if (f.key === key) {
        return {
          ...f,
          disabled: !f.disabled,
        };
      }
      return f;
    });
    setFormsData(newFormData);
  };

  const onDisplayAlert = async (connection: Connection) => {
    setConnection(connection);
    return setOpenAlert(() => true);
  };

  const onDisableEvent = async () => {
    return onDelete({ integration, connection });
  };

  const onHideAlert = () => {
    setConnection(null);
    setOpenAlert(false);
  };

  useEffect(() => {
    if (integration) {
      const accounts = integration.accounts;
      if (accounts && accounts.length > 0) {
        // set the form data for each of the accounts
        const newFormData = accounts.map((account) => {
          return {
            disabled: true,
            key: account.id,
            name: account.name,
            propertyId: account.propertyId || "",
            widgetId: account.widgetId || "",
            currency: account.currency || "",
            countryCode: account.countryCode || "",
            timezone: account.timezone || "",
          };
        });
        setFormsData(newFormData);
      }
    }
  }, [integration]);
  const { cssLogo, key, accounts, editFields } = integration;
  return (
    <>
      <IonModal
        isOpen={isOpen}
        onWillDismiss={onWillDismiss}
        id="integrations-modal"
      >
        <IonHeader>
          <IonToolbar>
            <IonTitle>
              {isMobile ? (
                <FormattedMessage id={`integrations.${key}Name`} />
              ) : (
                <div className={styles.titleDisplay}>
                  <div className={`${styles.titleItem} ${styles.logo}`}>
                    <div className={`${styles.logo} ${cssLogo}`}></div>
                  </div>

                  <h3 className="fs-3">
                    <FormattedMessage id={`integrations.${key}Name`} />
                  </h3>
                </div>
              )}
            </IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => onWillDismiss()}>
                <FormattedMessage id="common.close" />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div className={"view-wrapper"}>
            {accounts.map((connection) => (
              <IonList inset={true} key={connection.id}>
                <IonItem>
                  <IonLabel>
                    <b>
                      {connection.name || (
                        <FormattedMessage id={`integrations.${key}Name`} />
                      )}{" "}
                    </b>
                  </IonLabel>
                  <div slot="end">
                    <button
                      className="btn btn-xs btn-visito-link-light"
                      onClick={() => onDisplayAlert(connection)}
                    >
                      <IconTrash size={18} />
                    </button>
                  </div>
                </IonItem>
                <IonItem>
                  <IonToggle
                    {...(isMobile
                      ? { className: "visito-toggle" }
                      : { mode: "ios", className: "visito-toggle ios" })}
                    checked={connection.active}
                    onIonChange={(event: CustomEvent<{ checked: boolean }>) =>
                      toggle({
                        connection,
                        action: { active: event.detail.checked },
                      })
                    }
                  >
                    <IonLabel>
                      {(() => {
                        switch (integration.type) {
                          case "channel":
                            return connection.active ? (
                              <FormattedMessage id="integrations.autoReplies" />
                            ) : (
                              <FormattedMessage id="integrations.manualReplies" />
                            );
                          case "location":
                            return connection.active ? (
                              <FormattedMessage id="integrations.pmsActive" />
                            ) : (
                              <FormattedMessage id="integrations.pmsPaused" />
                            );
                          default:
                            return null;
                        }
                      })()}
                    </IonLabel>
                  </IonToggle>
                </IonItem>

                {key === "wa" && (
                  <>
                    <IonItem>
                      <IonLabel>
                        <FormattedMessage id="integrations.waSettings" />
                      </IonLabel>
                      <div slot="end">
                        <a
                          href={`https://business.facebook.com/latest/whatsapp_manager/phone_numbers/?nav_ref=whatsapp_manager&asset_id=${connection.id}`}
                          target="_blank"
                          className="btn btn-xs btn-visito-link"
                          rel="noreferrer"
                        >
                          <FormattedMessage id="common.setup" />
                        </a>
                      </div>
                    </IonItem>
                    <IonItem>
                      <IonLabel>
                        <FormattedMessage id="integrations.waPaymentMethod" />
                      </IonLabel>
                      <div slot="end">
                        <a
                          href={`https://business.facebook.com/latest/settings/whatsapp_account/?selected_asset_id=${connection.id}&selected_asset_type=whatsapp-business-account`}
                          target="_blank"
                          className="btn btn-xs btn-visito-link"
                          rel="noreferrer"
                        >
                          <FormattedMessage id="common.setup" />
                        </a>
                      </div>
                    </IonItem>
                  </>
                )}

                {editFields.map(({ key, type }) => {
                  switch (type) {
                    case "text":
                    case "number":
                      const formEntry = formsData.find(
                        (f) => f.key === connection.id
                      );
                      const value = formEntry?.[key as keyof FormData];

                      return (
                        <IonItem key={key}>
                          <IonInput
                            label-placement="stacked"
                            disabled={formEntry?.disabled}
                            name={key}
                            mode="ios"
                            label={intl.formatMessage({
                              id: `integrations.${key}Db`,
                            })}
                            placeholder={intl.formatMessage({
                              id: `integrations.${key}DbPlaceholder`,
                            })}
                            value={
                              typeof value === "string" ||
                              typeof value === "number"
                                ? value
                                : ""
                            }
                            onIonInput={(e) =>
                              handleChange(
                                connection.id,
                                key as keyof FormData,
                                e
                              )
                            }
                          />
                          <div slot="end">
                            {!formEntry?.disabled && (
                              <button
                                className="btn btn-xs btn-visito-link"
                                onClick={() => {
                                  formEntry?.disabled
                                    ? toggleForm(connection.id)
                                    : toggle({
                                        connection,
                                        action: { [key]: value },
                                      });
                                }}
                              >
                                <FormattedMessage id="common.save" />
                              </button>
                            )}
                            <button
                              className={
                                formEntry?.disabled
                                  ? "btn btn-xs btn-visito-light"
                                  : "btn btn-xs btn-visito-light"
                              }
                              onClick={() => toggleForm(connection.id)}
                            >
                              <FormattedMessage
                                id={
                                  formEntry?.disabled
                                    ? "common.edit"
                                    : "common.cancel"
                                }
                              />
                            </button>
                          </div>
                        </IonItem>
                      );
                  }
                })}
              </IonList>
            ))}
            <IonList inset={true}>
              <IonItem onClick={() => onReconnect()} button={true}>
                <IonLabel>
                  <div className="fs-3 color-step-500 color-visito-1 underline">
                    <FormattedMessage id={`integrations.${key}Reconnect`} />
                  </div>
                </IonLabel>
              </IonItem>
            </IonList>
          </div>
        </IonContent>
      </IonModal>
      <IonAlert
        id="alert-delete-integration"
        isOpen={openAlert}
        header={intl.formatMessage({ id: "integrations.deleteTitle" })}
        message={intl.formatMessage({ id: "integrations.deleteDesc" })}
        buttons={[
          {
            text: intl.formatMessage({ id: "common.cancel" }),
            role: "cancel",
            handler: onHideAlert,
            cssClass: styles.alertCancel,
          },
          {
            text: intl.formatMessage({ id: "common.confirmDelete" }),
            role: "confirm",
            handler: onDisableEvent,
            cssClass: styles.alertConfirm,
          },
        ]}
        onDidDismiss={onHideAlert}
      />
    </>
  );
};
export default IntegrationDetails;
