import { combineReducers } from "@reduxjs/toolkit";
import { App } from "./app";
import { Send } from "./send";

const rootReducer = combineReducers({
  app: App,
  send: Send,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
