import { useEffect, useState } from "react";
import { connect } from "react-redux";
import PlanUsage from "../../../../components/PlanUsage";

import { STRIPE } from "../../../../config";

import { Settings, User } from "../../../../interfaces";
import { FormattedMessage } from "react-intl";

const Billing: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const { user } = props;
  const { payment, plan } = props.settings.status;

  useEffect(() => {
    props.onRequestSettings();
  }, []);

  return (
    <div
      className={
        !payment.active ? `settings-w-presentation` : `settings-presentation`
      }
    >
      <h4>
        <FormattedMessage id="payment.title" />
      </h4>
      <p className="color-step-700 fs-3">
        <FormattedMessage id="payment.subTitle" />
      </p>
      <hr />
      {!payment.active ? (
        <div className="mt-4">
          <stripe-pricing-table
            pricing-table-id={STRIPE.pricingTable}
            publishable-key={STRIPE.key}
            client-reference-id={user.company.id}
          />
        </div>
      ) : (
        <div>
          <div className="fs-2 fw-semibold-1 mb-2">
            {" "}
            <FormattedMessage id="payment.currentPlan" />
          </div>
          <div className="mt-2">
            <div className="fs-2 color-step-800">
              {plan.name || <FormattedMessage id="common.active" />}
            </div>
            <div className="fs-2 color-step-80">{payment.email}</div>
            {plan.name ? (
              <a
                href={STRIPE.loginDashboard || ""}
                target="_blank"
                className="btn btn-visito-light btn-sm mt-3"
              >
                <FormattedMessage id="payment.managePlan" />
              </a>
            ) : null}
            <hr />
            {plan.totalMessages >= 0 && plan.messageLimit && (
              <PlanUsage
                plan={plan}
                path={location.pathname}
                isMobile={false}
              ></PlanUsage>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

interface ILayoutProps {
  dispatch: Function;
  settings: Settings;
  user: User;
  onRequestSettings: Function;
}

export default connect((props: ILayoutProps) => ({}))(Billing);
