import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonItem,
  IonLabel,
  IonList,
} from "@ionic/react";

import { FormattedMessage } from "react-intl";

import { IconMessageChatbot } from "@tabler/icons-react";
import headerStyles from "../../../theme/header.module.css";

const Header = () => {
  return (
    <div>
      <div className={headerStyles.header}>
        <h1 className={headerStyles.mobileTitle}>
          <FormattedMessage id="ai.pageTitle" />
        </h1>
        <h3 className={headerStyles.mobileDescription}>
          <FormattedMessage id="ai.pageDescription" />
        </h3>
      </div>
    </div>
  );
};

const AiHome: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  return (
    <IonPage id="main-content">
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            <FormattedMessage id="ai.pageTitle" />
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen color="light">
        <div className="view-wrapper">
          <div className="view-pane">
            <Header />
          </div>

          <div className="view-pane-title mt-4">
            {" "}
            <FormattedMessage id="ai.contextTitle" />
          </div>

          <div className="view-pane-title mt-4">
            {" "}
            <FormattedMessage id="ai.playgroundTitle" />
          </div>
          <IonList inset={true}>
            <IonItem button routerLink={`/ai-playground`} detail={true}>
              <div slot="start">
                <IconMessageChatbot />
              </div>
              <IonLabel>
                <FormattedMessage id="ai.playgroundDescription" />
              </IonLabel>
            </IonItem>
          </IonList>
        </div>
      </IonContent>
    </IonPage>
  );
};

interface ILayoutProps {}

export default AiHome;
