import React from "react";
import { useIonToast } from "@ionic/react";
import { useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { IconRefresh, IconPhoneCall } from "@tabler/icons-react";

import PlaygroundApi from "../../api/playground";
import SkeletonText from "../SkeletonText";
import ChatDetail from "../ChatDetail";
import ChatInput from "../common/chat-input";
import { useSockets } from "../../providers/socket";

import Call from "../common/call";

import { Message, Settings, ChatInputRef } from "../../interfaces";

// import headerStyles from "../../theme/header.module.css";
import styles from "./styles.module.css";
import { useSelectContactById } from "../../store/contacts";
import useContactsStore from "../../store/contacts";

function AiSettings({ settings }: { settings: Settings }) {
  const [content, setContent] = React.useState<string>();
  const [replyTo, setReplyTo] = React.useState<Message | null>(null);
  const [contactId, setContactId] = React.useState<string>();
  const [playgroundId, setPlaygroundId] = React.useState<string>();
  const chatInputRef = React.useRef<ChatInputRef>(null);
  const { userSocket, emitEventUser } = useSockets();
  const { updateContactsDb } = useContactsStore();
  const intl = useIntl();

  const selectedChat = useSelectContactById(String(contactId));
  const [present] = useIonToast();

  const {
    data: sendResponse,
    mutate: mutateSendMessage,
    // isPending: isSendingMessage,
  } = useMutation({
    mutationFn: PlaygroundApi.sendMessage,
    onSuccess: (data) => {
      if (!data.ok) {
        return present({
          message: intl.formatMessage({ id: "common.tryItLater" }),
          duration: 6000,
          color: "light",
          position: "top",
        });
      }
    },
    onError: () => {
      return present({
        message: intl.formatMessage({ id: "common.tryItLater" }),
        duration: 6000,
        color: "light",
        position: "top",
      });
    },
  });

  const { mutate: mutateClearPlayground, isPending: isClearingPlayground } =
    useMutation({
      mutationFn: PlaygroundApi.clearPlayground,
      onError: () => {
        return present({
          message: intl.formatMessage({ id: "common.tryItLater" }),
          duration: 6000,
          color: "light",
          position: "top",
        });
      },
    });

  const onChangeContent = (newContent: string) => setContent(newContent);

  const onBeforeClearPlayground = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    if (contactId) {
      mutateClearPlayground({ contactId });
    }
  };

  const onSubmit = () => {
    if (content && playgroundId) {
      mutateSendMessage({
        playgroundId,
        content,
        replyTo: replyTo?.id,
      });
    }
  };

  const onReply = (message: Message, content: string) => {
    if (content && playgroundId) {
      mutateSendMessage({
        playgroundId,
        content,
        replyTo: message ? message.id : undefined,
      });
    }
  };

  React.useEffect(() => {
    if (sendResponse && sendResponse?.ok) {
      setContent("");
      setReplyTo(null);
      setContactId(sendResponse.contactId);
    }
  }, [sendResponse]);

  React.useEffect(() => {
    setContactId(settings.playground.contactId);
    setPlaygroundId(settings.playground.id);
  }, [settings.playground]);

  React.useEffect(() => {
    if (contactId) {
      emitEventUser({ name: "requests", data: { request: { contactId } } });
    }
  }, [contactId]);

  const focusChatInput = () => chatInputRef.current?.setInputFocus();

  React.useEffect(() => {
    if (!userSocket) return;
    const handleNewMessage = (message: any) => {
      if (message.data) {
        console.log("setting up new data: ", message.data.length);
        updateContactsDb(message.data);
      }
    };

    // Attach event listener
    userSocket.on("new-message", handleNewMessage);

    // Cleanup: Remove event listener when component unmounts
    return () => {
      console.log("cleaning up playground handlers...");
      userSocket.off("new-message", handleNewMessage);
    };
  }, [userSocket]);

  return (
    <div className="w-100 h-100">
      <div className={styles.playgroundWrapper}>
        {!settings.playground.id ? (
          <div className={styles.chatWrapper}>
            <div className={styles.chatSkeleton}>
              <SkeletonText rows={15} />
            </div>
          </div>
        ) : (
          <div className={styles.chatWrapper}>
            <div className={styles.chatContainer}>
              <div className={styles.chatHeaderContainer}>
                <div className={styles.chatHeaderContent}>
                  <div className="flex gap-3 align-items-center">
                    <h3 className="fs-4 mb-0">
                      <FormattedMessage id="aiAgent" />
                    </h3>
                  </div>

                  <div className="flex gap-2 align-items-center">
                    <button
                      className="btn btn-visito-primary btn-xs btn-flex"
                      onClick={onBeforeClearPlayground}
                      disabled={isClearingPlayground}
                    >
                      <IconRefresh size={15} />
                      <FormattedMessage id="aiSettingsReset" />
                    </button>

                    {settings.isVoiceReady && contactId ? (
                      <Call
                        className={"btn btn-visito-primary btn-xs btn-flex"}
                        icon={
                          <>
                            <IconPhoneCall size={15} />
                            <FormattedMessage id="aiSettingsCall" />
                          </>
                        }
                        onCall={() => console.log("calling")}
                        onHangUp={() => console.log("hanging up")}
                        playgroundId={playgroundId}
                        displayName={settings.company.name}
                        type={"playground"}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="h-100 w-100">
                <ChatDetail
                  messages={selectedChat.messages}
                  openImage={(link) => console.log("Open image", link)}
                  contactId={selectedChat.contact.id}
                  mode={"desktop"}
                  scrollEscalations={1}
                  type="playground"
                  replyTo={({ message }: { message: Message }) => {
                    setReplyTo(message);
                    focusChatInput();
                  }}
                  actionClick={({
                    message,
                    text,
                  }: {
                    message: Message;
                    text: string;
                  }) => onReply(message, text)}
                />
              </div>
              <div
                className={`${styles.chatInputContainer} ${
                  selectedChat.messages.length === 0 ? "pt-3" : ""
                }`}
              >
                <ChatInput
                  ref={chatInputRef}
                  id="send-body-playground"
                  value={content}
                  placeholder={intl.formatMessage({
                    id: "chats.newMessageInput",
                  })}
                  enableWaActions={false}
                  onInputChange={onChangeContent}
                  onBeforeSubmit={onSubmit}
                  disabled={false /* isSendingMessage */}
                  replyTo={replyTo}
                  cleanReplyTo={() => setReplyTo(null)}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default AiSettings;
