import config from "../config";
import request, {
  requestOptions,
  requestOptionsFormData,
} from "../utils/request";

const baseUrl = `${config.API_ROOT}/app/contacts`;

const Contacts = {
  search: async (string, companies) => {
    const options = requestOptions("POST", { companies }, true);
    const url = `${baseUrl}/search?q=${string}`;
    return request(url, options);
  },
  get() {
    const options = requestOptions("GET", {}, true);
    const url = `${baseUrl}/`;
    return request(url, options);
  },
  getMessages(contactId) {
    const options = requestOptions("GET", {}, true);
    const url = `${baseUrl}/${contactId}/messages`;
    return request(url, options);
  },
  postMessages(contactId, body) {
    const options = requestOptions("POST", body, true);
    const url = `${baseUrl}/${contactId}/messages`;
    return request(url, options);
  },
  uploadMedia(contactId, formData) {
    const options = requestOptionsFormData(formData, true);
    const url = `${baseUrl}/${contactId}/media`;
    return request(url, options);
  },
  readMessages(contactId, messageId) {
    const options = requestOptions("POST", {}, true);
    const url = `${baseUrl}/${contactId}/messages/${messageId}`;
    return request(url, options);
  },
  putEnabled(contactId, body) {
    const options = requestOptions("PUT", body, true);
    const url = `${baseUrl}/${contactId}/enabled`;
    return request(url, options);
  },
  putBlocked(contactId, body) {
    const options = requestOptions("PUT", body, true);
    const url = `${baseUrl}/${contactId}/blocked`;
    return request(url, options);
  },
  escalateContact(contactId, body) {
    const options = requestOptions("PUT", body, true);
    const url = `${baseUrl}/${contactId}/manual-escalation`;
    return request(url, options);
  },
  runConversational(contactId) {
    const options = requestOptions("POST", {}, true);
    const url = `${baseUrl}/${contactId}/run-converational`;
    return request(url, options);
  },
  putSolveEscalations(contactId) {
    const options = requestOptions("PUT", {}, true);
    const url = `${baseUrl}/${contactId}/solve-escalations`;
    return request(url, options);
  },
};

export default Contacts;
