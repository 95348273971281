import { useState, useEffect } from "react";
const moment = require("moment");

import {
  IonSelect,
  IonSelectOption,
  IonList,
  IonLabel,
  IonItem,
} from "@ionic/react";
import { FormattedMessage, useIntl } from "react-intl";
import { useQuery } from "@tanstack/react-query";
import { IconCalendar } from "@tabler/icons-react";
import DatePicker from "react-datepicker";
import { Settings } from "../../../interfaces";

import HomeApi from "../../../api/home";
import styles from "../styles.module.css";
import NoStats from "../../../theme/images/no-stats.svg";
import datePickerStyles from "../../../theme/pickers-selects.module.css";

import SkeletonText from "../../SkeletonText";
import Last30Activity from "../../Last30Activity";
import PlanUsage from "../../PlanUsage";

import COMMON_FILTERS, { DIMENSION_FILTERS } from "../constants";

import { Stat } from "../types";

import {
  getLastDays,
  parseFilteredDates,
  parseDateForFilter,
} from "../../../utils/moment";
const [startDate, endDate] = parseFilteredDates(getLastDays({ days: 30 }));

const DimensionFilter: React.FC<{
  selectedFilter: string;
  onChange: Function;
}> = ({ selectedFilter, onChange }) => {
  return (
    <div className="flex gap-1 align-items-center justify-content-between">
      <div
        className={`${datePickerStyles.filtersContainer} ${styles.filterAlone}`}
      >
        <IonSelect
          labelPlacement="stacked"
          className={datePickerStyles.commonFilterSelect}
          value={selectedFilter}
          style={{ width: "100%" }}
          onIonChange={(e) => onChange(e.target.value)}
        >
          {DIMENSION_FILTERS.map((filter) => (
            <IonSelectOption key={filter.value} value={filter.value}>
              <FormattedMessage id={filter.label} />
            </IonSelectOption>
          ))}
        </IonSelect>
      </div>
    </div>
  );
};

const CalendarFilter: React.FC<{
  selectedFilter: string;
  range: {
    start: Date;
    end: Date;
  };
  locale: string;
  onChangeFilter: Function;
  onChange: (dates: [Date, Date]) => void;
}> = ({ selectedFilter, range, onChangeFilter, onChange, locale }) => {
  return (
    <div className="flex gap-1 align-items-center justify-content-between">
      <div className={`${datePickerStyles.filtersContainer} ${styles.filters}`}>
        <IonSelect
          labelPlacement="stacked"
          className={`${datePickerStyles.commonFilterSelect} ${styles.filterDate}`}
          value={selectedFilter}
          onIonChange={(e) => onChangeFilter(e.target.value)}
        >
          {COMMON_FILTERS.map((filter) => (
            <IonSelectOption
              key={filter.value}
              value={filter.value}
              disabled={filter.value === "custom"}
              className={filter.value === "custom" ? "d-none" : ""}
            >
              <FormattedMessage id={filter.label} />
            </IonSelectOption>
          ))}
        </IonSelect>
        <div className={datePickerStyles.dateWrapper}>
          <IconCalendar size={17} />
          <DatePicker
            selectsRange
            locale={locale}
            className={datePickerStyles.dateInput}
            wrapperClassName="w-100"
            startDate={range.start}
            endDate={range.end}
            onChange={onChange}
            monthsShown={2}
          />
        </div>
      </div>
    </div>
  );
};

const Stats = ({ isMobile, settings }: ILayoutProps) => {
  const { locale } = useIntl();
  const [stats, setStats] = useState([]);
  const [contacts, setContacts] = useState(0);
  const [messages, setMessages] = useState(0);
  const [percentageAuto, setPercentageAuto] = useState(0);
  const [dimensionFilter, setDimensionFilter] = useState("totalMessages");
  const [dateQuery, setDateQuery] = useState(getLastDays({ days: 30 }));
  const [commonFilter, setCommonFilter] = useState(getLastDays({ days: 30 }));
  const [range, setRange] = useState({
    start: startDate,
    end: endDate,
  });

  const onChangeCommonFilter = (filter: string) => {
    const [start, end] = parseFilteredDates(filter);
    setRange((prevState) => ({ ...prevState, start, end }));
    setCommonFilter(() => filter);
    return setDateQuery(filter);
  };

  const onChangeDimension = (dimension: string) => {
    setDimensionFilter(dimension);
  };

  const onChangeDates = (dates: [Date, Date]) => {
    const [start, end] = dates;
    if (start && end) {
      setDateQuery(
        () => `${parseDateForFilter(start)},${parseDateForFilter(end)}`
      );
      setCommonFilter("custom");
    }
    return setRange((prevState) => ({ ...prevState, start, end }));
  };

  const { data: homeData, isLoading: isLoading } = useQuery({
    queryFn: () => HomeApi.get({ date: dateQuery }),
    queryKey: ["getSettings", dateQuery],
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (homeData) {
      const { data, err } = homeData;
      if (err) {
        console.log(err);
      }
      if (data) {
        const selectedStats = data.stats.map((s: Stat) => ({
          date: s.date,
          count: s[dimensionFilter as keyof Stat],
        }));
        setStats(selectedStats);
        setMessages(data.global.messages);
        setContacts(data.global.contacts);
        setPercentageAuto(data.global.percentageAuto);
      }
    }
  }, [homeData, dimensionFilter]);

  return isMobile ? (
    isLoading ? (
      <div className="view-pane">
        <SkeletonText rows={8} />
      </div>
    ) : (
      <>
        <div className="view-pane">
          <div className="fw-semibold">
            <span className="mr-1">
              <FormattedMessage id="home.statsTitle" />
            </span>
          </div>
        </div>

        <IonList inset={true}>
          <IonItem>
            <IonLabel>
              <FormattedMessage id="home.totalMessages" />
            </IonLabel>
            <div slot="end">
              <strong>{Intl.NumberFormat("en-US").format(messages)}</strong>
            </div>
          </IonItem>
          <IonItem>
            <IonLabel>
              <FormattedMessage id="home.uniqueContacts" />
            </IonLabel>
            <div slot="end">
              <strong>{Intl.NumberFormat("en-US").format(contacts)}</strong>
            </div>
          </IonItem>
          <IonItem>
            <IonLabel>
              <FormattedMessage id="home.percentageAuto" />
            </IonLabel>
            <div slot="end">
              <strong>
                {" "}
                {Intl.NumberFormat("en-US").format(percentageAuto)}
              </strong>
            </div>
          </IonItem>
        </IonList>

        <div className="view-pane">
          <div className="fw-semibold">
            <span className="mr-1">
              <FormattedMessage id="home.planTitle" />
            </span>
          </div>
        </div>
        <IonList inset={true}>
          <PlanUsage
            plan={settings.status.plan}
            path={location.pathname}
            isMobile={true}
          />
        </IonList>

        <div className="view-pane">
          <DimensionFilter
            selectedFilter={dimensionFilter}
            onChange={onChangeDimension}
          />
        </div>

        <div className="view-pane">
          <div className="view-pane-body">
            {stats.length ? (
              <div className={styles.statsHolder}>
                {<Last30Activity stats={stats} />}
              </div>
            ) : (
              <div className="flex flex-column gap-4 w-100 align-items-center justify-content-center px-3 mt-5">
                <img src={NoStats} width="200px" alt="" />
                <div className="flex flex-column gap-1">
                  <p className={`mb-0 text-center fs-4`}>
                    <FormattedMessage id="home.noStats" />
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    )
  ) : (
    <>
      <div className="col-md-9">
        <div className="mt-4 mb-4">
          <div className={styles.statsHolder}>
            <div className="visito-card visito-block">
              <div className="visito-card-body">
                <PlanUsage
                  plan={settings.status.plan}
                  path={location.pathname}
                  isMobile={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-8">
          <CalendarFilter
            selectedFilter={commonFilter}
            onChange={onChangeDates}
            onChangeFilter={onChangeCommonFilter}
            range={range}
            locale={locale}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-3">
          <div className="visito-card visito-block">
            <div className="visito-card-body">
              <div>
                <FormattedMessage id="home.totalMessages" />
              </div>
              <div className={styles.statsWidgetNumber}>
                {isLoading ? (
                  <SkeletonText rows={2} />
                ) : (
                  Intl.NumberFormat("en-US").format(messages)
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="visito-card visito-block">
            <div className="visito-card-body">
              <div>
                <FormattedMessage id="home.uniqueContacts" />
              </div>
              <div className={styles.statsWidgetNumber}>
                {isLoading ? (
                  <SkeletonText rows={2} />
                ) : (
                  Intl.NumberFormat("en-US").format(contacts)
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="visito-card visito-block">
            <div className="visito-card-body">
              <div>
                <FormattedMessage id="home.percentageAuto" />
              </div>
              <div className={styles.statsWidgetNumber}>
                {isLoading ? (
                  <SkeletonText rows={2} />
                ) : (
                  Intl.NumberFormat("en-US").format(percentageAuto)
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-9">
          <div className="flex gap-1 align-items-center justify-content-between">
            <div
              className={`${datePickerStyles.filtersContainer} ${styles.filterAlone}`}
            >
              <IonSelect
                labelPlacement="stacked"
                className={datePickerStyles.commonFilterSelect}
                value={dimensionFilter}
                style={{ width: "100%" }}
                onIonChange={(e) => onChangeDimension(e.target.value)}
              >
                {DIMENSION_FILTERS.map((filter) => (
                  <IonSelectOption key={filter.value} value={filter.value}>
                    <FormattedMessage id={filter.label} />
                  </IonSelectOption>
                ))}
              </IonSelect>
            </div>
          </div>
          <div className="mt-4 mb-4">
            {isLoading ? (
              <SkeletonText rows={6} />
            ) : stats.length ? (
              <div className={styles.statsHolder}>
                <div className="visito-card visito-block">
                  <div className="visito-card-body">
                    <Last30Activity stats={stats} />{" "}
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex flex-column gap-4 w-100 align-items-center justify-content-center px-3 mt-5">
                <img src={NoStats} width="200px" alt="" />
                <div className="flex flex-column gap-1">
                  <p
                    className={`mb-0 text-center ${isMobile ? "fs-4" : "fs-5"}`}
                  >
                    <FormattedMessage id="home.noStats" />
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

interface ILayoutProps {
  isMobile: boolean;
  settings: Settings;
}

export default Stats;
