import config from "../config";
import request, { requestOptions } from "../utils/request";

const baseUrl = `${config.API_ROOT}/app/web-chat`;

type CreateWebIdRes = {
  err?: boolean;
  data?: {
    ok: boolean;
    webChat: {
      webId: string;
    };
  };
};

const WebChat = {
  async getCompanyInfo({ id }: { id: string }) {
    const options = requestOptions("GET", {}, true);
    const url = `${baseUrl}/${id}`;
    const { data } = await request(url, options);
    return data as { ok: boolean; company?: { name: string } };
  },
  async createContact({
    webId,
    companyId,
    name,
  }: {
    webId: string;
    companyId: string;
    name: string;
  }) {
    const options = requestOptions("POST", { webId, companyId, name }, true);
    const url = `${baseUrl}/contact`;
    const res = await request(url, options);
    return res as CreateWebIdRes;
  },
  async sendMessage({
    webId,
    content,
    companyId,
    replyTo,
  }: {
    webId: string;
    content: string;
    companyId: string;
    replyTo?: string;
  }) {
    const options = requestOptions(
      "POST",
      { webId, content, companyId, replyTo },
      true
    );
    const url = `${baseUrl}/message`;
    const { data } = await request(url, options);
    return data as { ok: boolean };
  },
  async readMessages({
    webId,
    companyId,
    lastId,
  }: {
    webId: string;
    companyId: string;
    lastId: string;
  }) {
    const options = requestOptions("POST", { webId, companyId, lastId }, true);
    const url = `${baseUrl}/read`;
    const { data } = await request(url, options);
    return data as { ok: boolean };
  },
  async deliveredMessages({
    webId,
    companyId,
    lastId,
  }: {
    webId: string;
    companyId: string;
    lastId: string;
  }) {
    const options = requestOptions("POST", { webId, companyId, lastId }, true);
    const url = `${baseUrl}/delivered`;
    const { data } = await request(url, options);
    return data as { ok: boolean };
  },
};

export default WebChat;
