import { create } from "zustand";
import { devtools } from "zustand/middleware";

interface SocketStore {
  userQuery: Record<string, any>;
  webChatQuery: Record<string, any>;
  userSocketReady: boolean;
  userSocketSessionReady: boolean;
  webChatSocketReady: boolean;

  setUserSocketReady: (userSocketReady: boolean) => void;
  setUserSocketSessionReady: (userSocketSessionReady: boolean) => void;
  setWebChatSocketReady: (webChatSocketReady: boolean) => void;
  setUserQuery: (query: Record<string, any>) => void;
  setWebChatQuery: (query: Record<string, any>) => void;
}

export const useSocketStore = create(
  devtools<SocketStore>((set, get) => ({
    userQuery: {},
    webChatQuery: {},
    userSocketReady: false,
    userSocketSessionReady: false,
    webChatSocketReady: false,

    setUserSocketReady: (userSocketReady) => set({ userSocketReady }),
    setUserSocketSessionReady: (userSocketSessionReady) =>
      set({ userSocketSessionReady }),
    setWebChatSocketReady: (webChatSocketReady) => set({ webChatSocketReady }),
    setUserQuery: (query) => set({ userQuery: query }),
    setWebChatQuery: (query) => set({ webChatQuery: query }),
  }))
);
