import { IconTrash } from "@tabler/icons-react";

import styles from "./styles.module.css";

type Props = {
  file: string | File;
  disableDelete?: boolean;
  onDelete?: () => void;
};

export default function PdfPreview({ file, disableDelete = false, onDelete }: Props) {
  const fileUrl = file instanceof File ? URL.createObjectURL(file) : file;

  return (
    <div className={`w-100 flex flex-column gap-2 ${styles.pdfContainer}`}>
      {onDelete && !disableDelete && (
        <button type="button" onClick={onDelete} className="flex align-items-center justify-content-end gap-1 color-danger bg-color-0 w-max ml-auto">
          Delete File
          <IconTrash size={20} />
        </button>
      )}
      <embed
        src={`${fileUrl}#navpanes=0&view=FitH`}
        type='application/pdf'
        width='100%'
        height='100%'
      />
    </div>
  );
}
