import React, { useRef, MutableRefObject } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useVirtualizer } from "@tanstack/react-virtual";

import { IonSpinner, IonItem, IonLabel, IonList } from "@ionic/react";
import { FormattedMessage } from "react-intl";

import SkeletonLoader from "./SkeletonLoader";
import ConversationsSvg from "../theme/images/conversations.svg";

import ContactPlatform from "./common/contact-platform";

import classNames from "classnames";

import "./ChatList.css";
import { Contact, Filter } from "../interfaces";

const MotionLink = motion(IonItem);

interface ILayoutProps {
  select?: Function;
  //changeStatus: Function;
  contacts: Contact[];
  searchResults: Contact[] | false;
  selectedId?: string;
  isSearch: boolean;
  mode: string;
  filters: Filter;
  isMulti: boolean;
  loading: boolean;
  isSearching: boolean;
}

const ChatList: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const {
    select,
    contacts,
    selectedId,
    mode,
    isSearch,
    filters,
    isMulti,
    loading,
    isSearching,
    searchResults,
  } = props;

  const parentRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  let results =
    isSearch && searchResults && searchResults.length
      ? searchResults
      : isSearch
      ? []
      : contacts;

  if (filters.companies.length) {
    results = contacts.filter((c) =>
      c.companies.some((company) => filters.companies.includes(company.id))
    );
    if (filters.simple.length) {
      results = results.filter((c) =>
        filters.simple.some((filter) => c[filter as keyof Contact])
      );
    }
  } else {
    if (filters.simple.length) {
      results = contacts.filter((c) =>
        filters.simple.some((filter) => c[filter as keyof Contact])
      );
    }
  }

  const rowVirtualizer = useVirtualizer({
    count: results.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => (mode === "desktop" ? 68 : 67.5),
    overscan: 2,
  });

  const noContacts = isSearch ? (
    <div className="p-3">
      <div className="color-step-500">
        {!searchResults ? (
          <FormattedMessage id="chats.searchStart" />
        ) : (
          <FormattedMessage id="chats.searchNoResults" />
        )}
      </div>
    </div>
  ) : (
    <div className="empty-list">
      <div className="welcome-content text-center">
        <div className="row justify-content-center">
          <div className="mb-3">
            <img src={ConversationsSvg} alt=""></img>
          </div>
          <span className="color-step-900 font-14 fw-semibold-1">
            {filters.simple.length ? (
              <FormattedMessage id="chats.emptyStateEscTitle" />
            ) : (
              <FormattedMessage id="chats.emptyStateTitle" />
            )}
          </span>
          <span className="color-step-600 font-12">
            {filters.simple.length ? (
              <FormattedMessage id="chats.emptyStateEscSubtitle" />
            ) : (
              <FormattedMessage id="chats.emptyStateSubtitle" />
            )}
          </span>
        </div>
      </div>
    </div>
  );

  const Item = ({ contact }: { contact: Contact }) => {
    const {
      name,
      preview,
      timestamp,
      unread,
      type,
      phoneNumber,
      escalated,
      manual,
      blocked,
      companies,
    } = contact;

    const Item = (
      <>
        <div slot="start" className="profile">
          <ContactPlatform defaultClass="profile-platform" contact={contact} />
        </div>
        <IonLabel className="contact ion-text-nowrap">
          <p className="contact-holder">
            {isMulti &&
              companies?.map((c) => (
                <span className="contact-company" key={c.id}>
                  <span className="badge text-bg-light">{c.name}</span>
                </span>
              ))}
            <span className="contact-name fw-semibold-1">
              {name || phoneNumber}
            </span>
          </p>
          <p className="conversation-preview">
            {" "}
            {isSearch ? phoneNumber || name : preview}
          </p>
        </IonLabel>
        <div slot="end" className="details">
          <div className="details-container">
            <div className="half-item timestamp">
              {timestamp}{" "}
              {unread ? (
                <div className="notifications-badge badge color-visito-1-bg">
                  {unread}
                </div>
              ) : null}
            </div>
            <div className="half-item">
              {escalated ? (
                <div className="badge text-bg-warning">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div className="fw-semibold-1">
                      <FormattedMessage id="esc.needsReview" />
                    </div>
                  </div>
                </div>
              ) : null}
              {manual ? (
                <div className="badge text-bg-info ml-1">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div className="fw-semibold-1">
                      <FormattedMessage id="chats.manual" />
                    </div>
                  </div>
                </div>
              ) : null}
              {blocked ? (
                <div className="badge text-bg-danger">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div className="fw-semibold-1">
                      <FormattedMessage id="chats.blocked" />
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </>
    );
    return Item;
  };

  return loading ? (
    <SkeletonLoader rows={10} />
  ) : isSearching ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      className="mt-3"
    >
      <IonSpinner name="lines"></IonSpinner>
    </div>
  ) : results.length ? (
    <div className="chat-list-items-holder" ref={parentRef}>
      <div style={{ height: `${rowVirtualizer.getTotalSize()}px` }}>
        <IonList
          style={{
            padding: 0,
            margin: 0,
            width: "100%",
            position: "relative",
            height: `${rowVirtualizer.getTotalSize()}px`,
          }}
          className="contact-list"
        >
          <AnimatePresence>
            {rowVirtualizer.getVirtualItems().map((virtualItem) => {
              const { index, start, size } = virtualItem;
              const contact = results[index];
              const { id } = contact;
              const onClick = select ? () => select(id) : undefined;
              return (
                <MotionLink
                  lines="full"
                  className={classNames("list-item", {
                    selected: id === selectedId,
                  })}
                  detail={false}
                  onClick={mode === "mobile" ? undefined : onClick}
                  routerLink={mode === "mobile" ? `/chat/${id}` : undefined}
                  key={`${id}`}
                  style={{
                    position: "absolute",
                    left: 0,
                    width: "100%",
                    height: `${size}px`,
                    transform: `translateY(${start}px)`,
                  }}
                  initial={false}
                  animate={isSearch ? false : { y: start }}
                  exit={{ opacity: 0 }}
                  transition={{ ease: "linear", duration: 0.3 }}
                >
                  <Item contact={contact} />
                </MotionLink>
              );
            })}
          </AnimatePresence>
        </IonList>
      </div>
    </div>
  ) : (
    noContacts
  );
};

export default ChatList;
