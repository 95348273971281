import config from "../config";
import request, { requestOptions } from "../utils/request";

const baseUrl = `${config.API_ROOT}/app/playground`;

const Playground = {
  async sendMessage({
    playgroundId,
    content,
    replyTo,
  }: {
    playgroundId: string;
    content: string;
    replyTo?: string;
  }) {
    const options = requestOptions(
      "POST",
      { playgroundId, content, replyTo },
      true
    );
    const url = `${baseUrl}/new-playground-message`;
    const { data } = await request(url, options);
    return data as { ok: boolean; contactId: string };
  },
  async clearPlayground({ contactId }: { contactId: string }) {
    const options = requestOptions("POST", { contactId }, true);
    const url = `${baseUrl}/clear`;
    const { data } = await request(url, options);
    return data as { ok: boolean };
  },
};

export default Playground;
