import { connect } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import { Location } from "history";
import { IonRouterOutlet, IonContent } from "@ionic/react";

// Interfaces
import { User, Event, Settings } from "../../interfaces";

// Components
import NavBar from "../../components/NavBar";
import NavBanner from "../../components/NavBanner";

import Outbound from "../../components/outbound";
import OutboundLogs from "../../components/outbound/logs/desktop";
import Home from "../../components/home";
import Crm from "../../components/crm";
import CrmCampaigns from "../../components/crm/campaigns";
import CrmLeads from "../../components/crm/leads";

// Pages
import AiSettings from "./desktop/AiSettings";
import AiSettingsSystem from "./desktop/AiSettingsSystem";

import Chats from "./desktop/Chats";
//import Home from "./desktop/Home";
import SettingsPage from "./desktop/Settings";
import Integrations from "../../components/integrations";

import AiSettingsV2 from "../../components/ai";
import CrawlWebsite from "../../components/ai/website";
import FreeText from "../../components/ai/free-text";
import Files from "../../components/ai/files";
import Providers from "../../components/ai/providers";
import CustomerContext from "../../components/ai/customer";
import Instructions from "../../components/ai/instructions";
import AiLayout from "../../components/ai/layout";

import AccountNewInvitation from "../../components/account/accept-invitation";
import AccountCreation from "../../components/account/create";

const DesktopHome: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const {
    logOut,
    user,
    location,
    settings,
    onRequestSettings,
    switchCompany,
    refreshUser,
  } = props;
  return (
    <>
      <NavBanner settings={settings} mode={"desktop"} />
      <NavBar
        user={user}
        logOut={logOut}
        switchCompany={switchCompany}
        settings={settings}
        path={location.pathname}
      />

      <IonContent>
        <IonRouterOutlet>
          <Route
            exact
            path="/"
            render={() => <Home user={user} settings={settings} />}
          />
          <Route path="/chats" component={Chats} />
          <Route
            exact
            path="/outbound"
            render={() => <Outbound settings={settings} />}
          />
          <Route exact path="/integrations" component={Integrations} />
          <Route exact path="/connect" component={Integrations} />
          <Route path="/outbound/:id" component={OutboundLogs} />
          <Route exact path="/crm" component={Crm} />
          <Route exact path="/crm/campaigns" component={CrmCampaigns} />
          <Route exact path="/crm/leads" component={CrmLeads} />
          <Route
            exact
            path="/ai-settings"
            render={() => <AiSettings settings={settings} />}
          />

          {/* Temporary redirect old path to the new one */}
          <Route path="/ai-settings/:type">
            <Redirect to="/ai/customer" />
          </Route>

          <Route path="/ai">
            <AiLayout>
              <Switch>
                <Route exact path="/ai">
                  <AiSettingsV2 settings={settings} />
                </Route>
                <Route exact path="/ai/website" component={CrawlWebsite} />
                <Route exact path="/ai/text" component={FreeText} />
                <Route exact path="/ai/files" component={Files} />
                <Route exact path="/ai/providers" component={Providers} />
                <Route exact path="/ai/customer" component={CustomerContext} />
                <Route exact path="/ai/instructions" component={Instructions} />
              </Switch>
            </AiLayout>
          </Route>

          <Route path="/ai-settings-system" component={AiSettingsSystem} />
          <Route
            exact
            path="/account/accept-invitation"
            render={(routeProps) => (
              <AccountNewInvitation
                {...routeProps}
                refreshUser={refreshUser}
                user={user}
                settings={settings}
              />
            )}
          />
          <Route
            path="/account/create"
            render={() => (
              <AccountCreation
                switchCompany={switchCompany}
                refreshUser={refreshUser}
              />
            )}
          />
          <Route
            path="/settings"
            render={() => (
              <SettingsPage
                user={user}
                path={location.pathname}
                settings={settings}
                onRequestSettings={onRequestSettings}
              />
            )}
          />
          <Route exact path="/cloudbeds" component={Integrations} />
        </IonRouterOutlet>
      </IonContent>
    </>
  );
};

interface ILayoutProps {
  logOut: Function;
  refreshUser: Function;
  switchCompany: Function;
  user: User;
  location: Location;
  settings: Settings;
  onRequestSettings: Function;
}

export default connect((props: any) => ({
  settings: props.app.settings,
}))(DesktopHome);
