import interceptRefreshToken from "./refreshToken";

function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.json();
}

function parseError(response) {
  try {
    return response.json();
  } catch (e) {
    return Promise.reject({ message: "Unknown Error" });
  }
}

function baseAdapter(data) {
  return { data };
}

function errorAdapter(err) {
  return { err };
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  throw response;
}

function parseBlob(response) {
  return response.blob();
}

function parseResponse(response, responseType) {
  if (responseType === "blob") {
    return parseBlob(response);
  }
  return parseJSON(response);
}

export function requestOptions(method, body = {}, auth) {
  const headers = new Headers();
  const whichMethods = ["POST", "PUT", "PATCH", "DELETE"];
  const extend = whichMethods.indexOf(method) > -1;

  if (extend) {
    headers.append("Accept", "application/json");
    headers.append("Content-Type", "application/json");
  }

  if (auth) {
    const token = localStorage.getItem("token");
    const company = localStorage.getItem("company");

    headers.append("Authorization", `bearer ${token}`);
    headers.append("ControlId", company);
  }

  const options = {
    method,
    headers,
  };

  if (extend) {
    options.mode = "cors";
    options.redirect = "follow";
    options.body = JSON.stringify(body);
  }
  return options;
}

export function requestOptionsFormData(formData, auth) {
  const headers = new Headers();

  if (auth) {
    const token = localStorage.getItem("token");
    const company = localStorage.getItem("company");

    headers.append("Authorization", `bearer ${token}`);
    headers.append("ControlId", company);
  }

  const options = {
    method: "POST",
    headers,
  };

  options.mode = "cors";
  options.redirect = "follow";
  options.body = formData;

  return options;
}

export default function request(url, options, responseType = "json") {
  try {
    return fetch(url, options)
      .then(checkStatus)
      .then((response) => parseResponse(response, responseType))
      .then(baseAdapter)
      .catch((err) =>
        parseError(err)
          .then((_err) => {
            console.log(_err);
            if (_err.name === "TokenError") {
              return interceptRefreshToken(
                { request, requestOptions },
                url,
                options
              );
            }
            return errorAdapter(_err);
          })
          .catch((e) => {
            return errorAdapter({
              message: "Unknown Error. Please try again later",
            });
          })
      );
  } catch (e) {
    return errorAdapter({ message: "Unknown Error. Please try again later" });
  }
}
