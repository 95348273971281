import { useMemo } from "react";
import {
  IconChevronLeft,
  IconChevronRight,
  IconDots,
} from "@tabler/icons-react";

import styles from "./styles.module.css";

const commonIconProps = {
  role: "button",
  size: 20,
};

const PaginationComponent = ({
  totalPages,
  currentPage,
  onChangePage,
}: {
  totalPages: number;
  currentPage: number;
  onChangePage: (page: number) => void;
}) => {
  const isCurrentFirst = currentPage === 1;
  const isCurrentLast = currentPage === totalPages;

  const paginationButtons = useMemo(() => {
    let isPageNumberOutOfRange = false;

    return [...new Array(totalPages)].map((_, index) => {
      const pageNumber = index + 1;
      const isPageNumberFirst = pageNumber === 1;
      const isPageNumberLast = pageNumber === totalPages;
      const isCurrentPageWithinTwoPageNumbers =
        Math.abs(pageNumber - currentPage) <= 2;

      if (
        isPageNumberFirst ||
        isPageNumberLast ||
        isCurrentPageWithinTwoPageNumbers
      ) {
        isPageNumberOutOfRange = false;
        return (
          <button
            key={pageNumber}
            className={`${styles.btn} ${
              pageNumber === currentPage ? styles.btnActive : ""
            }`}
            onClick={() => onChangePage(pageNumber)}
          >
            {pageNumber}
          </button>
        );
      }

      if (!isPageNumberOutOfRange) {
        isPageNumberOutOfRange = true;
        return (
          <div className="flex align-items-end">
            <IconDots key="dots" size={18} />
          </div>
        );
      }

      return null;
    });
  }, [totalPages, currentPage, onChangePage]);

  return (
    <div className="flex align-items-center justify-content-center gap-3 w-100">
      <div className="flex align-items-center gap-3">
        <IconChevronLeft
          {...commonIconProps}
          onClick={() => onChangePage(currentPage - 1)}
          className={`${isCurrentFirst ? styles.actionDisable : ""}`}
        />
        <div className="flex gap-2">{paginationButtons}</div>
        <IconChevronRight
          {...commonIconProps}
          onClick={() => onChangePage(currentPage + 1)}
          className={`${isCurrentLast ? styles.actionDisable : ""}`}
        />
      </div>
    </div>
  );
};

export default PaginationComponent;
