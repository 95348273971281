import { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import {
  IonHeader,
  IonToolbar,
  isPlatform,
  IonPage,
  IonContent,
  IonTitle,
  IonList,
  IonItem,
  IonLabel,
  useIonToast,
} from "@ionic/react";

// Reac int
import { FormattedMessage, useIntl } from "react-intl";

// Types
import { User, Settings, apiResult } from "../../../interfaces";

// Styles
import headerStyles from "../../../theme/header.module.css";

// Api
import Api from "../../../api/account";

// Components
import SkeletonLoader from "../../SkeletonLoader";

import { deserialize } from "../../../utils/serialize";

// Other
const isMobile = isPlatform("android") || isPlatform("ios");

const Header: React.FC = () => {
  return (
    <div>
      <h1 className={headerStyles.pageTitle}>
        <FormattedMessage id="account.acceptInvitation.title" />
      </h1>
      <h3 className={headerStyles.pageDescription}>
        <FormattedMessage id="account.acceptInvitation.pageDescription" />
      </h3>
    </div>
  );
};

const MobileHeader: React.FC = () => {
  return (
    <div>
      <div className={headerStyles.header}>
        <h1 className={headerStyles.mobileTitle}>
          <FormattedMessage id="account.acceptInvitation.title" />
        </h1>
        <h3 className={headerStyles.mobileDescription}>
          <FormattedMessage id="account.acceptInvitation.pageDescription" />
        </h3>
      </div>
    </div>
  );
};

const MobileTitle = () => {
  return (
    <IonHeader>
      <IonToolbar>
        <IonTitle>
          <FormattedMessage id="account.acceptInvitation.title" />
        </IonTitle>
      </IonToolbar>
    </IonHeader>
  );
};

interface List {
  id: string;
  name: string;
}

const AcceptInvitation: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);

  const [list, setList] = useState<List[]>([]);
  const [present] = useIonToast();

  useEffect(() => {
    getInvites();
  }, []);

  useEffect(() => {
    const { search } = props.location;
    const { token } = deserialize(search);
    if (token) {
      getInvite(token);
    }
  }, [props.location]);

  // API
  const getInvites = async () => {
    setLoading(true);
    const { data, err } = await Api.lisInvites();

    if (data) {
      setList(
        data.list.map((l: apiResult) => ({
          id: l._id,
          name: l.company.name,
        }))
      );
    }
    if (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const getInvite = async (code: string) => {
    const { data, err } = await Api.getInvite(code);
    if (data) {
      console.log(data);
    }
    if (err) {
      return present({
        message:
          err.error[intl.locale] ||
          intl.formatMessage({ id: "common.tryItLater" }),
        duration: 10000,
        color: "danger",
        position: "top",
      });
    }
  };

  const acceptInvitation = async (id: string) => {
    const { data, err } = await Api.acceptInvite(id);
    if (data) {
      props.refreshUser();
      getInvites();
      return present({
        message: intl.formatMessage({ id: "account.successAdded" }),
        duration: 10000,
        color: "light",
        position: "top",
        cssClass: "success-toast",
      });
    }
    if (err) {
      return present({
        message:
          err.error[intl.locale] ||
          intl.formatMessage({ id: "common.tryItLater" }),
        duration: 10000,
        color: "danger",
        position: "top",
      });
    }
  };

  return (
    <IonPage id="main-content">
      {isMobile && <MobileTitle />}
      <IonContent
        {...(isMobile
          ? { fullscreen: true, color: "light", className: "ion-padding" }
          : {})}
      >
        <div className={isMobile ? "view-wrapper" : "page-wrapper container"}>
          {isMobile ? (
            <>
              <div className="view-pane">
                <MobileHeader />
              </div>
              {loading ? (
                <SkeletonLoader rows={4} />
              ) : list.length ? (
                <IonList inset={true}>
                  <IonItem>
                    <IonLabel>
                      <b>
                        <FormattedMessage id="account.acceptInvitation.account" />
                      </b>
                    </IonLabel>
                  </IonItem>
                  {list.map((i) => (
                    <IonItem key={i.id}>
                      <IonLabel>{i.name}</IonLabel>
                      <div slot="end">
                        <button
                          className="btn btn-visito-primary btn-sm"
                          onClick={() => acceptInvitation(i.id)}
                        >
                          <FormattedMessage id="common.accept" />
                        </button>
                      </div>
                    </IonItem>
                  ))}
                </IonList>
              ) : (
                <div className="view-pane">
                  <FormattedMessage id="account.acceptInvitation.noPending" />
                </div>
              )}
            </>
          ) : (
            <>
              <Header />
              {loading ? (
                <SkeletonLoader rows={4} />
              ) : (
                <div className="row">
                  <div className="col-md-4">
                    {list.length ? (
                      <IonList lines={"full"}>
                        <IonItem>
                          <IonLabel>
                            <b>
                              <FormattedMessage id="account.acceptInvitation.account" />
                            </b>
                          </IonLabel>
                        </IonItem>
                        {list.map((i) => (
                          <IonItem key={i.id}>
                            <IonLabel>{i.name}</IonLabel>
                            <div slot="end">
                              <button
                                className="btn btn-visito-primary btn-sm"
                                onClick={() => acceptInvitation(i.id)}
                              >
                                <FormattedMessage id="common.accept" />
                              </button>
                            </div>
                          </IonItem>
                        ))}
                      </IonList>
                    ) : (
                      <div>
                        <FormattedMessage id="account.acceptInvitation.noPending" />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

interface ILayoutProps extends RouteComponentProps {
  user: User;
  settings: Settings;
  dispatch: Function;
  refreshUser: Function;
}

export default connect((props: any) => ({}))(AcceptInvitation);
