import { IconInfoCircle } from "@tabler/icons-react";

import styles from "./styles.module.css";

const getMainClass: { [key: string]: string } = {
  danger:  "dangerAlert",
  info: "infoAlert"
};

const getIconColor: { [key: string]: string } = {
  danger: "var(--visito-color-danger)",
  info: "var(--visito-color-purple)"
};

const Alert = (
  {
    type,
    icon,
    message,
    className,
  }:
  {
    type: "danger" | "warning" | "info",
    message: string | React.ReactNode,
    icon?: React.ReactNode | JSX.Element,
    className?: string,
  }) => {
    const mainClass = getMainClass[type];
  
    return (
      <div className={`${styles.alertContainer} ${styles[mainClass]} ${className || ""}`}>
        <div>
          {icon || (
            <IconInfoCircle
              size={18}
              color={getIconColor[type]}
            />
          )}
        </div>
        {message}
      </div>
    );
};

export default Alert;
