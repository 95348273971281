export const STRIPE = {
  key: process.env.REACT_APP_STRIPE_KEY || null,
  pricingTable: process.env.REACT_APP_PRICING_TABLE || null,
  loginDashboard: process.env.REACT_APP_BILLING_DASHBOARD || null,
};

export const CLOUDBEDS = {
  INSTALL_URL:
    process.env.REACT_APP_CLOUDBEDS_INSTALL_URL ||
    "https://hotels.cloudbeds.com/api/v1.1/oauth?client_id=visitoai_hcPbRdNgJUAyMwI58iOf2kHF&redirect_uri=https%3A%2F%2Fapp.visitoai.com%2Fdoor%3Fpartner%3Dcloudbeds&response_type=code&scope=read%3Aadjustment+read%3Aitem+read%3Aroomblock+read%3AallotmentBlock+read%3Acommunication+read%3Acurrency+read%3AcustomFields+read%3Adashboard+read%3Aguest+read%3Ahotel+read%3Apayment+write%3Apayment+read%3Arate+read%3Areservation+write%3Areservation+read%3Aroom+read%3AtaxesAndFees+read%3Auser",
};

export const META = {
  INSTAGRAM_URL:
    process.env.REACT_APP_INSTAGRAM_URL ||
    `https://www.instagram.com/oauth/authorize?enable_fb_login=0&force_authentication=1&client_id=1212987993330211&redirect_uri=${process.env.REACT_APP_HTML_BASE}/door/meta&response_type=code&scope=instagram_business_basic%2Cinstagram_business_manage_messages%2Cinstagram_business_manage_comments%2Cinstagram_business_content_publish%2Cinstagram_business_manage_insights`,
};

const config = {
  API_ROOT: process.env.REACT_APP_API_URL || "https://book.visitoai.com",
  SOCKET_ROOT: process.env.REACT_APP_SOCKET_URL || "https://book.visitoai.com",
  HTML_ROOT: process.env.REACT_APP_HTML_BASE || "https://app.visitoai.com",
  CDN_ROOT: "https://s3.visitoai.com",
  GA_ID: process.env.REACT_APP_GA_ID || null,
  HOTJAR_ID: process.env.REACT_APP_HOTJAR_ID || null,
  HELP_URL:
    process.env.REACT_APP_HELP_URL || `https://www.visitoai.com/en/guide`,
  VERSION: {
    vName: "1.28.0-stripe-web-chat",
    vId: 128,
    vIdentifier: "1.28.1",
  },
  ENV: process.env.REACT_APP_ENV || "local",
  STRIPE,
  CLOUDBEDS,
  META,
};

export default config;
