const lang = {
  "common.and": "e",
  "common.or": "ou",
  "common.delete": "Excluir",
  "common.loading": "Carregando",
  "common.done": "Pronto",
  "common.close": "Fechar",
  "common.continue": "Continuar",
  "common.accept": "Aceitar",
  "common.property": "Propriedade",
  "common.status": "Status",
  "common.skip": "Pular",
  "common.pause": "Pausar",
  "common.connecting": "Conectando",
  "common.activate": "Ativar",
  "common.back": "Voltar",
  "common.edit": "Editar",
  "common.active": "Ativo",
  "common.manage": "Gerenciar",
  "common.setup": "Configurar",
  "common.connect": "Conectar",
  "common.configure": "Configurar",
  "common.call": "Chamada",
  "common.inactive": "Inativo",
  "common.cancel": "Cancelar",
  "common.account": "Conta",
  "common.file": "Arquivo",
  "common.photo": "Fotos",
  "common.runAi": "Executar IA",
  "common.remove": "Remover",
  "common.reset": "Redefinir",
  "common.confirmInactive": "Sim, desativar",
  "common.confirmContinue": "Sim, continuar",
  "common.confirmDelete": "Sim, excluir",
  "common.confirmScan": "Sim, escanear",
  "common.error": "Erro",
  "common.tryItLater": "Ocorreu um erro, tente novamente mais tarde",
  "common.success": "Sucesso",
  "common.today": "Hoje",
  "common.week": "Esta semana",
  "common.7days": "Últimos 7 dias",
  "common.30days": "Últimos 30 dias",
  "common.thisMonth": "Este mês",
  "common.lastMonth": "Mês passado",
  "common.custom": "Personalizado",
  "common.date": "Data",
  "common.name": "Nome",
  "common.message": "Mensagem",
  "common.noResults":
    "Nenhum registro encontrado para o intervalo de datas selecionado",
  "common.results": "{total} resultados",
  "common.body": "Mensagem",
  "common.tags": "Etiquetas",
  "common.save": "Salvar",
  "common.saveClose": "Salvar e fechar",
  "common.submitChanges": "Enviar alterações",
  "common.updating": "Atualizando...",
  "common.newConversation": "Nova conversa",
  "common.create": "Criar",
  "common.phone": "Número de telefone",
  "common.contactName": "Nome do contato",
  "common.optional": "Opcional",
  "common.signUp": "Cadastrar-se",
  "common.copyLink": "Link copiado",
  "common.copyCode": "Código copiado",
  "common.share": "Compartilhar",
  "ommon.embed": "Incorporar",
  "common.virtualAssistant": "Assistente Virtual",
  "common.scan": "Digitalize seu site",
  "common.setUp": "Configurar",
  "common.typeText": "Digite um texto...",
  "common.connected": "Conectado",
  "common.searchText": "Buscar no texto...",
  "common.characteres": "caracteres",
  "common.wordpress": "WordPress",
  "settings.pageTitle": "Configurações",
  "settings.pageDescription": "Ajuste suas preferências",
  "settings.ai": "Configurações de IA",
  "settings.accountTitle": "Conta",
  "settings.language": "Idioma",
  "settings.settingsTitle": "Configurações",
  "settings.channels": "Canais",
  "settings.connect": "Conectar",
  "settings.integrations": "Integrações",
  "settings.teams": "Equipe",
  "settings.payment": "Cobrança",
  "esc.needsReview": "Revisão",
  "esc.escalation": "Ajuda",
  "esc.clickToView": "Clique para ver",
  "esc.tapToView": "Toque para ver",
  "esc.solve": "Resolver a escalada",
  "nav.inbox": "Conversas",
  "nav.integrations": "Integrações",
  "nav.home": "Início",
  "nav.outbound": "Eventos",
  "nav.ai": "IA",
  "nav.settings": "Configurações",
  "nav.logout": "Sair",
  "nav.trial":
    "Seu teste gratuito termina em {count, plural, one {# dia} other {# dias}}",
  "nav.payment": "Você não tem mais mensagens disponíveis.",
  "nav.paymentCTA": "Atualize seu plano",
  "nav.accountList": "Contas",
  "nav.pastDue": "Seu pagamento falhou.",
  "nav.pastDueCTA":
    "Atualize seu método de pagamento para manter sua conta ativa.",
  "nav.connect": "Conectar",
  "nav.createAccount": "Criar nova conta",
  "nav.helpCenter": "Centro de Ajuda",
  "nav.helpChat": "Chat de Ajuda",
  "ai-settings.workingHours": "Horário de trabalho",
  "ai-settings.workingHoursDescription":
    "Se uma escalonamento ocorrer fora do horário de expediente, a IA informará o hóspede sobre o horário de atendimento.",
  "ai-settings.title": "Configurações de IA",
  "ai-settings.paneTitle": "Configurações de Inteligência Artificial",
  "ai-settings.notifications": "Notificações",
  "ai-settings.notificationsDescription":
    "Baixe o aplicativo Visito para receber notificações push sobre conversas não resolvidas.",
  "ai-settings-intructions.title": "Configurações",
  "ai.pageTitle": "Inteligência Artificial",
  "ai.pageDescription": "Ajuste e experimente seu agente de IA",
  "ai.contextTitle": "Base de Conhecimento",
  "ai.contextDescription":
    "Gerencie seu conhecimento para treinar seu agente de IA",
  "ai.playgroundTitle": "Sandbox do Agente de IA",
  "ai.playgroundDescription":
    "Interaja com seu agente de IA para observar como ele responde",
  aiSettingsWorking: "Estamos preparando suas informações",
  aiSettingsOptimizing: "Estamos otimizando suas informações",
  aiSettingsOptimizingDesc:
    "Estamos otimizando as informações extraídas de seu site",
  aiSettingsKnowledge: "Hub de IA",
  aiSettingsKnowledgeDesc:
    "Aqui você pode fornecer informações para treinar a IA. Teste a IA à direita para ver o que seus clientes experimentarão.",
  aiSettingsAlert:
    "Aqui você pode fornecer informações para treinar a IA. Teste a IA à direita para ver o que seus clientes experimentarão.",
  aiSettingsFreeText: "Texto livre",

  aiSettingsReset: "Limpar",
  aiSettingsCall: "Chamada",
  aiSettingsCalling: "Chamando",
  aiSettingsCallConnected: "Conectado",

  aiSettingsHangup: "Desligar",
  aiSettingsAssitant: "Conheça Lana! Sua assistente virtual",
  aiSettingsAssitantDesc:
    "Aqui você pode experimentar e interagir com Lana para entender exatamente o que seus clientes experimentarão com as respostas da IA.",
  aiCrawlingFailed:
    "Houve um problema ao analisar seu site, tente novamente mais tarde",
  aiCrawlingSkip: "Deseja continuar sem analisar seu site?",
  aiCrawlingSkipDesc:
    "Analisar seu site ajudará seu assistente de IA a oferecer melhores resultados",
  aiSettingsSaving: "Salvando alterações...",
  aiExceededLength: "Você excedeu o limite de caracteres",
  aiCompanyTitle: "{company}",
  aiPoweredBy: "Desenvolvido por",
  aiCompanyWelcomeTitle: "Bem-vindo ao assistente virtual da {company}",
  aiCompanyFormDesc: "Insira seu nome para começar",
  aiCompanyExplore: "Continuar",
  aiShare: "Compartilhe seu assistente virtual",
  aiCrawl: "Vamos escanear seu site e treinar seu agente de IA!",
  aiCrawling: "Sua IA está escaneando o site",
  aiCrawlingWebsiteSucc: "Análise do site concluída",
  aiCrawlingSuccess: "Seu site foi escaneado com sucesso",
  aiCrawlingSuccessDesc: "Suas informações estão prontas para revisão.",
  aiReview: "Vamos revisá-las",
  aiAgent: "Agente de IA",
  aiAgentCreated: "Seu agente de IA está pronto",
  aiUpdateCta: "Editar",
  aiUpdateSucces: "Seu agente de IA foi atualizado",

  "ai.agent": "Seu Agente de IA",
  "ai.agent.update": "Aplicar Alterações",
  "ai.playground": "Teste",
  "ai.playground.desc": "Teste seu agente de IA em um chat de exemplo",
  "ai.playground.test": "Testar sua IA",
  "ai.sources": "Fontes",
  "ai.sources.alert": "Aproveite ao máximo sua IA",
  "ai.sources.alert.desc":
    "Sua IA funciona melhor quando tem informações para aprender. Para oferecer respostas mais precisas, é necessário adicionar pelo menos uma fonte de dados",
  "ai.sources.alert.button": "Entendi",
  "ai.sources.desc":
    "Adicione fontes de dados para melhorar a precisão do seu agente de IA",
  "ai.sources.website": "Website",
  "ai.sources.website.desc":
    "Analise uma página da web para extrair seu conteúdo e usá-lo para melhorar sua IA",
  "ai.sources.website.scanning": "Análise do site em andamento",
  "ai.sources.website.completed": "Análise do site concluída",
  "ai.sources.website.rescan.confirmation":
    "Tem certeza de que deseja escanear este site novamente?",
  "ai.sources.website.rescan.confirmationDesc":
    "Reescanear este site excluirá todo o conteúdo extraído anteriormente e o substituirá por novos dados da última análise",
  "ai.sources.website.delete.confirmation":
    "Tem certeza de que deseja excluir esta URL?",
  "ai.sources.website.delete.confirmationDesc":
    "Excluir esta URL removerá seu conteúdo da base de conhecimento do seu agente de IA. Ele não será mais usado para gerar respostas.",
  "ai.sources.website.fullCrawl":
    "Isso vai rastrear todos os links que comecem com a URL acima",
  "ai.sources.website.singleCrawl":
    "Isso vai extrair apenas o conteúdo da URL específica acima",
  "ai.sources.website.max":
    "Você atingiu o limite de {limit} URLs ativas. Exclua ou desative uma para adicionar outra",
  "ai.sources.text": "Texto",
  "ai.sources.text.desc":
    "Adicione informações manualmente para ajudar sua IA a gerar melhores respostas",
  "ai.sources.files": "Arquivos",
  "ai.sources.files.desc":
    "Envie um arquivo ou documento para fornecer informações úteis à sua IA",
  "ai.sources.files.upload": "Enviar Arquivo",
  "ai.sources.files.upload.label": "Envie uma imagem, PDF ou arquivo CSV",
  "ai.sources.files.delete": "Excluir Arquivo",
  "ai.sources.files.delete.confirmation":
    "Tem certeza de que deseja excluir este arquivo?",
  "ai.sources.files.delete.confirmationDesc":
    "Excluir este arquivo o removerá da base de conhecimento do seu agente de IA. Ele não será mais usado para gerar respostas.",
  "ai.sources.files.analyzed": "Arquivos Analisados",
  "ai.sources.files.limit": "O arquivo é muito grande. Limite: 15 MB",
  "ai.sources.files.max":
    "O limite de arquivos ativos é {limit}. Por favor, exclua ou desative um para poder enviar um novo arquivo",
  "ai.sources.providers": "Integrações",
  "ai.sources.providers.desc":
    "Conecte integrações de terceiros para dar à sua IA acesso a dados externos e melhorar suas respostas",
  "ai.sources.providers.manage":
    "Gerencie suas integrações conectadas na página de integrações. Você pode adicioná-las, atualizá-las ou removê-las a qualquer momento.",
  "ai.sources.providers.empty":
    "Nenhuma integração conectada ainda. Adicione uma na página de integrações para começar a usar dados externos",
  "ai.sources.providers.setUp": "Configurar uma Integração",
  "ai.sources.customerContext": "Contexto do Cliente",
  "ai.sources.customerContext.desc":
    "Adicione informações que só serão incluídas nas respostas quando uma reserva ou pedido for confirmado. Use isso para fornecer detalhes como números de quarto, senhas de Wi-Fi ou códigos de acesso especiais",
  "ai.settings": "Configurações",
  "ai.settings.desc": "Personalize o comportamento e a personalidade da sua IA",
  "ai.settings.instructions": "Comportamento IA",
  "ai.settings.instructions.desc":
    "Use instruções para definir a personalidade e o estilo do seu agente de IA. Essas configurações ajustam seu comportamento, mas não adicionam fontes de conhecimento. Seja claro e específico para alinhar as respostas com suas necessidades",

  "account.successAdded":
    "O convite foi aceito e você agora tem acesso à conta.",
  "account.acceptInvitation.title": "Aceitar Convites",
  "account.acceptInvitation.pageDescription":
    "Por favor, aceite seus convites pendentes.",
  "account.acceptInvitation.account": "Nome da conta",
  "account.acceptInvitation.noPending":
    "Você não tem nenhum convite pendente...",

  "account.createAccount.title": "Criar uma nova conta",
  "account.createAccount.pageDescription":
    "Insira o nome da empresa para a nova conta",

  "auth.login.title": "Bem-vindo ao Visito",
  "auth.login.email": "E-mail",
  "auth.login.password": "Senha",
  "auth.login.login": "Entrar",
  "auth.login.registerLabel": "Novo no Visito?",
  "auth.login.register": "Criar uma conta",
  "auth.login.forgotPassword": "Clique aqui",
  "auth.login.forgotLabel": "Esqueceu sua senha?",
  "auth.register.title": "Comece gratuitamente",
  "auth.register.company": "Nome da empresa",
  "auth.register.web": "Site",
  "auth.register.email": "E-mail",
  "auth.register.password": "Senha",
  "auth.register.register": "Registrar",
  "auth.register.loginLabel": "Já tem uma conta?",
  "auth.register.login": "Entrar",
  "auth.register.loginTerms": "Termos de Serviço",
  "auth.register.loginPrivacy": "Política de Privacidade",
  "auth.register.continuing": "Ao continuar, você aceita nossos",
  "auth.register.titleCloudbeds": "Crie sua conta Visito",
  "auth.registerUser.title": "Configure sua conta",
  "auth.registerUser.subtitle": "Por favor, defina sua senha para começar.",

  "auth.forgotPassword.title": "Redefinir senha",
  "auth.forgotPassword.email": "E-mail",
  "auth.forgotPassword.submit": "Enviar",
  "auth.forgotPassword.success":
    "O link para redefinir sua senha foi enviado para seu e-mail",
  "auth.forgotPassword.error":
    "E-mail não encontrado. Por favor, verifique e tente novamente.",
  "auth.forgotPassword.description":
    "Insira o endereço de e-mail associado à sua conta e enviaremos um link para redefinir sua senha.",
  "auth.resetPassword.title": "Redefinir sua senha",
  "auth.resetPassword.password": "Nova senha",
  "auth.resetPassword.cancel": "Cancelar",
  "auth.resetPassword.submit": "Redefinir",
  "auth.resetPassword.success":
    "Sua senha foi redefinida com sucesso. Agora você pode acessar sua conta usando a nova senha.",
  "auth.resetPassword.description": "Escolha uma nova senha abaixo.",
  "auth.userInvite.success": "Convite aceito com sucesso.",

  "contactDetail.title": "Perfil do contato",
  "contactDetail.actions": "Ações",
  "contactDetail.bookings": "Reservas",
  "contactDetail.followers": "seguidores",
  "chats.newMessageInput": "Digite sua mensagem...",
  "chats.searchContact": "Buscar contato",
  "chats.aiAuto": "Respostas automáticas de IA",
  "chats.markAsOpen": "Marcar como revisão",
  "chats.markAsClose": "Marcar como resolvido",
  "chats.all": "Todos",
  "chats.accounts": "Contas",
  "chats.manual": "Manual",
  "chats.blocked": "Bloqueado",
  "chats.reply": "Responder",
  "chats.isGuest": "Hóspede",
  "chats.isBook": "Reserva Oferecida",
  "chats.isManual": "Conversa Manual",
  "chats.isConversion": "Conversão",
  "chats.unblockContact": "Desbloquear Contato",
  "chats.blockContact": "Bloquear Contato",
  "chats.contactOptedOut": "O contato optou por sair",
  "chats.filter": "Filtrar",
  "chats.escalated": "Revisão",
  "chats.emptyStateTitle": "Ainda não há conversas",
  "chats.emptyStateSubtitle":
    "Sua lista de conversas está esperando pela primeira mensagem",
  "chats.emptyStateEscTitle": "Não há conversas em revisão",
  "chats.emptyStateEscSubtitle": "Parece que está tudo em ordem!",

  "chats.emptyWelcomeTitle": "Nenhuma conversa selecionada",
  "chats.emptyWelcomeSubtitle":
    "Escolha uma conversa à esquerda para visualizar as mensagens.",
  "chats.searchNoResults": "Nenhum resultado encontrado",
  "chats.searchStart": "Comece a digitar para encontrar um contato",
  "interactive.call.summary": "Resumo da chamada",
  "interactive.call.active": "Chat de voz ativo",

  "integrations.waSettings": "Configurações do WhatsApp",
  "integrations.waPaymentMethod": "Configurar pagamento",

  "integrations.title": "Integrações",
  "integrations.propertiesListTitle": "Propriedades",
  "integrations.noProperties": "Nenhuma propriedade encontrada",
  "integrations.embedChat": "Incorporar seu assistente virtual em seu site",
  "integrations.embedChatDescription":
    "Copie o código abaixo e cole no código-fonte do seu site.",

  "integrations.wordpress": "Plugin para WordPress",
  "integrations.wordpressInstructions":
    "Para usar o Visito AI no WordPress, siga estes passos:",
  "integrations.wordpressStep1":
    "Baixe o plugin Visito AI (arquivo ZIP) no seguinte link:",
  "integrations.wordpressStep2":
    "Acesse Plugins > Adicionar novo > Enviar plugin no painel do WordPress.",
  "integrations.wordpressStep3":
    "Ative o plugin e cole sua chave API em Configurações > Visito AI.",
  "integrations.webChat": "Compatilhe seu assistente virtual",
  "integrations.webName": "Web Chat",
  "integrations.playName": "Playground Chat",
  "integrations.webChatDescription":
    "Copia o link abaixo para compartilhar seu assistente virtual com outras pessoas",
  "integrations.copyLink": "Copiar",
  "integrations.visitLink": "Abrir",
  "integrations.acceptNewIntegration":
    "Por favor, aceite a nova integração recebida do {provider}",
  "integrations.completedNewIntegration":
    "Você aceitou com sucesso a integração com o {provider}",

  "integrations.completedDbIntegration": "Integração adicionada com sucesso",
  "integrations.deleteTitle":
    "Tem certeza de que deseja excluir esta integração?",
  "integrations.deleteDesc":
    "Essa ação é irreversível. A integração será desativada imediatamente e você precisará reconectá-la se quiser usá-la novamente.",

  "integrations.webChatName": "Site Web",
  "integrations.fmName": "Messenger",
  "integrations.waName": "WhatsApp",
  "integrations.cbName": "Cloudbeds",
  "integrations.haName": "Hostaway",
  "integrations.igName": "Instagram",
  "integrations.smName": "SiteMinder",
  "integrations.gsName": "Guesty",
  "integrations.miraiName": "Mirai",
  "integrations.voiceName": "Voz",

  "integrations.manualReplies": "A automação de IA está pausada.",
  "integrations.autoReplies": "A automação de IA está ativa.",

  "integrations.pmsActive": "Acesso está ativo.",
  "integrations.pmsPaused": "O acesso está pausado.",

  "integrations.igReconnect": "Adicione ou remova contas",
  "integrations.waReconnect": "Adicione ou remova números",
  "integrations.fmReconnect": "Adicione ou remova páginas",

  "integrations.cbReconnect": "Adicione novas propriedades",
  "integrations.smReconnect": "Adicione novas propriedades",
  "integrations.gsReconnect": "Adicione novas propriedades",
  "integrations.haReconnect": "Adicione novas propriedades",

  "integrations.smAdd": "Adicione uma nova propriedade do SiteMinder",
  "integrations.gsAdd": "Adicione uma nova propriedade do Guesty",
  "integrations.voiceAdd": "Adicione um novo número de telefone",
  "integrations.guide": "Guia de Integração",

  "integrations.missingInformation":
    "Sua integração está com informações faltando. Consulte o guia de integração e clique em ‘Gerenciar’ para completar as configurações da conta.",
  "integrations.nameDb": "Nome",
  "integrations.refreshTokenDb": "API Secret",
  "integrations.clientIdDb": "API Client ID",
  "integrations.propertyIdDb": "ID da Propriedade",
  "integrations.widgetIdDb": "ID do widget do motor de reservas",
  "integrations.publisherDb": "Publicador",
  "integrations.currencyDb": "Moeda",
  "integrations.countryCodeDb": "Selecione o país",
  "integrations.timezoneDb": "Fuso Horário",
  "integrations.bookingEngineDb": "Motor de Reservas",
  "integrations.phoneNumberDb": "Número de teléfono",

  "integrations.nameDbPlaceholder": "Hotel Santa Fé",
  "integrations.propertyIdDbPlaceholder": "28093",
  "integrations.widgetIdDbPlaceholder": "santafedirect",
  "integrations.publisherDbPlaceholder": "littlehotelier",
  "integrations.currencyDbPlaceholder": "BRL",
  "integrations.countryCodeDbPlaceholder": "BR",
  "integrations.phoneNumberDbPlaceholder": "5585655477",

  "integrations.refreshTokenDbPlaceholder":
    "Your API secret from guesty API dashboard",
  "integrations.clientIdDbPlaceholder":
    "Your Client ID from guesty API dashboard",

  "integrations.pageTitle": "Integrações",
  "integrations.pageConnectTitle": "Conectar",
  "integrations.pageDescription":
    "Conecte seu assistente aos principais canais de comunicação e fontes de dados",

  "integrations.fmDescription": "Conecte suas páginas do Facebook",
  "integrations.waDescription": "Conecte seu número de WhatsApp",
  "integrations.cbDescription": "Conecte suas propriedades do Cloudbeds",
  "integrations.haDescription": "Conecte suas propriedades do Hostaway",
  "integrations.igDescription": "Conecte suas contas do Instagram",
  "integrations.smDescription": "Conecte suas propriedades do SiteMinder",
  "integrations.gsDescription": "Conecte suas propriedades do Guesty",
  "integrations.miraiDescription": "Conecte seu motor de reservas do Mirai",
  "integrations.voiceDescription": "Conecte seu número de telefone",

  "teams.title": "Gerenciamento de Equipe",
  "teams.subTitle": "Gerencie o acesso dos membros da equipe",
  "teams.inviteMember": "Convide um novo membro da equipe",
  "teams.selectorLabel": "Papel",
  "teams.rol.admin": "Administrador",
  "teams.rol.user": "Usuário",
  "teams.emailLabel": "E-mail",
  "teams.button": "Convidar",
  "teams.message.success": "Um link de convite foi enviado para o seu e-mail.",
  "teams.passwordLabel": "Senha",
  "teams.buttonAccept": "Aceitar",
  "teams.inviteInfo": "Aceitar convite de equipe",
  "teams.modalMessageTitle.success": "Convite enviado com sucesso",
  "teams.modalMessageTitle.error": "Erro ao enviar o convite",
  "teams.modal.button": "Fechar",
  "invitedList.Members": "Lista de membros da equipe",
  "invitedList.Invites":
    "📩 {count, plural, one {# convite restante} other {# convites restantes}}",
  "invitedList.emailLabel": "E-mail",
  "invitedList.roleLabel": "Papel",
  "invitedList.dateLabel": "Ingressou em",
  "invitedList.title.deleteSuccess": "Remover usuário",
  "invitedList.message.deleteSuccess": "Usuário removido com sucesso",
  "invitedList.message.deleteError": "Erro ao remover o usuário",
  "payment.title": "Cobrança",
  "payment.subTitle":
    "Gerencie seus métodos de pagamento, assinaturas e detalhes de cobrança.",
  "payment.monthly": "Mensal",
  "payment.annual": "Anual",
  "payment.currentPlan": "Plano Atual",
  "payment.managePlan": "Gerenciar Plano",
  "payment.billingEmail": "E-mail de Cobrança",
  "language.title": "Idioma",
  "language.subTitle": "Selecione o idioma que deseja usar na plataforma",
  "language.selectorLabel": "Selecionar Idioma",
  "language.english": "English",
  "language.spanish": "Español",
  "language.portuguese": "Português",
  "language.languages": "Idiomas",
  "language.en_US": "Inglês",
  "language.es_MX": "Espanhol",
  "language.pt_BR": "Português",
  "home.statsTitle": "Estatísticas dos últimos 30 dias",
  "home.planTitle": "Estatísticas do plano atual",
  "home.pageTitle": "Olá",
  "home.pageDescription": "Bem-vindo ao seu painel do Visito",
  "home.skip": "Pular introdução",

  "home.onboardingTitle": "Começar",
  "home.onboardingDescription":
    "Conclua as etapas abaixo para configurar totalmente sua conta",

  "home.title_account": "Crie sua conta",
  "home.description_account":
    "Gerencie todas as comunicações em uma única caixa de entrada do Visito",

  "home.title_location": "Conecte uma propriedade",
  "home.description_location": "Integre sua propriedade ao Visito",

  "home.title_channel": "Conecte um canal",
  "home.description_channel":
    "Conecte um canal para começar a receber mensagens",

  "home.title_wa": "Conecte o WhatsApp",
  "home.description_wa":
    "Alcance mais de 2 bilhões de clientes em todo o mundo",

  "home.title_fm": "Conecte o Facebook Messenger",
  "home.description_fm": "Melhore o suporte e as reservas com o Messenger",

  "home.title_ig": "Conecte o Instagram",
  "home.description_ig":
    "Conecte a caixa de entrada da sua conta do Instagram para começar a receber mensagens",

  "home.messageLimit": "Limite de mensagens",
  "home.descriptionLimit":
    "{count, plural, um {# mensagen disponívei} other {# mensagens disponíveis}} de {messageLimit, number}",
  "home.dateLimit": "Reinicia às {date}",
  "home.increaseLimit": "Aumentar limite",

  "home.title_ai": "Configure as fontes de dados da sua IA",
  "home.description_ai": "Programe seu agente de IA com as informações certas",
  "home.pendingItems": "itens pendentes",
  "home.uniqueContacts": "Contatos Únicos",
  "home.totalMessages": "Mensagens Totais",
  "home.percentageAuto": "Resoluções por IA (%)",
  "home.noStats":
    "Parece que ainda não há dados. Por favor, volte mais tarde ou ajuste seus filtros.",

  "outbound.event": "Evento",
  "outbound.pageTitle": "Eventos",
  "outbound.pageDescription":
    "Melhore o engajamento dos hóspedes com gatilhos de comunicação automatizados ao longo da jornada de reserva",
  "outbound.viewEvent": "Ver evento",
  "outboud.messagesSent": "Mensagens enviadas nos últimos 30 dias",
  "outbound.reservation":
    "Após o hóspede concluir sua reserva, ele recebe uma mensagem de confirmação com os detalhes da reserva.",
  "outbound.time_reservation": "Enviado imediatamente",
  "outbound.arrival":
    "Antes da chegada, o hóspede recebe uma mensagem com informações úteis sobre a estadia.",
  "outbound.time_arrival": "2 dias antes da chegada",
  "outbound.stay":
    "Após o check-in do hóspede, ele recebe uma mensagem com instruções especiais e informações úteis",
  "outbound.time_stay": "Enviado imediatamente após a chegada",
  "outbound.after_departure":
    "Após o check-out do hóspede, ele recebe uma mensagem de agradecimento junto com um pedido de feedback",
  "outbound.time_after_departure": "1 dia após a partida",
  "outbound.inactiveTitle": "Tem certeza de que deseja desativar este evento?",
  "outbound.inactiveDesc":
    "Desativar este evento fará com que as respostas não sejam enviadas",
  "outbound.disabling": "Desativando evento...",
  "outbound.cardTitle": "Eventos de saída",
  "outbound.recentActivity": "Atividade recente",
  "outbound.noActivity": "Sem atividade",
  "outbound.missingChannel":
    "Ops! Você ainda não conectou um número de WhatsApp à sua conta.",
  "outbound.missingChannelDesc":
    "Conecte seu número de WhatsApp para interagir com seus hóspedes durante as reservas, chegadas e após a partida.",
  "outbound.missingChannelCTA": "Clique aqui para conectar",
  "outbound.modifyTemplate": "Modificar modelo",
  "outbound.templateTitle": "Editar {template} ",
  "outbound.templateLastUpdate":
    "Última modificação: {date} (Modificado {count, plural, one {# vez} other {# vezes}} em 30 dias)",
  "outbound.templateLangDesc":
    "Modifique a mensagem que deseja enviar aos hóspedes em {lang}",
  "outbound.templatePreview": "Pré-visualizar mensagem",
  "outbound.templatePreviewDesc": "Abaixo está como sua mensagem será exibida",
  "outbound.templateVarsInfo":
    "Certifique-se de que as tags foram inseridas corretamente. Consulte abaixo quais tags você pode usar",
  "outbound.templateVardDesc": "Use essas tags no corpo da mensagem",
  "outbound.templateTag": "Tag",
  "outbound.templateFunction": "Função",
  "outbound.template_confirmationId": "Número de confirmação",
  "outbound.template_username": "Nome completo do contato",
  "outbound.template_checkin": "Data de check-in do hóspede",
  "outbound.template_checkout": "Data de check-out do hóspede",
  "outbound.template_status_PENDING":
    "As novas alterações no modelo estão em revisão. Isso pode levar até 24 horas para aprovação. Você não poderá modificar o modelo enviado até que a revisão seja concluída.",
  "outbound.template_status_REJECTED":
    "Suas alterações foram rejeitadas pelo seguinte motivo: {reason}",
  "outbound.template_status_LESS_THAN_ONE_DAY":
    "Aguarde 24 horas desde a última atualização para editar o modelo novamente",
  "outbound.templateReviewTitle":
    "Tem certeza de que deseja enviar essas novas alterações para revisão?",
  "outbound.templateReviewDesc":
    "O envio para revisão pode levar até 24 horas para ser aprovado. Você ficará bloqueado de fazer mais alterações.",
  "outbound.templateSend": "Sim, enviar para revisão",
  "outbound.templateSendForReview":
    "O modelo foi enviado para revisão, o processo de aprovação pode levar até 24 horas",
  "outbound.template_review_url_Desc":
    "Este link será incluído se a avaliação for positiva",
  "outbound.template_review_url": "Link para avaliações positivas",
  "outbound.template_review_url_Placeholder":
    "https://tripadvisor.com/review/example",
  "outbound.templateNoLang": "Idioma indisponível",
  "outbound.templateNoLangDescription": "Adicione esse idioma para começar",
  "outbound.isLanguageActive": "Idioma ativo?",
  "onboarding.getStarted": "Começar",
  "onboarding.pageTite": "Introdução",
  "onboarding.stepsTitle":
    "Escolha um horário para concluir sua introdução ao Visito",
  "onboarding.step1":
    "Revise as informações da sua propriedade e as configurações do chat",
  "onboarding.step2":
    "Configure seus canais de comunicação, incluindo WhatsApp e Instagram",
  "onboarding.step3": "Visão geral dos recursos e capacidades da conta",
  "onboarding.step4":
    "Envie mensagens de teste e habilite o Visito em suas propriedades digitais",
  "onboarding.step5": "Responda a quaisquer outras dúvidas que você possa ter!",

  "onboarding.businessInfo": "Detalhes do seu negócio",
  "onboarding.businessInfoWorking":
    "Quase lá! Isso deve levar menos de um minuto...",
  "onboarding.businessInfoDone": "Tudo pronto.",
  "onboarding.businessInfoContinue":
    "Revise as informações e continue. Você sempre pode editar isso mais tarde.",
  "onboarding.channelsDone": "Tudo pronto!",
  "onboarding.continueToDashboard": "Continue para o painel",
  "onboarding.igConnect": "Conecte sua conta automaticamente.",
  "onboarding.igConnected": "Sua conta do Instagram foi conectada.",

  "onboarding.waConnect": "Comece em minutos.",
  "onboarding.waConnected": "Seu número de telefone do WhatsApp foi conectado.",
  "onboarding.channelsTitle": "Conecte Instagram ou WhatsApp para começar:",

  "newConv.Title": "Criar uma nova conversa",
  "newConv.Desc":
    "Insira o número do WhatsApp e a mensagem abaixo para criar uma nova conversa",
  "newConv.invalidPhone": "Parece que você inseriu um número incorreto.",
  "newConv.success": "Mensagem enviada com sucesso",
};

export default lang;
