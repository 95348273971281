export default function extractFilename(urlString: string): string | null {
  try {
    const url = new URL(urlString);
    const path = url.pathname;
    const fileNameEncoded = path.split('/').pop();

    if (!fileNameEncoded) {
      return null;
    }

    return decodeURIComponent(fileNameEncoded);
  } catch (error) {
    return null;
  }
}
