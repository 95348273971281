import React, { createContext, useContext, useEffect, useMemo } from "react";
import { Capacitor } from "@capacitor/core";
import { useHistory } from "react-router-dom";

import { useSocketStore } from "../store/socket";
import { createSocket } from "../utils/socket";

interface SocketContextType {
  userSocket: ReturnType<typeof createSocket> | null;
  webChatSocket: ReturnType<typeof createSocket> | null;
  emitEventUser: ({ name, data }: { name: string; data: any }) => void;
  emitEventWebChat: ({ name, data }: { name: string; data: any }) => void;
}

const SocketContext = createContext<SocketContextType | null>(null);

const connectAndSetupSocket = (
  socket: ReturnType<typeof createSocket>,
  eventHandlers: Record<string, (data: any) => void>,
  intervalEvents?: { name: string; interval: number; query: any }[],
  cleanupEvents?: string[]
) => {
  socket.connect();

  // Configurate dynamic events
  Object.entries(eventHandlers).forEach(([event, handler]) => {
    socket.on(event, handler);
  });

  // Configure heartbeats
  const intervals: NodeJS.Timer[] = [];
  if (intervalEvents) {
    intervalEvents.forEach(({ name, interval, query }) => {
      if (query) {
        const timer = setInterval(() => {
          socket.emit(name, query);
        }, interval);
        intervals.push(timer);
      }
    });
  }

  // Clean socket + custom clean events
  return () => {
    Object.keys(eventHandlers).forEach((event) => socket.off(event));
    if (cleanupEvents) {
      cleanupEvents.forEach((event) => socket.off(event));
    }
    intervals.forEach((timer) => clearInterval(timer));
    socket.disconnect();
  };
};

export const SocketProvider: React.FC<{
  children: React.ReactNode;
  isAuthenticated: boolean;
}> = ({ children, isAuthenticated }) => {
  const history = useHistory();
  const {
    userQuery,
    webChatQuery,
    setUserSocketSessionReady,
    setUserSocketReady,
    setWebChatSocketReady,
  } = useSocketStore();

  const userSocket = useMemo(() => {
    if (userQuery && userQuery?.email) {
      return createSocket(userQuery, "token");
    }
    return null;
  }, [userQuery]);
  const webChatSocket = useMemo(() => {
    if (webChatQuery && webChatQuery?.webChatId) {
      return createSocket(webChatQuery, "token");
    }
    return null;
  }, [webChatQuery]);

  // User socket
  useEffect(() => {
    if (!isAuthenticated || !userSocket) return;

    const cleanupUserSocket = connectAndSetupSocket(
      userSocket,
      {
        connect: () => {
          setUserSocketReady(true);
        },
        disconnect: () => {
          setUserSocketReady(false);
        },
        ready: () => {
          setUserSocketSessionReady(true);
        },
        "user-message": (message) => {
          switch (message.data.task) {
            case "logout":
              console.log("receieved logout...");
              window.location.reload();
              break;
          }
        },
        updateBrowser: () => {
          if (Capacitor.isNativePlatform()) {
            history.push(`/download-new-version`);
          } else {
            window.location.reload();
          }
        },
      },
      [],
      ["connect", "disconnect", "ready", "user-message", "updateBrowser"]
    );

    return () => {
      cleanupUserSocket();
    };
  }, [isAuthenticated, userSocket, userQuery, setUserSocketReady]);

  // WebChat socket
  useEffect(() => {
    if (!webChatSocket) return;

    const cleanupWebChatSocket = connectAndSetupSocket(
      webChatSocket,
      {
        connect: () => {
          console.log("WebChat socket connected");
          setWebChatSocketReady(true);
        },
        disconnect: () => {
          setWebChatSocketReady(false);
          console.log("WebChat socket disconnected");
        },
      },
      [],
      ["connect", "disconnect"]
    );

    return () => {
      cleanupWebChatSocket();
    };
  }, [webChatSocket, webChatQuery, setWebChatSocketReady]);

  const emitEventUser = ({ name, data }: { name: string; data: any }) =>
    emitEvent({ type: "user", data, name });

  const emitEventWebChat = ({ name, data }: { name: string; data: any }) =>
    emitEvent({ type: "web-chat", data, name });

  const emitEvent = ({
    type,
    name,
    data,
  }: {
    type: string;
    name: string;
    data: any;
  }) => {
    console.log("emitting...");
    const socket = type === "user" ? userSocket : webChatSocket;
    if (!socket) {
      console.warn(`Socket not initialized for type: ${type}`);
      return;
    }
    if (socket.connected) {
      console.log(`Emitting event '${name}'...`);
      socket.emit(name, data);
    } else {
      console.warn(`Socket not connected, queuing emit for '${name}'...`);
      socket.once("connect", () => socket.emit(name, data));
    }
  };

  return (
    <SocketContext.Provider
      value={{ userSocket, webChatSocket, emitEventUser, emitEventWebChat }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export const useSockets = () => {
  const context = useContext(SocketContext);
  if (!context) {
    throw new Error("useSockets must be used within a SocketProvider");
  }
  return context;
};
