import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useIntl } from "react-intl";
import { useIonToast } from "@ionic/react";
import { lazy, Suspense } from "react";

import AiApi from "../../../api/ai";
import SkeletonText from "../../SkeletonText";

const TextEditor = lazy(() => import("../../common/text-editor"));

export default function FreeText() {
  const queryClient = useQueryClient();
  const intl = useIntl();
  const [present] = useIonToast();

  const {
    data: statusData,
  } = useQuery({
    queryFn: () => AiApi.getAiStatus(),
    queryKey: ["getAiStatus"],
    refetchOnWindowFocus: false,
    staleTime: 300000,
  });

  const {
    data: aiData,
    isLoading: isGettingAi,
    isRefetching: isRefetchingAi,
  } = useQuery({
    queryFn: () => AiApi.getFreeText(),
    queryKey: ["getAiFreeText"],
    refetchOnWindowFocus: false,
  });

  const refreshData = async (embededContent?: string) => {
    const queriesToInvalidate = ["getAiFreeText"];
  
    if (!statusData?.status?.text || !embededContent?.trim()) {
      queriesToInvalidate.push("getAiStatus");
    }
  
    await Promise.all(
      queriesToInvalidate.map(queryKey =>
        queryClient.invalidateQueries({ queryKey: [queryKey] })
      )
    );
  };
  

  const mutateUpdateText =
    useMutation({
      mutationFn: AiApi.createUpdateFreeText,
      onSuccess: async (_, variables) => {
        await refreshData(variables.embededContent); 

        return present({
          message: intl.formatMessage({ id: "aiUpdateSucces" }),
          duration: 4000,
          color: "light",
          position: "top",
          cssClass: "success-toast",
        });
      },
      onError: () => {
        return present({
          message: intl.formatMessage({ id: "common.tryItLater" }),
          duration: 5000,
          color: "light",
          position: "top",
          cssClass: "error-toast",
        });
      },
    });

  const isRequesting = isGettingAi || isRefetchingAi || mutateUpdateText.isPending;
  const textEmbedding = aiData?.embedding || null;

  return (
    isRequesting ? (
      <SkeletonText rows={10} />
    ) : (
      <Suspense fallback={<SkeletonText rows={10} />}>
        <TextEditor
          text={textEmbedding?.embededContent || null}
          onSave={(embededContent) => mutateUpdateText.mutate({
            embededContent,
            embeddingId: textEmbedding?._id,
            updateTrain: !statusData?.status?.trained,
          })}
        />
      </Suspense>
    )
  )
}
