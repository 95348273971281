
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import styles from "./styles.module.css"

export default function SourcesAlert() {

  return (
    <div className={styles.overlayContainer}>
      <div className={styles.overlayContent}>
        <div className="flex flex-column gap-2">
          <h3 className="mb-0 fs-5">
            <FormattedMessage id="ai.sources.alert" />
          </h3>
          <p className="mb-0 fs-4">
            <FormattedMessage id="ai.sources.alert.desc" />
          </p>
        </div>
        <Link
          className="btn btn-visito-primary w-100"
          to="/ai/website"
        >
          <FormattedMessage id="ai.sources.alert.button" />
        </Link>
      </div>
    </div>
  );
}
