import React from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useIonToast, IonSpinner, IonAlert } from "@ionic/react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { IconPlus, IconSearch, IconX } from "@tabler/icons-react";

import CrawlingApi from "../../../api/crawling";

import { UrlWithStatus, useCrawlingActions, useCrawlingData } from "../../../store/crawling";
import { makeSelectUser } from "../../../selectors";
import AiApi from "../../../api/ai";
import { useSockets } from "../../../providers/socket";
import SkeletonText from "../../SkeletonText";
import Url, { WhileCrawling } from "./url";

import Alert from "../../common/alert";
import normalizeUrl from "./utils";

import globalOutboundStyles from "../../outbound/styles.module.css";
import styles from "./styles.module.css";

const MAX_ACTIVE_URLS = 250;

export default function CrawlWebsite() {
  const [website, setWebsite] = React.useState<string>();
  const [singleWebsite, setSingleWebsite] = React.useState<string>("");
  const [openAlert, setOpenAlert] = React.useState(false);
  const [currentEmbedding, setCurrentEmbedding] = React.useState<string>();
  const [alertType, setAlertType] = React.useState<"delete" | "recrawl">();
  const [showInput, setShowInput] = React.useState(false);

  const [present] = useIonToast();
  const intl = useIntl();
  const queryClient = useQueryClient();

  const { company } = useSelector(makeSelectUser());

  const { fullCrawl, singleCrawl } = useCrawlingData();
  const { setFullCrawl, setSingleCrawl, reset: resetCrawling } = useCrawlingActions();
  const { urls, isScanning, isOptimizing } = fullCrawl;

  const { userSocket } = useSockets();

  const needsCrawling = !urls || urls?.length === 0;
  const isMaxUrls = React.useMemo(() => {
    const activeFullCrawl = fullCrawl?.urls?.filter((u) => u.active)?.length;
    const activeSingleCrawl = singleCrawl?.filter((u) => u.active)?.length;

    return (activeFullCrawl || 0) + (activeSingleCrawl || 0) >= MAX_ACTIVE_URLS;
  }, [fullCrawl?.urls, singleCrawl]);

  const setUrlUpdate = (message: any) => {
    const isSingleCrawl = message.source === "single-crawl";
    const currentArr = isSingleCrawl ? singleCrawl : fullCrawl?.urls || [];
  
    const indexToUpdate = currentArr.findIndex(u => 
      normalizeUrl(u.url) === normalizeUrl(message.url)
    );
    if (indexToUpdate === -1) {
      const updatedArr = [...currentArr, { ...message, active: message.completed }];
      return isSingleCrawl
        ? setSingleCrawl(updatedArr)
        : setFullCrawl({ urls: updatedArr });
    };
  
    const updatedArr = [...currentArr];
    updatedArr[indexToUpdate] = {
      ...updatedArr[indexToUpdate],
      ...message,
      active: message.completed,
    };

    if (message.completed) {
      present({
        message: intl.formatMessage({ id: "aiUpdateSucces" }),
        duration: 4000,
        color: "light",
        position: "top",
        cssClass: "success-toast",
      });
    }
  
    return isSingleCrawl
      ? setSingleCrawl(updatedArr)
      : setFullCrawl({ urls: updatedArr });
  }

  const {
    data: aiData,
    refetch: refetchAi,
    isLoading: isGettingAi,
  } = useQuery({
    queryFn: () => AiApi.getWebsite(),
    queryKey: ["getWebsite"],
    refetchOnWindowFocus: false,
    staleTime: 300000,
  });

  const mutateDeleteUrl = useMutation({
    mutationFn: AiApi.deleteUrl,
    onSuccess: async () => {
      setCurrentEmbedding(undefined);
      refetchAi();
      return present({
        message: intl.formatMessage({ id: "aiUpdateSucces" }),
        duration: 4000,
        color: "light",
        position: "top",
        cssClass: "success-toast",
      });
    },
    onError: () => {
      setCurrentEmbedding(undefined);
      return present({
        message: intl.formatMessage({ id: "common.tryItLater" }),
        duration: 5000,
        color: "light",
        position: "top",
        cssClass: "error-toast",
      });
    },
  });

  const mutateActivateUrl = useMutation({
    mutationFn: AiApi.patchUrl,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["getWebsite"],
      });
      return present({
        message: intl.formatMessage({ id: "aiUpdateSucces" }),
        duration: 4000,
        color: "light",
        position: "top",
        cssClass: "success-toast",
      });
    },
    onError: () => {
      return present({
        message: intl.formatMessage({ id: "common.tryItLater" }),
        duration: 5000,
        color: "light",
        position: "top",
        cssClass: "error-toast",
      });
    },
  });

  const { mutate: mutateNewCrawling, isPending: isCreatingEvent } = useMutation(
    {
      mutationFn: CrawlingApi.newCrawlingEvent,
      onSuccess: ({ data }) => {
        if (data && data?.ok) {
          return setFullCrawl({ isScanning: true })
        }
      },
      onError: () => {
        return present({
          message: intl.formatMessage({ id: "common.tryItLater" }),
          duration: 6000,
          color: "light",
          position: "top",
          cssClass: "error-toast",
        });
      },
    }
  );

  const mutateResetCrawling = useMutation({
    mutationFn: AiApi.resetCrawling,
    onSuccess: async () => {
      return await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ["getWebsite"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["getAiStatus"],
        }),
      ]);
    },
    onError: () => {
      return present({
        message: intl.formatMessage({ id: "common.tryItLater" }),
        duration: 5000,
        color: "light",
        position: "top",
        cssClass: "error-toast",
      });
    },
  });

  const mutateSingleCrawling =
    useMutation({
      mutationFn: AiApi.singleCrawling,
      onSuccess: (data, variables) => {
        if (data && data?.success) {
          setSingleWebsite("");
          setShowInput(false);
          return setUrlUpdate({ ...variables, completed: false, error: false });
        }
      },
      onError: () => {
        return present({
          message: intl.formatMessage({ id: "common.tryItLater" }),
          duration: 6000,
          color: "light",
          position: "top",
          cssClass: "error-toast",
        });
      },
    });

  const onSubmitCrawling = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (website) {
      if (needsCrawling) {
        return mutateNewCrawling({ companyId: company.id, url: website });
      }
      setAlertType(() => "recrawl");
      return setOpenAlert(true);
    }
  };

  const onSubmitSingleCrawling = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (singleWebsite && singleWebsite.length > 0) {
      return mutateSingleCrawling.mutate({
        companyId: String(company.id),
        url: singleWebsite,
        // @ts-ignore,
        source: "single-crawl",
      });

    }
  }

  const onBeforeDeleteEmbedding = (embeddingId: string) => {
    setCurrentEmbedding(embeddingId);
    setAlertType(() => "delete");
    setOpenAlert(true);
  };

  const onDeleteEmbedding = () => {
    setOpenAlert(false);
    setAlertType(undefined);
    return mutateDeleteUrl.mutate({ embeddingId: currentEmbedding });
  };

  const onBeforeResetCrawling = () => {
    if (website) {
      setOpenAlert(false);
      setAlertType(undefined);
      return mutateResetCrawling.mutate({
        companyId: company.id,
        url: website,
      });
    }
  };

  const onHideAlert = () => {
    setCurrentEmbedding(undefined);
    setAlertType(undefined);
    return setOpenAlert(() => false);
  };

  React.useEffect(() => {
    if (!userSocket) return;
    const handleCompanyMessage = (event: any) => {


      // Only listen to events from current company;
      if (company?.id !== event?.companyId) return;
          
      if (event.data?.task !== "ai-crawling" && event.data?.task !== "ai-single-crawling") return;

      const handleCompletion = async () => {
        await queryClient.invalidateQueries({ queryKey: ["getAiStatus"] });
        refetchAi();
      };

      if (event.data?.task === "ai-crawling") {
        const {
          message: {
            urlsWithStatus = [],
            isScanning = false,
            isOptimizing = false,
            failed = false,
            completed = false,
          } = {},
        } = event.data;
    
        if (!urlsWithStatus) return;
    
        if (failed) {
          present({
            message: intl.formatMessage({ id: "aiCrawlingFailed" }),
            duration: 5800,
            color: "light",
            position: "top",
            cssClass: "error-toast",
          });
          return resetCrawling();
        }
    
        if (completed) {
          handleCompletion();
        }
    
        setFullCrawl({
          urls: urlsWithStatus,
          isOptimizing,
          isScanning,
          failed,
        });
      }

      if (event.data?.task === "ai-single-crawling") {
        const message: UrlWithStatus = event.data?.message;
        if (!message) return;

        if (message.completed && fullCrawl?.urls?.length === 0 && singleCrawl?.length === 1) {
          handleCompletion();
        }

        if (message.failed) {
          present({
            message: intl.formatMessage({ id: "aiCrawlingFailed" }),
            duration: 5800,
            color: "light",
            position: "top",
            cssClass: "error-toast",
          });
          resetCrawling()
          return handleCompletion();
        }

        setUrlUpdate(message);
      }
    };
        
    userSocket.on("company-message", handleCompanyMessage);
    return () => {
      userSocket.off("company-message");
    };
  }, [userSocket, fullCrawl, singleCrawl]);

  React.useEffect(() => {
    const embeddings = aiData?.embeddings;
    if (!embeddings) return;

    const { fullCrawl, singleCrawl } = embeddings;

    const updatedFullCrawl =
      urls && urls.length > 0 && fullCrawl?.length === 0 ? urls : fullCrawl;

    setFullCrawl({ urls: updatedFullCrawl });
    setSingleCrawl(singleCrawl);
  }, [aiData]);

  React.useEffect(() => {
    if (!website && website !== "" && company?.website) {
      setWebsite(company.website);
    }
  }, [website, company?.website]);

  const isRequesting =
    isCreatingEvent || isScanning ||
    isOptimizing || mutateResetCrawling.isPending ||
    fullCrawl?.urls?.[0]?.completed === false;
  
  const isFetchingData = isGettingAi || mutateDeleteUrl.isPending;

  const isDeleteAlert = alertType === "delete";

  const hasFullCrawling = urls && urls?.length > 0;
  const hasSingleCrawling = singleCrawl && singleCrawl?.length > 0;

  return isFetchingData ? (
    <SkeletonText rows={10} />
  ) : (
    <div className="flex flex-column gap-4 w-100 h-100 overflow-y-none">
      {isMaxUrls && (
        <div className="flex flex-column gap-3">
          <Alert
            type="danger"
            className="p-3"
            message={intl.formatMessage({ id: "ai.sources.website.max" }, { limit: MAX_ACTIVE_URLS })}
          />
        </div>
      )}
      <div className={`${styles.fullCrawlingContainer} flex flex-column gap-3 w-100`}>
        {!isMaxUrls && (
          <div className="flex flex-column gap-1 w-100">
            <form onSubmit={onSubmitCrawling} className="flex flex-row gap-2 w-100">
              <input
                type="text"
                className="form-control no-focus"
                value={website || ""}
                onChange={(e) => setWebsite(e.target.value)}
                disabled={isRequesting}
                autoComplete="off"
                required
              />
              <button
                className="btn btn-visito-primary btn-sm w-20"
                disabled={isRequesting}
                type="submit"
              >
                {isRequesting ? (
                  <IonSpinner name="circular" style={{ color: "var(--ion-color-light)", width: "19px", height: "19px" }} />
                ) : (
                  <FormattedMessage id="common.scan" />
                )}
              </button>
            </form>
            <span className="description fs-2">
              <FormattedMessage id="ai.sources.website.fullCrawl" />
            </span>
          </div>
        )}
        {hasFullCrawling && (
          <div className={styles.siteMapContainer}>
            <div className={`${styles.linksContainer} flex flex-column`}>
              {urls.map((u) => (
                <Url
                  url={u}
                  key={u.url}
                  isCrawling={isRequesting}
                  onDelete={onBeforeDeleteEmbedding}
                  onEnableUrl={mutateActivateUrl.mutate}
                  onRescanUrl={(data) => mutateSingleCrawling.mutate({
                    ...data as any,
                    companyId: String(company.id)
                  })}
                />
              ))}
            </div>
          </div>
        )}

        {!hasFullCrawling && isRequesting && <WhileCrawling url={website || ""} />}
      </div>

      <div className={`${styles.singleCrawlingContainer} flex flex-column gap-3 w-100`}>
        <div className="flex flex-column w-100">
          <div className="flex align-items-center gap-3 w-100">
            <hr className={styles.divider} />
            <p className="w-max mb-1 text-nowrap description">Extra Links</p>
            <hr className={styles.divider} />
          </div>
          {!isMaxUrls && (
            <div className="flex flex-column gap-1 w-100">
              {showInput ? (
                <>
                  <form onSubmit={onSubmitSingleCrawling} className="flex flex-row gap-3 w-100 mt-2">
                    <input
                      type="text"
                      className="form-control no-focus"
                      value={singleWebsite}
                      onChange={(e) => setSingleWebsite(e.target.value)}
                      disabled={mutateSingleCrawling.isPending}
                      autoComplete="off"
                      required
                      autoFocus
                    />
                    <button
                      className="no-bg color-visito-1"
                      disabled={isRequesting}
                      type="submit"
                    >
                      {mutateSingleCrawling.isPending ? (
                        <IonSpinner name="circular" style={{ color: "var(--ion-color-light)", width: "19px", height: "19px" }} />
                      ) : (
                        <IconSearch size={23} />
                      )}
                    </button>
                    {!isRequesting && (
                      <button
                        className="no-bg color-step-650"
                        onClick={() => setShowInput(false)}
                        type="button"
                      >
                        <IconX size={21} /> 
                      </button>
                    )}
                  </form>
                  <span className="description fs-2">
                    <FormattedMessage id="ai.sources.website.singleCrawl" />
                  </span>
                </>
              ) : (
                <button
                  className="btn btn-visito-outline btn-sm w-auto ml-auto"
                  onClick={() => setShowInput(true)}
                >
                  <IconPlus size={20} />
                </button>
              )}
            </div>
          )}
        </div>

        {hasSingleCrawling && (
          <div className={styles.siteMapContainer}>
            <div className={`${styles.linksContainer} flex flex-column`}>
              {singleCrawl.map((u) => (
                <Url
                  key={u.url}
                  url={u}
                  isCrawling={isRequesting}
                  onDelete={onBeforeDeleteEmbedding}
                  onEnableUrl={mutateActivateUrl.mutate}
                  onRescanUrl={(data) => mutateSingleCrawling.mutate({
                    ...data as any,
                    companyId: String(company.id)
                  })}
                />
              ))}
            </div>
          </div>
        )}
      </div>

      <IonAlert
        isOpen={openAlert}
        header={intl.formatMessage({
          id: isDeleteAlert
            ? "ai.sources.website.delete.confirmation"
            : "ai.sources.website.rescan.confirmation",
        })}
        message={intl.formatMessage({
          id: isDeleteAlert
            ? "ai.sources.website.delete.confirmationDesc"
            : "ai.sources.website.delete.confirmationDesc",
        })}
        buttons={[
          {
            text: intl.formatMessage({ id: "common.cancel" }),
            role: "cancel",
            handler: onHideAlert,
            cssClass: `${globalOutboundStyles.alertCancel} ${globalOutboundStyles.alertBtn}`,
          },
          {
            text: intl.formatMessage({
              id: isDeleteAlert ? "common.confirmDelete" : "common.confirmScan",
            }),
            role: "confirm",
            handler: isDeleteAlert ? onDeleteEmbedding : onBeforeResetCrawling,
            cssClass: `${globalOutboundStyles.alertConfirm} ${globalOutboundStyles.alertBtn}`,
          },
        ]}
        onDidDismiss={onHideAlert}
      />
    </div>
  );
}
