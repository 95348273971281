import { useState, lazy, Suspense, useEffect } from "react";
import { connect } from "react-redux";
import {
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonSearchbar,
  IonChip,
  IonSpinner,
} from "@ionic/react";
import classNames from "classnames";
import { FormattedMessage, useIntl } from "react-intl";
import { IconPlus } from "@tabler/icons-react";

import { Contact, Settings } from "../../../interfaces";

import "./Chats.css";

//Components
import ChatList from "../../../components/ChatList";
import SkeletonLoader from "../../../components/SkeletonLoader";

//API
import ContactsApi from "../../../api/contacts";

//Stores
import useContactsStore from "../../../store/contacts";
import { useSocketStore } from "../../../store/socket";

// Socket
import { useSockets } from "../../../providers/socket";
import { se } from "date-fns/locale";

const list: Contact[] = [];

const NewConversation = lazy(
  () => import("../../../components/new-conversation")
);

const Chats: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const { settings } = props;
  const [loading, setLoading] = useState(true); // Whether we receieved user messages

  const [isOpenNewConv, setOpenNewConv] = useState<boolean>(false);

  const {
    contactList: contacts,
    escalatedCount,
    filters,
    setFilters,
    updateContactsDb,
    searchResults,
    setSearchResults,
    resetSearchResults,
    isSearching,
    setIsSearching,
    searchActive,
    setSearchActive,
  } = useContactsStore();

  const { userSocketSessionReady, userSocketReady } = useSocketStore();
  const { userSocket, emitEventUser } = useSockets();

  const intl = useIntl();

  const handleSearch = async (
    event: CustomEvent<{ value?: string | null | undefined }>
  ) => {
    if (event.detail.value) {
      setIsSearching(true);
      const { data, err } = await ContactsApi.search(
        event.detail.value,
        settings.companies
      );
      setIsSearching(false);
      if (data) {
        setSearchActive(true);
        setSearchResults(data.results);
      }
      if (err) {
        console.log(err);
        setSearchActive(false);
      }
    } else {
      resetSearchResults();
    }
  };

  useEffect(() => {
    if (!userSocket || !userSocketSessionReady) return;
    const handleNewMessage = (message: any) => {
      setLoading(false);
      if (message.data) {
        console.log("setting up new data: ", message.data.length);
        updateContactsDb(message.data);
      }
    };
    // Attach event listener
    userSocket.on("new-message", handleNewMessage);
    emitEventUser({ name: "initial-connection", data: {} });
    return () => {
      console.log("cleaning up handler chats...");
      userSocket.off("new-message", handleNewMessage);
    };
  }, [userSocket, userSocketSessionReady]);

  useEffect(() => {
    if (loading) return;
    if (!userSocketReady) return;
    console.log("re-requesting chats...");
    emitEventUser({ name: "initial-connection", data: {} });
  }, [userSocketReady]);

  return (
    <>
      <IonPage id="main-content">
        <IonHeader>
          <IonToolbar>
            <IonTitle>
              <FormattedMessage id="nav.inbox" />
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen={true} className="chat-mobile-wrapper">
          <div className="chat-interface">
            <div className="chat-interface-nav">
              <div className="flex gap-2 align-items-center searchbar-container">
                <IonSearchbar
                  debounce={300}
                  placeholder={intl.formatMessage({
                    id: "chats.searchContact",
                  })}
                  onIonInput={(ev) => handleSearch(ev)}
                  className={classNames({
                    "search-is-active": searchActive === true,
                  })}
                  inputmode={`text`}
                  mode={`ios`}
                  style={{ zIndex: 5000 }}
                  showCancelButton={searchActive ? "always" : "never"}
                  showClearButton="never"
                  onIonFocus={() => {
                    setSearchActive(true);
                  }}
                  onIonClear={() => {
                    setIsSearching(false);
                    setSearchActive(false);
                    resetSearchResults();
                  }}
                />
                {!searchActive && settings.isWaReady && (
                  <>
                    <IconPlus
                      role="button"
                      color="var(--visito-color-1)"
                      size={26}
                      onClick={() => setOpenNewConv(true)}
                    />
                    <Suspense fallback="Loading...">
                      <NewConversation
                        isOpen={isOpenNewConv}
                        onOpenChange={setOpenNewConv}
                        settings={settings}
                        id="new-conversation-mobile-modal"
                      />
                    </Suspense>
                  </>
                )}
              </div>
              <div
                className="chat-interface-nav-review fw-semibold-1"
                style={{
                  display: searchActive ? "none" : "block",
                }}
              >
                {!userSocketReady || loading ? (
                  <div className="connection-status fs-3 color-step-700 flex align-items-center ">
                    <IonSpinner
                      color="medium"
                      name="lines-sharp-small"
                    ></IonSpinner>{" "}
                    <FormattedMessage id="common.connecting" />
                  </div>
                ) : (
                  <>
                    <IonChip
                      onClick={() => setFilters({ simple: [], companies: [] })}
                      color={
                        !filters.simple.length && !filters.companies.length
                          ? undefined
                          : "medium"
                      }
                    >
                      <FormattedMessage id="chats.all" />
                    </IonChip>
                    {["escalated", "manual", "isBook", "isManual"].map((s) => (
                      <IonChip
                        key={s}
                        onClick={() =>
                          setFilters({
                            ...filters,
                            simple: [s as keyof Contact],
                          })
                        }
                        color={
                          filters.simple.includes(s as keyof Contact)
                            ? undefined
                            : "medium"
                        }
                      >
                        <FormattedMessage id={`chats.${s}`} />{" "}
                        {s === "escalated" && <>({escalatedCount})</>}
                      </IonChip>
                    ))}
                    {settings.companies.length > 1
                      ? settings.companies.map((c) => (
                          <IonChip
                            key={c.id}
                            onClick={() => {
                              const newFilter = {
                                ...filters,
                                companies: [c.id],
                              };
                              setFilters(newFilter);
                              emitEventUser({
                                name: "requests",
                                data: {
                                  request: { filter: newFilter },
                                },
                              });
                            }}
                            color={
                              filters.companies.includes(c.id)
                                ? undefined
                                : "medium"
                            }
                          >
                            {c.name}
                          </IonChip>
                        ))
                      : null}
                  </>
                )}
              </div>
            </div>

            {loading ? (
              <SkeletonLoader rows={10} />
            ) : (
              <ChatList
                contacts={contacts}
                mode={"mobile"}
                //changeStatus={changeStatusContact}
                isSearch={searchActive}
                searchResults={searchResults}
                filters={filters}
                isMulti={settings.multiAccount}
                loading={loading}
                isSearching={isSearching}
              />
            )}
          </div>
        </IonContent>
      </IonPage>
    </>
  );
};

interface ILayoutProps {
  dispatch: Function;
  settings: Settings;
}

export default connect((props: any) => ({
  settings: props.app.settings,
}))(Chats);
