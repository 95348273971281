import { connect } from "react-redux";
import { Route } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { isPlatform, useIonToast } from "@ionic/react";
import { useEffect } from "react";
import { Capacitor } from "@capacitor/core";

// Config
import config from "../../config";

// Interfaces
import { User, Event, Settings } from "../../interfaces";

// Actions
import { setSettings, setAppPushToken } from "../../actions/app";

// API
import SettingsApi from "../../api/settings";
import AuthApi from "../../api/auth";

//Pages
import MobileHome from "./MobileHome";
import DesktopHome from "./DesktopHome";

// Components
import Nots from "../../components/DesktopNotification";
import Push from "../../components/PushNotification";
import GA from "../../components/GoogleAnalytics";

// Store & Providers
import { setCompany } from "../../utils/localStorage";
import { useSocketStore } from "../../store/socket";
import { useSockets } from "../../providers/socket";

const version = config.VERSION;

const mobile = isPlatform("android") || isPlatform("ios");

const HOCDashboard: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const history = useHistory();
  const { user, logOut, refreshUser, pushToken } = props;
  const [present] = useIonToast();

  const { setUserQuery } = useSocketStore();
  const { userSocket } = useSockets();

  const getSettings = async () => {
    const { data, err } = await SettingsApi.get();
    if (data) {
      props.dispatch(setSettings(data.settings));
    }
    if (err) {
      props.dispatch(setSettings({}));
    }
  };

  const switchCompany = async ({ companyId }: { companyId: string }) => {
    const { data, err } = await AuthApi.switchCompany({ companyId });
    if (data) {
      const { company } = data.user;
      await setCompany({ company });
      window.location.reload();
    }
    if (err) {
      return present({
        message: "Something went wrong",
        duration: 5000,
        color: "danger",
        position: "top",
      });
    }
  };

  useEffect(() => {
    const query = { email: user.email, ...version, mobile };
    setUserQuery(query);
  }, []);

  useEffect(() => {
    getSettings();
  }, [props.dispatch]);

  useEffect(() => {
    if (!userSocket) return;
    userSocket.on("company-message", (message: any) => {
      switch (message.data?.task) {
        case "settings":
          // User is currently in the company that the settings are for
          if (props.settings.company?.id === message?.companyId) {
            console.log("refreshing settings reducer.....");
            props.dispatch(setSettings(message.data.message));
          }
          break;
      }
    });
    return () => {
      userSocket.off("company-message");
    };
  }, [userSocket]);

  const pushEvent = async (event: Event) => {
    switch (event.type) {
      case "chat":
        mobile && event.data && history.push(`/chat/${event.data.id}`);
        !mobile && event.data && history.push(`/chats?chat=${event.data.id}`);
        break;
    }
  };

  return mobile ? (
    <>
      <GA />
      <Push
        request={Capacitor.isNativePlatform()}
        setEvent={pushEvent}
        setPushToken={(token: string) =>
          props.dispatch(setAppPushToken({ token }))
        }
        pushToken={pushToken}
      />
      <Route
        path="/"
        render={(props) => (
          <MobileHome
            {...props}
            logOut={logOut}
            refreshUser={refreshUser}
            user={user}
            onRequestSettings={() => getSettings()}
            switchCompany={switchCompany}
          />
        )}
      />
    </>
  ) : (
    <>
      <GA />
      <Nots request={!mobile} setEvent={pushEvent} />
      <Route
        path="/"
        render={(props) => (
          <DesktopHome
            {...props}
            logOut={logOut}
            refreshUser={refreshUser}
            switchCompany={switchCompany}
            user={user}
            onRequestSettings={() => getSettings()}
          />
        )}
      />
    </>
  );
};

interface ILayoutProps {
  user: User;
  logOut: Function;
  refreshUser: Function;
  dispatch: Function;
  settings: Settings;
  pushToken: String;
}

export default connect((props: any) => ({
  settings: props.app.settings,
  pushToken: props.app.auth.pushToken,
}))(HOCDashboard);
