import { useEffect, useState } from "react";
import { PushNotifications } from "@capacitor/push-notifications";
import { App } from "@capacitor/app";
import AuthApi from "../api/auth";

const PushNotifcations: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const { request, setPushToken, pushToken, setEvent } = props;

  useEffect(() => {
    if (request) {
      const registerNotifications = async () => {
        try {
          await PushNotifications.addListener("registration", async (token) => {
            if (token.value) {
              const { err } = await AuthApi.push({
                active: true,
                token: token.value,
              });
              setPushToken(token.value);
              if (err) {
                console.log(err);
              }
            }
            console.log("Registration token: ", token.value);
          });

          await PushNotifications.addListener("registrationError", (err) => {
            console.log("Registration error: ", err.error);
          });

          await PushNotifications.addListener(
            "pushNotificationReceived",
            (notification) => {
              console.log("Push notification received: ", notification);
            }
          );

          await PushNotifications.addListener(
            "pushNotificationActionPerformed",
            (action) => {
              const { notification } = action;
              const { type, payload } = notification.data;
              setEvent({
                timeStamp: new Date().toString(),
                data: JSON.parse(payload),
                type,
                companyId: `undefined`,
              });
            }
          );
          let permStatus = await PushNotifications.checkPermissions();
          if (permStatus.receive === "prompt") {
            permStatus = await PushNotifications.requestPermissions();
          }
          if (permStatus.receive !== "granted") {
            throw new Error("User denied permissions!");
          }
          await PushNotifications.register();
        } catch (e) {
          console.log(e);
        }
      };
      registerNotifications();
    }
  }, [request]);
  useEffect(() => {
    if (pushToken) {
      let listenerHandle: any;
      App.addListener("appStateChange", async (props) => {
        console.log(
          "App has been opened or brought to the foreground kkk",
          pushToken
        );
        await AuthApi.resetPush({ pushToken, active: props.isActive });
      }).then((handle) => {
        listenerHandle = handle;
      });
      return () => {
        if (listenerHandle) {
          listenerHandle.remove();
        }
      };
    }
  }, [pushToken]);
  return <div></div>;
};

interface ILayoutProps {
  request: boolean;
  setEvent: Function;
  setPushToken: Function;
  pushToken: String;
}

export default PushNotifcations;
