import { useEffect } from "react";
import config from "../config";
import { useSockets } from "../providers/socket";
import { useTabActive } from "../utils/useActiveTab";
const { CDN_ROOT } = config;
const ringer = require("../sounds/not.mp3");

const DesktopNotification: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const { request } = props;
  const { userSocket } = useSockets();
  useEffect(() => {
    if (!("Notification" in window)) {
      console.log("Browser does not support desktop notification");
    } else {
      request && Notification.requestPermission();
    }
  }, []);

  useEffect(() => {
    if (!userSocket) return;
    const audio = new Audio(ringer);
    const sendNotification = (message: any) => {
      console.log("new notification...");
      try {
        const { data } = message;
        const { notification, payload } = data;
        const not = new Notification(`${notification.title}`, {
          body: notification.body,
          icon: `${CDN_ROOT}/statics/logo/letters-square.png`,
          badge: `${CDN_ROOT}/statics/logo/letters-square.png`,
          // image: `${CDN_ROOT}/statics/logo/letters-square.png`,
        });

        not.onclick = (event) => {
          props.setEvent({
            timeStamp: new Date().toString(),
            data: payload,
            type: "chat",
            companyId: `undefined`,
          });
          not.close();
        };
        audio.play();
      } catch (e) {
        console.error(e);
      }
    };

    // Attach event listener
    userSocket.on("not-escalation", sendNotification);
    userSocket.on("not-new-message", sendNotification);

    // Detach event listener
    return () => {
      console.log("Cleaning up socket listeners...");
      userSocket.off("not-escalation", sendNotification);
      userSocket.off("not-new-message", sendNotification);
    };
  }, [userSocket]);

  return <></>;
};

interface ILayoutProps {
  request: boolean;
  setEvent: Function;
}

export default DesktopNotification;
