import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Container from "react-bootstrap/Container";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import classNames from "classnames";

import { IconLogout, IconSettings, IconBook2 } from "@tabler/icons-react";

import "./NavBar.css";
import { User, Settings } from "../interfaces";
import AiSvg from "../theme/images/ai.svg";
import config from "../config";
import AccountDropDown from "./common/account-drop-down";

const isDev = config.ENV === "staging" || config.ENV === "local";

const NavBar: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const { user, logOut, path, settings, switchCompany } = props;
  return (
    <Navbar className="bg-body-tertiary visito-navbar">
      <Container fluid>
        <Navbar.Brand as={Link} to={"/"}>
          <div className="logo"></div>
        </Navbar.Brand>
        <Nav className="me-auto">
          <Nav.Link
            className={classNames("menu-list-link", {
              active: path === "/",
            })}
            as={Link}
            to="/"
          >
            <FormattedMessage id="nav.home" />
            {/*
              Not to deploy now
              {settings.badge ? (
                <span className="badge badge-visito">{settings.badge}</span>
              ) : null}
            */}
          </Nav.Link>
          <Nav.Link
            className={classNames("menu-list-link", {
              active: path.includes("/chats"),
            })}
            as={Link}
            to="/chats"
          >
            <FormattedMessage id="nav.inbox" />
          </Nav.Link>

          {settings.events ? (
            <Nav.Link
              className={classNames("navigation-link", {
                active: path.includes("/outbound"),
              })}
              as={Link}
              to="/outbound"
            >
              <FormattedMessage id="nav.outbound" />
            </Nav.Link>
          ) : null}
          {/*isDev ? (
            <Nav.Link
              className={classNames("navigation-link", {
                active: path.includes("/crm"),
              })}
              as={Link}
              to="/crm"
            >
              <FormattedMessage id="nav.crm" />
            </Nav.Link>
          ) : null */}
          <Nav.Link
            className={classNames(
              "navigation-link flex align-items-center gap-1",
              {
                active: path.includes("/ai") && !path.includes("/ai-settings"),
              }
            )}
            as={Link}
            to="/ai"
          >
            <FormattedMessage id="nav.ai" />
            <img alt="ai-icon" src={AiSvg} />
          </Nav.Link>
          <Nav.Link
            className={classNames("navigation-link", {
              active: path.includes("/integrations"),
            })}
            as={Link}
            to="/integrations"
          >
            <FormattedMessage id="nav.integrations" />
          </Nav.Link>
          <Nav.Link
            className={classNames("navigation-link", {
              active: path.includes("/connect"),
            })}
            as={Link}
            to="/connect"
          >
            <FormattedMessage id="nav.connect" />
          </Nav.Link>
        </Nav>

        <NavDropdown
          title={<div className="company-name">{settings.company.name}</div>}
          className="account-switcher"
        >
          <NavDropdown.Header>{user.email}</NavDropdown.Header>
          <NavDropdown.Item as={Link} to="/settings">
            <IconSettings size={16} style={{ marginTop: "-2px" }} />{" "}
            <FormattedMessage id="nav.settings" />
          </NavDropdown.Item>
          {/*<NavDropdown.Item as={Link} to="https://www.visitoai.com/en/guide">
              <IconMessages size={16} style={{ marginTop: "-2px" }} />{" "}
              <FormattedMessage id="nav.helpChat" />
            </NavDropdown.Item>
            */}
          <NavDropdown.Item
            as="a"
            href={config.HELP_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconBook2 size={16} style={{ marginTop: "-2px" }} />{" "}
            <FormattedMessage id="nav.helpCenter" />
          </NavDropdown.Item>
          <NavDropdown.Header className="uppercase">
            <FormattedMessage id="nav.accountList" />{" "}
            {settings.companies.length > 1
              ? `(${settings.companies.length})`
              : ""}
          </NavDropdown.Header>
          <AccountDropDown
            currentCompany={settings.company}
            companies={settings.companies}
            onSelectCompany={(id) => switchCompany({ companyId: id })}
          />
          <NavDropdown.Item
            as={Link}
            to="/account/create"
            className="account-switcher-create-account"
          >
            + <FormattedMessage id="nav.createAccount" />
          </NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item
            onClick={(e) => {
              e.preventDefault();
              logOut();
            }}
          >
            <IconLogout size={16} style={{ marginTop: "-2px" }} />{" "}
            <FormattedMessage id="nav.logout" />
          </NavDropdown.Item>
        </NavDropdown>
      </Container>
    </Navbar>
  );
};

interface ILayoutProps {
  user: User;
  logOut: Function;
  switchCompany: Function;
  settings: Settings;
  path: string;
}

export default NavBar;
