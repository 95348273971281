import React, { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  IconLink,
  IconListDetails,
  IconCloudUpload,
  IconChevronRight,
  IconMessage,
  IconCircleCheckFilled,
  IconPlug,
  IconSettings,
} from "@tabler/icons-react";
import { IonContent, IonPage } from "@ionic/react";
import { useQuery } from "@tanstack/react-query";
import { FormattedMessage } from "react-intl";

import AiApi from "../../api/ai";
import SkeletonText from "../SkeletonText";
import SourcesAlert from "./sources/alert";

import styles from "./styles.module.css";

type AiLayoutProps = {
  children: React.ReactNode;
};

const NAV_ITEMS = [
  {
    path: "/ai/website",
    key: "website",
    label: "ai.sources.website",
    icon: <IconLink size={17} />,
  },
  {
    path: "/ai/text",
    key: "text",
    label: "ai.sources.text",
    icon: <IconListDetails size={17} />,
  },
  {
    path: "/ai/files",
    key: "files",
    label: "ai.sources.files",
    icon: <IconCloudUpload size={17} />,
  },
  {
    path: "/ai/providers",
    key: "provider",
    label: "ai.sources.providers",
    icon: <IconPlug size={17} />,
  },
];

const HEADER_DESCRIPTIONS: Record<string, React.ReactNode> = {
  "/ai/website": (
    <>
      <h2 className="fs-5 mb-0">
        <FormattedMessage id="ai.sources.website" />
      </h2>
      <p className="description mb-0">
        <FormattedMessage id="ai.sources.website.desc" />
      </p>
    </>
  ),
  "/ai/text": (
    <>
      <h2 className="fs-5 mb-0">
        <FormattedMessage id="ai.sources.text" />
      </h2>
      <p className="description mb-0">
        <FormattedMessage id="ai.sources.text.desc" />
      </p>
    </>
  ),
  "/ai/files": (
    <>
      <h2 className="fs-5 mb-0">
        <FormattedMessage id="ai.sources.files" />
      </h2>
      <p className="description mb-0">
        <FormattedMessage id="ai.sources.files.desc" />
      </p>
    </>
  ),
  "/ai/providers": (
    <>
      <h2 className="fs-5 mb-0">
        <FormattedMessage id="ai.sources.providers" />
      </h2>
      <p className="description mb-0">
        <FormattedMessage id="ai.sources.providers.desc" />
      </p>
    </>
  ),
  "/ai/customer": (
    <>
      <h2 className="fs-5 mb-0">
        <FormattedMessage id="ai.sources.customerContext" />
      </h2>
      <p className="description mb-0">
        <FormattedMessage id="ai.sources.customerContext.desc" />
      </p>
    </>
  ),
  "/ai/instructions": (
    <>
      <h2 className="fs-5 mb-0">
        <FormattedMessage id="ai.settings.instructions" />
      </h2>
      <p className="description mb-0">
        <FormattedMessage id="ai.settings.instructions.desc" />
      </p>
    </>
  ),
};

const LayoutSkeleton = () => (
  <IonPage id="main-content">
    <IonContent>
      <div className="page-wrapper">
        <div className="w-100 h-100">
          <div className="row h-100">
            <div className="col-md-4">
              <div className="flex flex-column w-100 h-100 p-5">
                <SkeletonText rows={16} />
              </div>
            </div>
            <div className="col-md-8">
              <div className="flex flex-column w-100 h-100 p-5">
                <SkeletonText rows={16} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </IonContent>
  </IonPage>
);

const baseLinkClass = "flex flex-column gap-2 no-underline p-3";

export default function AiLayout({ children }: AiLayoutProps) {
  const location = useLocation();
  const path = location.pathname;

  const headerContent = useMemo(
    () => HEADER_DESCRIPTIONS[path] || null,
    [path]
  );
  const isHome = path === "/ai";

  const { data, isLoading: isRequesting } = useQuery({
    queryFn: () => AiApi.getAiStatus(),
    queryKey: ["getAiStatus"],
    refetchOnWindowFocus: false,
    staleTime: 300000,
  });

  if (isRequesting) {
    return <LayoutSkeleton />;
  }

  const aiData: Record<string, any> | null = data?.status || null;

  return (
    <IonPage id="main-content">
      <IonContent>
        <div className="page-wrapper">
          <div className="w-100 h-100">
            <div className="row h-100">
              <div className={`col-md-4 ${styles.navContainer}`}>
                <div className="flex flex-column align-items-center gap-4 w-100 h-100 px-5 py-4">
                  <div className="flex flex-column gap-2 w-100">
                    <h2 className="fs-5 mb-0 text-start w-100 flex flex-column gap-1">
                      <FormattedMessage id="ai.sources" />
                      <span className="description fs-2 fw-normal">
                        <FormattedMessage id="ai.sources.desc" />
                      </span>
                    </h2>
                    <nav className="flex flex-column gap-3 border-0 w-100 bg-color-0">
                      {NAV_ITEMS.map(({ path: linkPath, label, key, icon }) => (
                        <Link
                          key={linkPath}
                          to={linkPath}
                          className={`${baseLinkClass} ${styles.link} ${
                            path === linkPath ? styles.activeLink : ""
                          }`}
                        >
                          <div className="flex align-items-center justify-content-between gap-2 w-100">
                            <div className="flex align-items-center gap-2">
                              {icon}
                              <FormattedMessage id={label} />
                              {aiData?.[key] ? (
                                <IconCircleCheckFilled
                                  style={{
                                    color: "var(--visito-color-active)",
                                  }}
                                  size={18}
                                />
                              ) : (
                                <span className={styles.statusBadge}>
                                  <FormattedMessage id="common.setUp" />
                                </span>
                              )}
                            </div>
                            <IconChevronRight size={18} />
                          </div>
                        </Link>
                      ))}
                    </nav>
                  </div>

                  <div className="flex flex-column gap-2 w-100">
                    <h2 className="fs-5 mb-0 text-start w-100 flex flex-column gap-1">
                      <FormattedMessage id="ai.playground" />
                      <span className="description fs-2 fw-normal">
                        <FormattedMessage id="ai.playground.desc" />
                      </span>
                    </h2>
                    <Link
                      to="/ai"
                      className={`${baseLinkClass} ${styles.link} ${
                        isHome ? styles.activeLink : ""
                      }`}
                    >
                      <div className="flex align-items-center justify-content-between gap-2 w-100">
                        <div className="flex align-items-center gap-2">
                          <IconMessage size={18} />
                          <FormattedMessage id="ai.playground.test" />
                        </div>
                        <IconChevronRight size={18} />
                      </div>
                    </Link>
                  </div>

                  <div className="flex flex-column gap-2 w-100">
                    <h2 className="fs-5 mb-0 text-start w-100 flex flex-column gap-1">
                      <FormattedMessage id="ai.settings" />
                      <span className="description fs-2 fw-normal">
                        <FormattedMessage id="ai.settings.desc" />
                      </span>
                    </h2>
                    <Link
                      to="/ai/instructions"
                      className={`${baseLinkClass} ${styles.link} ${
                        path === "/ai/instructions" ? styles.activeLink : ""
                      }`}
                    >
                      <div className="flex align-items-center justify-content-between gap-2 w-100">
                        <div className="flex align-items-center gap-2">
                          <IconSettings size={18} />
                          <FormattedMessage id="ai.settings.instructions" />
                        </div>
                        <IconChevronRight size={18} />
                      </div>
                    </Link>
                  </div>

                </div>
              </div>

              <div className={`col-md-8 pl-0 ${styles.mainContent}`}>
                {!aiData?.trained && isHome && <SourcesAlert />}
                <div
                  className={`w-full flex flex-column gap-3 px-5 py-4 h-100 ${
                    isHome ? "" : "default-bg"
                  }`}
                >
                  <div className="flex flex-column gap-1">{headerContent}</div>
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
}
