import { connect } from "react-redux";
import { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { User, Settings } from "../../../../interfaces";
import Spinner from "react-bootstrap/Spinner";
import { Form, Row, Col } from "react-bootstrap";
import Api from "../../../../api/teams";
import styles from "./styles.teams.module.css";
import InvitedList from "../../../../components/InvitedList";
import SkeletonLoader from "../../../../components/SkeletonLoader";

interface TeamsForm {
  email: string;
  role: string;
}

interface InvitedUser {
  id: string;
  email: string;
  role: string;
  accepted: boolean;
  createdAt: string;
}

const Team: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const { language } = props.user;
  const { plan } = props.settings.status;

  const [formData, setFormData] = useState<TeamsForm>({
    email: "",
    role: "user",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [loading, setLoading] = useState(false);
  const [loadingList, setLoadingList] = useState(true);
  const [successDelete, setSuccessDelete] = useState("");
  const [errorDelete, setErrorDelete] = useState("");
  const [invitedUsers, setInvitedUsers] = useState<InvitedUser[]>([]);
  const intl = useIntl();

  const getList = async () => {
    setLoadingList(true);
    try {
      const { data, error } = await Api.getInvite();
      if (data) {
        const { list } = data;
        setInvitedUsers(list);
        setLoadingList(false);
      }
      if (error) {
        throw error;
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  const deleteUser = async (id: string) => {
    try {
      const { data, err } = await Api.removeInvite({ id });
      if (data) {
        setSuccessDelete(
          intl.formatMessage({ id: "invitedList.message.deleteSuccess" })
        );

        getList();
      }
      if (err) {
        setErrorDelete(
          intl.formatMessage({ id: "invitedList.message.deleteError" })
        );
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  useEffect(() => {
    getList();
    props.onRequestSettings();
  }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    setSuccess("");
    try {
      const { data, err } = await Api.createInvite(formData);
      if (data) {
        setSuccess(intl.formatMessage({ id: "teams.message.success" }));
        getList();
      }
      if (err) {
        const { error } = err;
        if (error && error[language]) {
          setError(error[language]);
        } else {
          setError(err.message);
        }
      }
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value, id } = e.currentTarget;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const handleRoleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.currentTarget;
    setFormData({ ...formData, role: value });
  };

  return (
    <div className={`settings-presentation ${styles.teamPresentation}`}>
      <h4>
        <FormattedMessage id="teams.title" />
      </h4>
      <p className="color-step-700 fs-3">
        <FormattedMessage id="teams.subTitle" />
      </p>
      <hr />
      <div className="row">
        <div className="col-md-12">
          <div className="visito-card visito-block">
            <div className="visito-card-header">
              <div className="visito-card-title">
                <FormattedMessage id="teams.inviteMember" />
              </div>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="visito-card-body">
                <Row>
                  <Col>
                    <label className="fs-3 mb-2">
                      <FormattedMessage id="teams.emailLabel" />
                    </label>
                    <input
                      id="email"
                      type="email"
                      placeholder="example@visito.com"
                      value={formData.email}
                      onChange={(e) => handleChange(e)}
                      required
                      className="form-control"
                    />
                  </Col>
                  <Col>
                    <label className="fs-3 mb-2">
                      <FormattedMessage id="teams.selectorLabel" />
                    </label>
                    <Form.Select
                      aria-label="Select"
                      value={formData.role}
                      onChange={(e) => handleRoleChange(e)}
                      className="form-control"
                    >
                      <option value="user">
                        <FormattedMessage id="teams.rol.user" />
                      </option>
                      <option value="admin">
                        <FormattedMessage id="teams.rol.admin" />
                      </option>
                    </Form.Select>
                  </Col>
                </Row>
              </div>

              <div className="visito-card-footer">
                <button type="submit" className="btn btn-sm btn-visito-primary">
                  {loading ? (
                    <Spinner animation="border" role="status" size="sm">
                      <span className="visually-hidden">
                        <FormattedMessage id="common.loading" />
                        ...
                      </span>
                    </Spinner>
                  ) : (
                    <FormattedMessage id="teams.button" />
                  )}
                </button>
                {error && <div className="color-danger mt-2">{error}</div>}
                {success && <div className="color-success mt-2">{success}</div>}
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="visito-card visito-block mt-4">
        <div className="visito-card-header">
          <div style={{ width: "100%" }} className="visito-card-title">
            <p style={{ float: "left" }} className=" fs-3 mt-2 mb-1">
              <FormattedMessage id="invitedList.Members" />
            </p>
            <p style={{ float: "right" }} className="fs-3 mt-2 mb-1">
              <FormattedMessage
                values={{
                  count: plan.userLeft,
                }}
                id="invitedList.Invites"
              />
            </p>
          </div>
        </div>
        <div className="visito-card-body">
          {loadingList ? (
            <SkeletonLoader rows={3} />
          ) : (
            <div>
              {errorDelete && (
                <div className="color-danger mt-2">{errorDelete}</div>
              )}
              {successDelete && (
                <div className="color-success mt-2">{successDelete}</div>
              )}
              <InvitedList list={invitedUsers} onDelete={deleteUser} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

<div className="visito-card-header">
  <div className="visito-card-title">
    <FormattedMessage id="teams.inviteMember" />
  </div>
</div>;

interface ILayoutProps {
  dispatch: Function;
  settings: Settings;
  user: User;
  onRequestSettings: Function;
}

export default connect((props: any) => ({}))(Team);
