import {
  IonContent,
  IonPage,
  isPlatform,
  useIonToast,
  IonSpinner,
} from "@ionic/react";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Helmet } from "react-helmet";

import ChatDetail from "../ChatDetail";
import ChatInput from "../common/chat-input";
import WebChatApi from "../../api/web-chat";
import { useWebChatActions, useWebChatData } from "../../store/web-chat";
import config from "../../config";
import { ChatInputRef, Message } from "../../interfaces";
import { connect } from "react-redux";
import InfoModal from "./modal";
import useContactsStore, { useSelectContactById } from "../../store/contacts";

// socket
import { useSockets } from "../../providers/socket";
import { useSocketStore } from "../../store/socket";

// Styles
import webChatStyles from "./styles.module.css";
import styles from "../ai/styles.module.css";

// Utils

import { useTabActive } from "../../utils/useActiveTab";

const isMobile = isPlatform("android") || isPlatform("ios");
const version = config.VERSION;

function WebChat({ dispatch }: { dispatch: Function }) {
  // Get id from URL
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const id = params.get("id");

  //Active tab
  const isTabActive = useTabActive();
  const isInIframe = window.self !== window.top;

  const { webChatSocket, emitEventWebChat } = useSockets();
  const { setWebChatQuery, webChatSocketReady } = useSocketStore();
  const { updateContactsDb } = useContactsStore();

  const [webChatReady, setWebChatReady] = React.useState(false);
  const [chatOpen, setChatOpen] = React.useState(false);
  const [tabOpen, setTabOpen] = React.useState(true);
  const [scroll, setScroll] = React.useState(0);
  const [content, setContent] = React.useState<string>();
  const [isFocus, setIsFocus] = React.useState(0);
  const chatInputRef = React.useRef<ChatInputRef>(null);
  const [replyTo, setReplyTo] = React.useState<Message | null>(null);

  const intl = useIntl();

  const { createWebId, removeWebId, setLoading, setMessages } =
    useWebChatActions();

  const [present] = useIonToast();

  const existsId = id !== null && id.length > 0;

  const {
    data: companyData,
    isLoading: isGettingCompany,
    isError: isGettingCompanyError,
  } = useQuery({
    queryFn: () => WebChatApi.getCompanyInfo({ id: String(id) }),
    queryKey: ["getCompanyInfo", id],
    enabled: existsId,
    refetchOnWindowFocus: false,
  });

  const { webChat, loading, unreadCount, lastId } = useWebChatData(id);
  const selectedChat = useSelectContactById(String(webChat?.webId));

  const {
    data: sendResponse,
    mutate: mutateSendMessage,
    isPending: isSendingMessage,
  } = useMutation({
    mutationFn: WebChatApi.sendMessage,
    onSuccess: (data) => {
      if (!data.ok) {
        return present({
          message: intl.formatMessage({ id: "common.tryItLater" }),
          duration: 6000,
          color: "light",
          position: "top",
        });
      }
    },
    onError: () => {
      return present({
        message: intl.formatMessage({ id: "common.tryItLater" }),
        duration: 6000,
        color: "light",
        position: "top",
      });
    },
  });

  // Post Read Messages
  const { mutate: mutateReadMessages } = useMutation({
    mutationFn: WebChatApi.readMessages,
  });

  // Post Delivered Messages
  const { mutate: mutateDeliveredMessages } = useMutation({
    mutationFn: WebChatApi.deliveredMessages,
  });

  const onChangeContent = (newContent: string) => setContent(newContent);

  const onSubmit = () => {
    if (content && webChat?.webId && webChat?.companyId) {
      mutateSendMessage({
        webId: webChat.webId,
        content,
        replyTo: replyTo?.id,
        companyId: webChat.companyId,
      });
    }
  };

  const onReply = (message: Message, content: string) => {
    if (content && webChat?.webId && webChat?.companyId) {
      mutateSendMessage({
        webId: webChat.webId,
        content,
        replyTo: message ? message.id : undefined,
        companyId: webChat.companyId,
      });
    }
  };

  const onCreateWebId = (name: string) => {
    if (id) {
      setLoading(true);
      createWebId(id, name, false);
    }
  };

  React.useEffect(() => {
    if (sendResponse && sendResponse?.ok) {
      setContent("");
      setReplyTo(null);
    }
  }, [sendResponse]);

  // If we have a webId, connect to socket
  React.useEffect(() => {
    if (webChat?.webId) {
      const query = { webChatId: webChat.webId, ...version, mobile: isMobile };
      // Connect to socket
      setWebChatQuery(query);
    }
  }, [webChat?.webId]);

  React.useEffect(() => {
    if (!webChatSocket) return;
    console.log("we got socket...");
    const handleNewMessage = (message: any) => {
      setLoading(false);
      if (message.data) {
        setMessages(message.data[0]?.messages);
        updateContactsDb(message.data);
      }
    };

    const handleChatSession = (message: any) => {
      if (!message?.data.success) {
        setWebChatReady(false);
        if (webChat?.webId) {
          removeWebId(webChat?.webId);
        }
      }
      if (message?.data.success) {
        console.log("webchat ready to use....");
        setWebChatReady(true);
      }
    };
    // Attach event listener
    webChatSocket.on("new-message", handleNewMessage);
    webChatSocket.on("chat-session", handleChatSession);

    emitEventWebChat({
      name: "chat-session",
      data: { webChatId: webChat?.webId },
    });

    return () => {
      console.log("cleaning up handler chats...");
      webChatSocket.off("new-message", handleNewMessage);
      webChatSocket.off("chat-session", handleChatSession);
    };
  }, [webChatSocket]);

  React.useEffect(() => {
    if (!isInIframe) {
      setTabOpen(isTabActive);
    }
  }, [isTabActive]);

  React.useEffect(() => {
    if (!isInIframe) return;
    window.parent.postMessage(
      {
        event: "chat:unreadCount",
        count: unreadCount,
      },
      "*"
    );
  }, [unreadCount]); // Whenever unread count changes

  React.useEffect(() => {
    const handleParentEvent = (e: MessageEvent) => {
      const data = e.data;
      switch (e.data?.event) {
        case "chat:opened":
          setChatOpen(true);
          // increase scroll number
          setScroll(scroll + 1);

          break;
        case "chat:closed":
          setChatOpen(false);
          break;
        case "tab:visibility":
          setTabOpen(data.isActive);
          break;
        default:
          break;
      }
    };
    window.addEventListener("message", handleParentEvent);
    return () => window.removeEventListener("message", handleParentEvent);
  }, []);

  React.useEffect(() => {
    if (webChat?.webId && webChat?.companyId && lastId) {
      isInIframe && chatOpen && tabOpen
        ? mutateReadMessages({
            webId: webChat?.webId,
            companyId: webChat?.companyId,
            lastId,
          })
        : !isInIframe && tabOpen
        ? mutateReadMessages({
            webId: webChat?.webId,
            companyId: webChat?.companyId,
            lastId,
          })
        : mutateDeliveredMessages({
            webId: webChat?.webId,
            companyId: webChat?.companyId,
            lastId,
          });
    }
  }, [chatOpen, tabOpen, lastId]);

  const inputFocused = () => setTimeout(() => setIsFocus(isFocus + 1), 650);

  const companyName = companyData?.company?.name || "";
  const isLoading = isGettingCompany || !webChatReady || !webChatSocketReady;
  const isError = isGettingCompanyError || !existsId;

  const title = `Visito | ${companyName} web chat`;
  const description = `Chat with ${companyName} using Visito's AI virtual assistant. Ask questions, get answers, and more.`;
  const image =
    "https://cdn.prod.website-files.com/659ea70342c43623b694723a/65cf09976f795389644fd344_visito%20thumbnailV3.png";

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta
          property="twitter:title"
          content={`Visito | ${companyName || ""} Playground`}
        />
        <meta property="twitter:description" content={description} />
        <meta property="twitter:image" content={image} />
      </Helmet>
      <IonPage id="main-content">
        <IonContent
          {...(isMobile
            ? { fullscreen: true, color: "light", className: "ion-padding" }
            : {})}
        >
          <div className={isMobile ? "view-wrapper h-100" : "page-wrapper"}>
            {isLoading && !isError ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                }}
              >
                <IonSpinner name="lines"></IonSpinner>
              </div>
            ) : isError ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                }}
              >
                <div className="fs-4">
                  {" "}
                  There was an error. If you are the owner of the website,
                  contact support.
                </div>
              </div>
            ) : (
              <div className={webChatStyles.webChatWrapper}>
                <div className={webChatStyles.webChatDetailHeader}>
                  <div>
                    <h3 className="fs-4 mb-0">
                      <FormattedMessage
                        id="aiCompanyTitle"
                        values={{ company: companyName }}
                      />
                    </h3>
                  </div>
                </div>
                <div className={styles.chatDetailContainer}>
                  <div className={`h-100 w-100`}>
                    <ChatDetail
                      messages={selectedChat.messages}
                      openImage={(link) => console.log("Open image", link)}
                      contactId={selectedChat.contact.id}
                      mode={isMobile ? "ios" : "desktop"}
                      scrollEscalations={1}
                      scroll={scroll}
                      type="web-chat"
                      replyTo={({ message }: { message: Message }) => {
                        setReplyTo(message);
                        inputFocused();
                      }}
                      actionClick={({
                        message,
                        text,
                      }: {
                        message: Message;
                        text: string;
                      }) => onReply(message, text)}
                    />
                  </div>
                </div>
                <div className={`${styles.chatInputContainer}`}>
                  <ChatInput
                    id="send-body-playground"
                    ref={chatInputRef}
                    value={content}
                    placeholder={intl.formatMessage({
                      id: "chats.newMessageInput",
                    })}
                    enableWaActions={false}
                    onInputChange={onChangeContent}
                    onBeforeSubmit={onSubmit}
                    onAfterFocus={() => (isMobile ? inputFocused() : undefined)}
                    replyTo={replyTo}
                    cleanReplyTo={() => setReplyTo(null)}
                    //disabled={isSendingMessage}
                  />
                </div>
                <div className={webChatStyles.webChatDetailFooter}>
                  <p className="mb-0">
                    <FormattedMessage id="aiPoweredBy" />{" "}
                    <a
                      className="mb-0"
                      href="https://visitoai.com"
                      target="_blank"
                    >
                      Visito AI
                    </a>
                  </p>
                </div>
              </div>
            )}
          </div>
        </IonContent>

        {!isGettingCompany && !webChat?.webId && !isError && existsId && (
          <InfoModal
            companyName={companyName}
            loading={loading}
            onSubmit={onCreateWebId}
          />
        )}
      </IonPage>
    </>
  );
}

export default connect((props: any) => ({
  requests: props.requests,
  settings: props.app.settings,
}))(WebChat);
