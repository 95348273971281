import React, {
  useImperativeHandle,
  useRef,
  useState,
  forwardRef,
} from "react";
import { isPlatform } from "@ionic/react";
import {
  IconPhoto,
  IconArrowUp,
  IconBrandOpenai,
  IconFilePlus,
  IconPlus,
  IconX,
} from "@tabler/icons-react";
import { useIntl } from "react-intl";
import styles from "./styles.module.css";
import config from "../../../config";
import { ChatInputRef, Message } from "../../../interfaces";
import ReplyContent from "../reply-content";

const isDev = config.ENV === "staging" || config.ENV === "local";
const isMobile = isPlatform("android") || isPlatform("ios");
const iconsSize = isMobile ? 23 : 20;

type CustomProps = {
  onBeforeSubmit: () => void;
  onOpenPhotoInput?: () => void;
  onOpenDocInput?: () => void;
  onRunConversational?: () => void;
  onInputChange?: (body: string) => void;
  onAfterFocus?: () => void;
  enableWaActions?: boolean;
  className?: string;
  id?: string;
  value?: string;
  placeholder?: string;
  disabled?: boolean;
  replyTo?: Message | null;
  cleanReplyTo: () => void;
};

type Action = {
  key: string;
  name: string;
  onlyDev?: boolean;
  icon: JSX.Element;
};

type ActionPress = {
  e?: React.MouseEvent<HTMLButtonElement>;
  key: string;
};

// Using forwardRef to allow parent to pass a ref
const ChatInput = forwardRef<ChatInputRef, CustomProps>(
  (
    {
      disabled,
      enableWaActions = false,
      onBeforeSubmit,
      onOpenPhotoInput,
      onOpenDocInput,
      onRunConversational,
      onInputChange,
      onAfterFocus,
      replyTo,
      cleanReplyTo,
      ...props
    },
    ref
  ) => {
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const actionMenuRef = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState(false);
    const intl = useIntl();

    useImperativeHandle(ref, () => ({
      setInputFocus: () => {
        if (textAreaRef.current) {
          textAreaRef.current.focus();
        }
      },
    }));

    React.useEffect(() => {
      if (textAreaRef.current) {
        textAreaRef.current.focus();
      }
    }, [textAreaRef]);

    React.useEffect(() => {
      if (isOpen) {
        document.addEventListener("mousedown", handleClickOutside);
      } else {
        document.removeEventListener("mousedown", handleClickOutside);
      }

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [isOpen]);

    React.useEffect(() => {
      resizeTextArea();
    }, [props.value]);

    const onKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === "Enter" && !e.shiftKey && !isMobile) {
        e.preventDefault();
        if (
          props.value &&
          typeof props.value === "string" &&
          props.value.length > 0
        ) {
          onBeforeSubmit();
        }
      }
    };

    const handleClickOutside = (event: MouseEvent) => {
      if (
        actionMenuRef.current &&
        !actionMenuRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      return onBeforeSubmit();
    };

    const onChangeTextArea = (
      event: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
      return onInputChange?.(event.target.value || "");
    };

    const handleMobileSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      onBeforeSubmit(); // Call the submit handler
      if (textAreaRef.current) {
        textAreaRef.current.focus(); // Refocus the textarea
      }
    };

    const resizeTextArea = () => {
      if (textAreaRef.current) {
        textAreaRef.current.style.height = "0px";
        textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
      }
    };

    const actionPress = ({ e, key }: ActionPress) => {
      e?.preventDefault();
      setIsOpen(false);
      switch (key) {
        case "doc":
          return onOpenDocInput?.();
        case "pic":
          return onOpenPhotoInput?.();
        case "ai":
          return onRunConversational?.();
      }
    };

    const actions: Action[] = [
      {
        key: "doc",
        name: intl.formatMessage({ id: "common.file" }),
        icon: <IconFilePlus size={iconsSize} />,
      },
      {
        key: "pic",
        name: intl.formatMessage({ id: "common.photo" }),
        icon: <IconPhoto size={iconsSize} />,
      },
      {
        key: "ai",
        name: intl.formatMessage({ id: "common.runAi" }),
        onlyDev: true,
        icon: <IconBrandOpenai size={iconsSize} />,
      },
    ];

    return (
      <form onSubmit={isMobile ? undefined : onSubmit}>
        {replyTo && (
          <div className={styles.replyToContainer}>
            <div className={styles.replyToMessage}>
              <ReplyContent
                message={replyTo}
                right={false}
                onAfterClick={(id: string) => console.log(id)}
              />
            </div>
            <div className={styles.replyToAction}>
              <IconX
                size={iconsSize}
                className={styles.icon}
                onClick={() => cleanReplyTo()}
              />
            </div>
          </div>
        )}
        {isOpen && !isMobile && (
          <div className={styles.fabContainer} ref={actionMenuRef}>
            <div className={styles.fabMenu}>
              {actions
                .filter((a) => (isDev ? true : !a.onlyDev))
                .map(({ key, name, icon }) => (
                  <button
                    className={`${styles.actionBtn} ${styles.fabBtn} btn btn-visito-light nb btn-sm icon`}
                    onClick={(e) => actionPress({ e, key })}
                    key={key}
                  >
                    {icon} {name}
                  </button>
                ))}
            </div>
          </div>
        )}
        <div className={styles.container}>
          {isOpen && isMobile && (
            <div className={styles.actions} ref={actionMenuRef}>
              {actions
                .filter((a) => (isDev ? true : !a.onlyDev))
                .map(({ key, name, icon }) => (
                  <button
                    className={`${styles.actionBtn} btn round-btn icon btn-visito-light`}
                    onClick={(e) => actionPress({ e, key })}
                    key={key}
                  >
                    {icon}
                  </button>
                ))}
            </div>
          )}
          {enableWaActions &&
            (!isMobile || !isOpen ? (
              <div className={styles.actions}>
                <button
                  className={`${styles.actionBtn} btn round-btn icon btn-visito-light`}
                  type="button"
                  onClick={() => setTimeout(() => setIsOpen(!isOpen), 100)}
                >
                  {isOpen ? (
                    <IconX size={iconsSize} />
                  ) : (
                    <IconPlus size={iconsSize} />
                  )}
                </button>
              </div>
            ) : null)}
          <textarea
            ref={textAreaRef}
            {...props}
            onChange={onChangeTextArea}
            className={`${styles.textarea} ${props.className || ""}`}
            onKeyDown={onKeyPress}
            rows={1}
            required
            disabled={disabled}
            onFocus={() => onAfterFocus?.()}
          />
          <div className={styles.actions}>
            <button
              className={`${styles.actionBtn} btn round-btn icon btn-visito-primary`}
              type={isMobile ? "button" : "submit"}
              onClick={(e) => (isMobile ? handleMobileSubmit(e) : undefined)}
              disabled={disabled}
            >
              <IconArrowUp size={iconsSize} className={styles.icon} />
            </button>
          </div>
        </div>
      </form>
    );
  }
);

ChatInput.displayName = "ChatInput";

export default ChatInput;
