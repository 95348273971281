import { IconTrash, IconCircleCheckFilled, IconRefresh } from "@tabler/icons-react";
import { IonSpinner, IonToggle } from "@ionic/react";

import { UrlWithStatus } from "../../../store/crawling";

import styles from "./styles.module.css";

const UrlSkeleton = ({ url }: { url: string }) => (
  <div className="flex align-items-center justify-content-between gap-2">
    <a href={url} target="_blank" rel="noreferrer" className="fs-3 break-all">
      {url}
    </a>

    <div className="flex align-items-center gap-3">
      <IonSpinner name="circular" style={{ color: "var(--visito-color-1)", width: "21px", height: "21px" }} />
    </div>
  </div>
)

export const WhileCrawling = ({ url }: { url: string }) => (
  <div className={styles.siteMapContainer}>
    <div className={`${styles.linksContainer} flex flex-column gap-3`}>
      <UrlSkeleton url={url} />
    </div>
  </div>
)

type UrlProps = {
  url: UrlWithStatus;
  isCrawling?: boolean
  onDelete: (id: string) => void;
  onEnableUrl: (data: { embeddingId: string; active: boolean }) => void;
  onRescanUrl: (data: { url: string; source?: string }) => void;
};

const Url = ({ url, isCrawling, onDelete, onEnableUrl, onRescanUrl }: UrlProps) => {
  const { url: urlLink, completed, active, _id, embeddingId, source } = url;

  const id = _id || embeddingId || "";

  return (
    <div className="flex align-items-center justify-content-between gap-2">
      <a href={urlLink} target="_blank" rel="noreferrer" className="fs-3 break-all no-underline">
        {urlLink}
      </a>

      <div className="flex align-items-center gap-4">
        {completed ? (
          isCrawling ? (
            <IconCircleCheckFilled style={{ color: "var(--visito-color-active)" }} size={22} />
          ) : (
            <>
              <div className="flex gap-2">
                <IconRefresh
                  size={21}
                  style={{ color: "var(--ion-color-step-600)" }}
                  role="button"
                  onClick={() => onRescanUrl({ url: urlLink, source })}
                />
                <IconTrash
                  size={21}
                  style={{ color: "var(--visito-color-danger)" }}
                  role="button"
                  onClick={() => onDelete(id)}
                />
              </div>
              <IonToggle
                checked={active || false}
                onIonChange={(ev) => onEnableUrl({ embeddingId: id, active: ev.detail.checked  })}
                mode="ios"
                className="ios visito-toggle"
              />
            </>
          )
        ) : (
          <IonSpinner name="circular" style={{ color: "var(--visito-color-1)", width: "21px", height: "21px" }} />
        )}
      </div>
    </div>
  );
};

export default Url;
