import config from "../config";
import request, { requestOptions, requestOptionsFormData } from "../utils/request";

const baseUrl = `${config.API_ROOT}/ai`;

const Ai = {
  getAiSettings() {
    const options = requestOptions("GET", {}, true);
    const url = `${baseUrl}/settings`;
    return request(url, options);
  },
  getAiSettingsSystem() {
    const options = requestOptions("GET", {}, true);
    const url = `${baseUrl}/settings-system`;
    return request(url, options);
  },
  putAiSettingsByField(field, value, location) {
    const options = requestOptions("PUT", { value, location }, true);
    const url = `${baseUrl}/settings/field/${field}`;
    return request(url, options);
  },
  putAiSettingsByFieldV2({ field, value, embeddingId }) {
    const options = requestOptions("PUT", { value, embeddingId }, true);
    const url = `${baseUrl}/settings/field/${field}`;
    return request(url, options);
  },
  async getFreeText() {
    const options = requestOptions("GET", {}, true);
    const url = `${baseUrl}/free-text`;
    const { data, err } = await request(url, options);
    if (err) {
      throw err
    }

    return data;
  },
  async createUpdateFreeText({ embeddingId, embededContent, updateTrain }) {
    const method = embeddingId ? "PATCH" : "POST";
    const options = requestOptions(method, { embededContent, updateTrain }, true);
    const url = embeddingId ? `${baseUrl}/free-text/${embeddingId}` : `${baseUrl}/free-text`;
    const { data, err } = await request(url, options);
    if (err) {
      throw err
    }

    return data;
  },
  async getCustomerContext() {
    const options = requestOptions("GET", {}, true);
    const url = `${baseUrl}/customer-context`;
    const { data, err } = await request(url, options);
    if (err) {
      throw err
    }

    return data;
  },
  async createUpdateCustomerContext({ embeddingId, embededContent }) {
    const method = embeddingId ? "PATCH" : "POST";
    const options = requestOptions(method, { embededContent }, true);
    const url = embeddingId ? `${baseUrl}/customer-context/${embeddingId}` : `${baseUrl}/customer-context`;
    const { data, err } = await request(url, options);
    if (err) {
      throw err
    }

    return data;
  },
  async getFiles() {
    const options = requestOptions("GET", {}, true);
    const url = `${baseUrl}/files`;
    const { data, err } = await request(url, options);
    if (err) {
      throw err
    }

    return data;
  },
  async processFile(formData) {
    const options = requestOptionsFormData(formData, true);
    const url = `${baseUrl}/files`;
    const { data, err } = await request(url, options);
    if (err) {
      throw err
    }

    return data;
  },
  async patchFile({ embeddingId, active }) {
    const options = requestOptions("PATCH", { active }, true);
    const url = `${baseUrl}/files/${embeddingId}`;
    const { data, err } = await request(url, options);
    if (err) {
      throw err
    }

    return data;
  },
  async deleteFile({ embeddingId, fileName }) {
    const options = requestOptions("DELETE", { fileName }, true);
    const url = `${baseUrl}/files/${embeddingId}`;
    const { data, err } = await request(url, options);
    if (err) {
      throw err
    }

    return data;
  },
  async getWebsite() {
    const options = requestOptions("GET", {}, true);
    const url = `${baseUrl}/website`;
    const { data, err } = await request(url, options);
    if (err) {
      throw err
    }

    return data;
  },
  async patchUrl({ embeddingId, active }) {
    const options = requestOptions("PATCH", { active }, true);
    const url = `${baseUrl}/website/${embeddingId}`;
    const { data, err } = await request(url, options);
    if (err) {
      throw err
    }

    return data;
  },
  async deleteUrl({ embeddingId }) {
    const options = requestOptions("DELETE", {}, true);
    const url = `${baseUrl}/website/${embeddingId}`;
    const { data, err } = await request(url, options);
    if (err) {
      throw err
    }

    return data;
  },
  async singleCrawling({ url, companyId, source = undefined  }) {
    const options = requestOptions("POST", { url, companyId, source }, true);
    const path = `${baseUrl}/website`;
    const { data, err } = await request(path, options);
    if (err) {
      throw err
    }

    return data;
  },
  async resetCrawling({ url, companyId }) {
    const options = requestOptions("PUT", { url, companyId }, true);
    const path = `${baseUrl}/website`;
    const { data, err } = await request(path, options);
    if (err) {
      throw err
    }

    return data;
  },
  async getAiStatus() {
    const options = requestOptions("GET", {}, true);
    const url = `${baseUrl}/status`;
    const { data, err } = await request(url, options);
    if (err) {
      throw err
    }

    return data;
  },
  async updateTrainingStatus() {
    const options = requestOptions("PATCH", {}, true);
    const url = `${baseUrl}/status`;
    const { data, err } = await request(url, options);
    if (err) {
      throw err
    }

    return data;
  },
  async getProviders() {
    const options = requestOptions("GET", {}, true);
    const url = `${baseUrl}/providers`;
    const { data, err } = await request(url, options);
    if (err) {
      throw err
    }

    return data;
  }
};

export default Ai;
