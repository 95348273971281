import { useQuery } from "@tanstack/react-query";
import { IconCircleCheckFilled, IconInfoCircle } from "@tabler/icons-react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import AiApi from "../../../api/ai";
import SkeletonText from "../../SkeletonText";

import integrationStyles from "../../integrations/styles.module.css";
import badgesStyles from "../../../theme/badges.module.css";

type GenericProvider = {
  id: string;
  type: string;
}

export default function Providers() {
  const {
    data: aiData,
    isLoading: isGettingAi,
    isRefetching: isRefetchingAi,
  } = useQuery({
    queryFn: () => AiApi.getProviders(),
    queryKey: ["getAiProviders"],
    refetchOnWindowFocus: false,
    staleTime: 300000,
  });

  const isRequesting = isGettingAi || isRefetchingAi;
  const providers = aiData?.providers || null;

  return (
    isRequesting ? (
      <SkeletonText rows={10} />
    ) : (
      <div className="w-100 flex flex-column gap-4">
        <div className="row">
          {providers && providers.map((provider: GenericProvider) => (
            <div className="col-md-4" key={provider.id}>
              <div className="visito-card">
                <div className="visito-card-body">
                  <div className={integrationStyles.titleDisplay}>
                    <div className={`${integrationStyles.titleItem} ${integrationStyles.logo}`}>
                      <div className={`${integrationStyles.logo} ${provider.type}-logo`} />
                    </div>
                    <h3 className="fs-3">
                      <FormattedMessage id={`integrations.${provider.type}Name`} />
                    </h3>
                    <div style={{ marginLeft: "auto" }}>
                      <div className={badgesStyles.statusBadge}>
                        <IconCircleCheckFilled
                          className={badgesStyles.activeIcon}
                          size={16}
                        />{" "}
                        <FormattedMessage id="common.connected" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        {providers && providers.length > 0 ? (
          <p className="mb-0 fs-2 description flex gap-1">
            <IconInfoCircle size={15} className="mt-1" />
            <FormattedMessage id="ai.sources.providers.manage"  />
          </p>
        ) : (
          <div className="flex flex-column justify-content-center align-items-center text-center gap-3 mt-4">
            <h3 className="mb-0 fs-4 fw-normal w-100">
              <FormattedMessage id="ai.sources.providers.empty" />
            </h3>
            <Link to="/integrations" className="btn btn-visito-primary w-max">
              <FormattedMessage id="ai.sources.providers.setUp" />
            </Link>
          </div>
        )}
      </div>
    )
  )
}
