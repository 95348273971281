import { useMemo } from "react";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import config from "../config";
import {
  apiResult,
  ContactsDBItem,
  Contact,
  ContactsDBObject,
  Filter,
} from "../interfaces";
import {
  adaptItem,
  howMany,
  runChatList,
  contactAdapter,
} from "../utils/contacts";

const isDev = config.ENV === "staging" || config.ENV === "local";

interface ContactStoreState {
  contactsDb: ContactsDBItem[];
  contactsDbObject: ContactsDBObject;
  contactList: Contact[];
  searchResults: Contact[] | false;
  isSearching: boolean; // fetching results from backend;
  searchActive: boolean; // search state is active
  currentContact: string;
  loading: boolean;
  escalatedCount: number;
  filters: Filter;

  setLoading: (loading: boolean) => void;
  updateContactsDb: (contacts: apiResult[]) => void;
  setSearchResults: (contacts: apiResult[]) => void;
  resetSearchResults: () => void;
  setSearchActive: (searchActive: boolean) => void;
  setIsSearching: (isSearching: boolean) => void;
  setContact: (id: string) => void;
  setFilters: (filters: Filter) => void;
  insertSentMessage: () => void;
  modifyEnabled: () => void;
  changeContactStatus: () => void;
  readMessages: () => void;
}

const useContactsStore = create(
  devtools<ContactStoreState>((set, get) => ({
    contactsDb: [],
    contactsDbObject: {},
    contactList: [],
    searchResults: false,
    currentContact: "",
    loading: true,
    escalatedCount: 0,
    isSearching: false,
    searchActive: false,
    filters: {
      simple: [],
      companies: [],
    },

    setLoading: (loading) => set({ loading }),

    updateContactsDb: (contacts: apiResult[]) => {
      const currentContactsDbObject = get().contactsDbObject;
      const updatedContactsDbObject = { ...currentContactsDbObject };

      contacts.forEach((c) => {
        const properties = howMany(c.messages, ["user", "assistant"]);
        const contact = adaptItem(c, properties);
        // @ts-ignore
        updatedContactsDbObject[contact.contact.id] = contact;
      });

      const { list, escalated } = runChatList(updatedContactsDbObject);
      const filteredList = isDev
        ? list
        : list.filter((contact) => contact.type !== "play");

      set((state) => ({
        ...state,
        contactsDbObject: updatedContactsDbObject,
        contactList: filteredList,
        escalatedCount: escalated,
      }));
    },

    setContact: (id: string) => set({ currentContact: id }),

    setFilters: (filters: Filter) => set({ filters }),
    setSearchResults: (contacts: apiResult[]) => {
      set((state) => ({
        ...state,
        searchResults: contacts
          .map((c) => contactAdapter(c, {}))
          .sort((a, b) => b.timestampNative - a.timestampNative),
      }));
    },
    resetSearchResults: () => set({ searchResults: false }),
    setIsSearching: (isSearching: boolean) => set({ isSearching }),
    setSearchActive: (searchActive: boolean) => set({ searchActive }),
    insertSentMessage: () => {},
    modifyEnabled: () => {},
    changeContactStatus: () => {},
    readMessages: () => {},
  }))
);

export const useSelectContactById = (id: string) => {
  const contact = useContactsStore((state) => state.contactsDbObject[id]);

  return useMemo(() => {
    if (contact) {
      return contact;
    }

    return {
      contact: {
        type: "list",
        id: "",
        name: "",
        preview: "",
        unread: 0,
        timestamp: "",
        enabled: false,
        optedOut: false,
        manual: false,
        blocked: false,
        phoneNumber: "",
        status: "",
        escalated: false,
        isBook: false,
        isManual: false,
        isOnline: false,
        timestampNative: new Date().getTime(),
        count: 0,
        followers: 0,
        username: "",
        companies: [],
      },
      messages: [],
      bookings: [],
      escalations: [],
    };
  }, [contact]);
};

export default useContactsStore;
