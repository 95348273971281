import NavDropdown from "react-bootstrap/NavDropdown";
import { Company } from "../../../interfaces";
import { IconCheck } from "@tabler/icons-react";
type CompaniesDropdownProps = {
  currentCompany: Company;
  variant?: "default" | "light";
  companies: { id: string; name: string }[];
  onSelectCompany: (id: string) => void;
};
import styles from "./styles.module.css";
import classNames from "classnames";

// bring selected company to first position
export default function AccountDropDown({
  currentCompany,
  companies,
  onSelectCompany,
}: CompaniesDropdownProps) {
  const sortedCompanies = [
    currentCompany, // Place the current company at the top
    ...companies
      .filter((c) => c.id !== currentCompany.id) // Remove the current company from the list
      .sort((a, b) => a.name.localeCompare(b.name)), // Sort the remaining companies alphabetically
  ];

  return (
    <div
      className={classNames("list-of-companies", {
        scrolled: companies.length > 3,
      })}
    >
      {sortedCompanies.map((c) => (
        <NavDropdown.Item
          onClick={() => onSelectCompany(c.id)}
          key={c.id}
          className={`company-drop-item ${
            currentCompany.id === c.id && companies.length > 1 ? "selected" : ""
          }`}
        >
          <div className="company-name">{c.name}</div>

          {currentCompany.id === c.id && companies.length > 1 && (
            <IconCheck size={16} className={styles.checkIcon} />
          )}
        </NavDropdown.Item>
      ))}
    </div>
  );
}
