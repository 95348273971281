import { IonList, IonItem, IonLabel } from "@ionic/react";
import { FormattedMessage } from "react-intl";
import { IconCircleCheckFilled, IconCircleFilled } from "@tabler/icons-react";

import { Link } from "react-router-dom";
import styles from "../styles.module.css";

import SkeletonText from "../../SkeletonText";

import { Settings } from "../../../interfaces";

const generateLink = ({ name }: ListItemProps) => {
  try {
    if (["ai"].includes(name)) {
      return "/ai";
    }
    return "/connect";
  } catch (e) {
    return null;
  }
};

const generateCTA = ({ name }: ListItemProps) => {
  try {
    if (["ai"].includes(name)) {
      return "common.configure";
    }
    return "common.connect";
  } catch (e) {
    return "common.connect";
  }
};

const ListItem: React.FC<ListItemProps> = ({ name, done, isMobile }) => {
  const link = generateLink({ name, done });
  const cta = generateCTA({ name, done });
  return isMobile ? (
    <IonItem
      routerLink={link && !done ? link : undefined}
      detail={link && !done ? true : undefined}
    >
      <div slot="start">
        {done ? (
          <IconCircleCheckFilled size={18} className="color-active" />
        ) : (
          <IconCircleFilled size={18} />
        )}
      </div>
      <IonLabel>
        {" "}
        <div className="fw-semibold fs-4">
          <FormattedMessage id={`home.title_${name}`} />
        </div>
        <div className="color-step-600 fs-3">
          <FormattedMessage id={`home.description_${name}`} />
        </div>
      </IonLabel>
    </IonItem>
  ) : (
    <div className={styles.stepListItem}>
      <div className={styles.stepListItemIcon}>
        {done ? (
          <IconCircleCheckFilled size={18} className="color-active" />
        ) : (
          <IconCircleFilled size={18} />
        )}
      </div>
      <div className={styles.stepListItemDescription}>
        <div className="fw-semibold fs-4">
          <FormattedMessage id={`home.title_${name}`} />
        </div>
        <div className="color-step-600 fs-3">
          <FormattedMessage id={`home.description_${name}`} />
        </div>
      </div>
      <div className={styles.stepListItemCta}>
        {done ? null : (
          <Link
            to={link ? link : "/"}
            className="btn btn-visito-outline btn-sm"
          >
            <FormattedMessage id={cta} />
          </Link>
        )}
      </div>
    </div>
  );
};

const Onboarding = ({ settings, isMobile, onSkip }: ILayoutProps) => {
  return isMobile ? (
    settings.loading ? (
      <div className="view-pane">
        <SkeletonText rows={6} />
      </div>
    ) : settings.badge ? (
      <>
        <div className="view-pane">
          <div className="fw-semibold mb-3">
            <span className="mr-1">
              <FormattedMessage id="home.onboardingTitle" />
            </span>
            <span className="badge text-bg-warning fw-semibold-1">
              {settings.badge} <FormattedMessage id="home.pendingItems" />
            </span>
            {settings.canSkip ? (
              <span
                className={`${styles.skip} color-visito-1 fw-semibold-1 fs-3 pointer`}
                onClick={() => onSkip()}
              >
                <FormattedMessage id="home.skip" />
              </span>
            ) : null}
          </div>
        </div>
        <div>
          <IonList inset={true}>
            {settings.onboardingList.map(({ name, done }) => (
              <ListItem key={name} name={name} done={done} isMobile={true} />
            ))}
          </IonList>
        </div>
      </>
    ) : null
  ) : settings.loading ? (
    <div className="row">
      <div className="col-md-8">
        <SkeletonText rows={12} />
      </div>
    </div>
  ) : settings.badge ? (
    <div className="row">
      <div className="col-md-9">
        <div className="visito-card visito-block">
          <div className="visito-card-body">
            <div>
              <span className="fw-semibold mr-1">
                <FormattedMessage id="home.onboardingTitle" />
              </span>
              <span className="color-step-600 mr-1">
                <FormattedMessage id="home.onboardingDescription" />
              </span>{" "}
              <span className="badge text-bg-warning fw-semibold-1">
                {settings.badge} <FormattedMessage id="home.pendingItems" />
              </span>
              {settings.canSkip ? (
                <span
                  className={`${styles.skip} color-visito-1 fw-semibold-1 fs-3 pointer`}
                  onClick={() => onSkip()}
                >
                  <FormattedMessage id="home.skip" />
                </span>
              ) : null}
            </div>
            <hr />
            <div className="styles.stepList">
              {settings.onboardingList.map(({ name, done }) => (
                <ListItem key={name} name={name} done={done} isMobile={false} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

interface ILayoutProps {
  settings: Settings;
  isMobile: boolean;
  onSkip: Function;
}

interface ListItemProps {
  name: string;
  done: boolean;
  isMobile?: boolean;
}

export default Onboarding;
