import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

import WebChatApi from "../api/web-chat";

import { Message } from "../interfaces";

const generateRandomToken = () => {
  const array = new Uint8Array(16);
  window.crypto.getRandomValues(array);
  return Array.from(array, (byte) => byte.toString(16).padStart(2, "0")).join(
    ""
  );
};

export type WebChat = {
  webId: string | null | undefined;
  companyId: string | null | undefined;
  name?: string;
};

export type WebChats = {
  loading: boolean;
  unreadCount: number;
  lastId: string;
  webChats?: WebChat[];
};

interface WebChatState extends WebChats {
  setWebChats: (webChats: WebChats) => void;
  setLoading: (loading: boolean) => void;
  createWebId: (companyId: string, name?: string, isAuth?: boolean) => void;
  removeWebId: (webId: string) => void;
  setMessages: (messages: Message[]) => void;
  reset: () => void;
}

const webChatInitialState: WebChats = {
  loading: false,
  webChats: undefined,
  unreadCount: 0,
  lastId: "",
};

const useWebChatStore = create(
  persist(
    devtools<WebChatState>(
      (set, get) => ({
        ...webChatInitialState,
        setMessages: (messages: any[]) => {
          set({
            lastId: messages[messages.length - 1]?._id,
            unreadCount: messages.reduce((count, message) => {
              return message.status !== "read" && message.role === "assistant"
                ? count + 1
                : count;
            }, 0),
          });
        },
        setWebChats: (webChats) => {
          return set(webChats);
        },
        setLoading: (loading) => {
          return set({ loading });
        },
        removeWebId: async (webId) => {
          const state = get() || {};
          const { webChats } = state;
          if (!webChats) return;
          const newWebChats = webChats.filter((play) => play.webId !== webId);
          return set({ webChats: newWebChats });
        },
        createWebId: async (companyId, name = "WebChat User") => {
          const state = get() || {};
          const companyWebChat = state?.webChats?.find(
            (play) => play.companyId === companyId
          );
          if (companyWebChat && companyWebChat.webId) {
            return set({ loading: false });
          }

          const webId = generateRandomToken();
          const { data } = await WebChatApi.createContact({
            webId,
            companyId,
            name,
          });
          if (data?.webChat.webId) {
            const newWebChats = [
              ...(state.webChats || []),
              { webId: data?.webChat.webId, companyId, name },
            ];
            return set({ webChats: newWebChats, loading: false });
          }
          return set({ loading: false });
        },
        reset: () => set({ ...webChatInitialState }),
      }),
      { name: "useWebChatStore" }
    ),
    { name: "webChats" }
  )
);

export const useWebChatData = (companyId: string | null) =>
  useWebChatStore((state) => ({
    webChats: state.webChats,
    webChat: state?.webChats?.find((play) => play.companyId === companyId),
    loading: state.loading,
    unreadCount: state.unreadCount,
    lastId: state.lastId,
  }));

export const useWebChatActions = () =>
  useWebChatStore((state) => ({
    setWebChats: state.setWebChats,
    setLoading: state.setLoading,
    createWebId: state.createWebId,
    removeWebId: state.removeWebId,
    setMessages: state.setMessages,
    reset: state.reset,
  }));

export default useWebChatStore;
