import React from "react";
import "./PlanUsage.css";
import { Link } from "react-router-dom";
import { FormattedMessage, IntlProvider, useIntl } from "react-intl";
import { IonItem, IonLabel, IonList } from "@ionic/react";
import { Plan } from "../interfaces";

const PlanUsage: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const { totalMessages, messageLimit, endDate, messagesUsed, messagesLeft } =
    props.plan;
  const { isMobile, path } = props;
  const displayCount = messagesLeft > 0 ? messagesLeft : 0;
  const intl = useIntl();

  const progressBar = (
    <div className="progress progress-xxs visito-progress-bar">
      <div
        className="progress-bar"
        role="progressbar"
        aria-valuenow={1}
        aria-valuemin={0}
        aria-valuemax={100}
        style={{ width: messagesUsed + "%" }}
      ></div>
    </div>
  );

  return isMobile ? (
    <>
      <IonItem>
        <IonLabel>Mensajes disponibles</IonLabel>
        <div slot="end">
          <strong>
            {Intl.NumberFormat("en-US").format(totalMessages)} /{" "}
            {Intl.NumberFormat("en-US").format(messageLimit)}
          </strong>
        </div>
      </IonItem>
      <IonItem>
        <div style={{ width: "100%" }}>{progressBar}</div>
      </IonItem>
      <IonItem>
        <IonLabel>Fecha de reinicio</IonLabel>
        <div slot="end">
          <strong>{endDate}</strong>
        </div>
      </IonItem>
    </>
  ) : (
    <div id="plan-usage-chart">
      <h4 className="fs-4 fw-semibold mb-3">
        <FormattedMessage id="home.messageLimit" />
      </h4>
      {progressBar}
      <div className="progress-info">
        <div className="fs-4">
          {" "}
          <IntlProvider locale="en" messages={intl.messages}>
            <FormattedMessage
              values={{
                count: displayCount,
                messageLimit: messageLimit,
              }}
              id="home.descriptionLimit"
            />
          </IntlProvider>
        </div>
      </div>
      <div className="fs-3 color-step-600">
        <FormattedMessage
          values={{
            date: endDate,
          }}
          id="home.dateLimit"
        />
      </div>
      {path != "/settings/payment" ? (
        <Link
          to="settings/payment"
          className="btn btn-visito-outline btn-sm mt-2"
        >
          <FormattedMessage id="home.increaseLimit"></FormattedMessage>
        </Link>
      ) : null}
    </div>
  );
};

interface ILayoutProps {
  plan: Plan;
  path?: string;
  isMobile?: boolean;
}

export default PlanUsage;
