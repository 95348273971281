import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useIonToast } from "@ionic/react";
import { useIntl } from "react-intl";
import Nav from "react-bootstrap/Nav";
import config from "../../config";
import { Integration } from "../../interfaces";

interface Properties {
  webchat: Integration | undefined;
  isMobile: boolean;
}

import styles from "./styles.module.css";

const WebChat = (props: Properties) => {
  const [apiKey, setApiKey] = useState("");
  const [tab, setTab] = useState("embed");
  const intl = useIntl();
  const [present] = useIonToast();

  useEffect(() => {
    if (props.webchat) {
      const accounts = props.webchat.accounts;
      if (accounts && accounts.length > 0) {
        const account = accounts[0];
        if (account.apiKey) {
          setApiKey(account.apiKey);
        }
      }
    }
  }, [props.webchat]);

  const url = `${config.HTML_ROOT}/web-chat?id=${apiKey}`;
  const onCopy = async () => {
    await navigator.clipboard.writeText(url);
    return present({
      message: intl.formatMessage({ id: "common.copyLink" }),
      duration: 6000,
      color: "light",
      position: "top",
    });
  };
  const embedCode = `<script>(function(){const onLoad=function(){const script=document.createElement("script");script.src="${config.API_ROOT}/embed.min.js";script.id="visitowc";script.setAttribute("data-domain","visitoai.com");script.setAttribute("data-apiKey","${apiKey}");document.body.appendChild(script);};if(document.readyState==="complete"){onLoad();}else{window.addEventListener("load",onLoad);}})();</script>`;
  const onCopyEmbed = async () => {
    await navigator.clipboard.writeText(embedCode);
    return present({
      message: intl.formatMessage({ id: "common.copyCode" }),
      duration: 6000,
      color: "light",
      position: "top",
    });
  };

  const onCopyApiKey = async () => {
    await navigator.clipboard.writeText(apiKey);
    return present({
      message: intl.formatMessage({ id: "common.copyCode" }),
      duration: 6000,
      color: "light",
      position: "top",
    });
  };
  const onOpen = async () => {
    window.open(url, "_blank");
  };

  return apiKey ? (
    <>
      <div className={props.isMobile ? "view-pane" : "visito-card"}>
        <div className={props.isMobile ? "view-pane-body" : "visito-card-body"}>
          <div className={`${styles.titleDisplay}`}>
            <div className={`${styles.titleItem} ${styles.logo}`}>
              <div className={`${styles.logo} web-logo`}></div>
            </div>
            <h3 className="fs-3">
              <FormattedMessage id={`integrations.webChatName`} />
            </h3>
          </div>
          <Nav
            variant="tabs"
            defaultActiveKey="/home"
            className={`${styles.navTabs} mb-3 mt-3`}
          >
            {["embed", "share", "wordpress"].map((t) => (
              <Nav.Item key={t}>
                <Nav.Link
                  active={t === tab}
                  className=""
                  onClick={() => setTab(t)}
                >
                  <FormattedMessage id={`common.${t}`} />
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
          {(() => {
            switch (tab) {
              case "embed":
                return (
                  <>
                    <h3 className="fs-4">
                      <FormattedMessage id="integrations.embedChat" />
                    </h3>
                    <p
                      className="fs-3 mt-3 color-step-600"
                      style={props.isMobile ? {} : { textWrap: "nowrap" }}
                    >
                      <FormattedMessage id="integrations.embedChatDescription" />
                    </p>
                    <pre className={`${styles.webChatUrl} fs-2 mt-2`}>
                      {embedCode}
                    </pre>
                    <div className="flex gap-2 mt-2">
                      <button
                        className="btn-visito-primary btn btn-sm"
                        onClick={onCopyEmbed}
                      >
                        <FormattedMessage id="integrations.copyLink" />
                      </button>
                    </div>
                  </>
                );
              case "wordpress":
                return (
                  <>
                    <h3 className="fs-4">
                      <FormattedMessage id="integrations.wordpress" />
                    </h3>
                    <p className="fs-3 mt-3 color-step-600">
                      <FormattedMessage id="integrations.wordpressInstructions" />
                    </p>
                    <ol
                      className="fs-3 mt-3 color-step-600"
                      style={{ paddingLeft: "1.5rem" }}
                    >
                      <li>
                        <FormattedMessage id="integrations.wordpressStep1" />{" "}
                        <a
                          href="https://github.com/visito-ai/wordpress-plugin/archive/refs/tags/v1.0.0.zip"
                          target="_blank"
                        >
                          Visito AI WordPress Plugin
                        </a>
                      </li>
                      <li>
                        <FormattedMessage id="integrations.wordpressStep2" />
                      </li>
                      <li>
                        <FormattedMessage id="integrations.wordpressStep3" />
                      </li>
                    </ol>
                    <pre className={`${styles.webChatUrl} fs-2 mt-2`}>
                      {apiKey}
                    </pre>
                    <div className="flex gap-2 mt-2">
                      <button
                        className="btn-visito-primary btn btn-sm"
                        onClick={onCopyApiKey}
                      >
                        <FormattedMessage id="integrations.copyLink" />
                      </button>
                    </div>
                  </>
                );

              default:
                return (
                  <>
                    <h3 className="fs-4">
                      <FormattedMessage id="integrations.webChat" />
                    </h3>
                    <p
                      className="fs-3 mt-3 color-step-600"
                      style={props.isMobile ? {} : { textWrap: "nowrap" }}
                    >
                      <FormattedMessage id="integrations.webChatDescription" />
                    </p>
                    <pre className={`${styles.webChatUrl} fs-2 mt-2`}>
                      {url}
                    </pre>
                    <div className="flex gap-2 mt-2">
                      <button
                        className="btn-visito-primary btn btn-sm"
                        onClick={onCopy}
                      >
                        <FormattedMessage id="integrations.copyLink" />
                      </button>
                      <button
                        className="btn-visito-primary btn btn-sm"
                        onClick={onOpen}
                      >
                        <FormattedMessage id="integrations.visitLink" />
                      </button>
                    </div>
                  </>
                );
            }
          })()}
        </div>
      </div>
    </>
  ) : null;
};

export default WebChat;
