import { useState } from "react";
import {
  IonPage,
  IonHeader,
  IonBackButton,
  IonToolbar,
  IonButtons,
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonToggle,
  IonLoading,
  IonAlert,
  IonTitle,
} from "@ionic/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useIntl } from "react-intl";

import OutboundEventsApi from "../../../../api/outbound-events";
import SkeletonLoader from "../../../SkeletonLoader";

import styles from "./styles.module.css";
import outboundStyles from "../../styles.module.css";
import MessageStatus from "../badge";
import OutboundLogsProps from "../types";

const OutboundLogs = (props: OutboundLogsProps) => {
  const { id } = props.match.params;

  const [openAlert, setOpenAlert] = useState(false);

  const { formatMessage } = useIntl();

  const {
    isLoading: isLoadingEventLogs,
    data: outboundEventLogsData,
    refetch: refetchEventLogs,
  } = useQuery({
    queryFn: () => OutboundEventsApi.getOutboundEventsLogs({ id, limit: 50 }),
    queryKey: ["getOutboundEventsLogs", id],
    refetchOnWindowFocus: false,
  });

  const onHideAlert = () => {
    return setOpenAlert(() => false);
  };

  const { mutate: mutateUpdateEventStatus, isPending: isUpdatingEvent } =
    useMutation({
      // @ts-ignore
      mutationFn: OutboundEventsApi.putOutboundEvent,
      onSuccess: () => {
        refetchEventLogs();
        return onHideAlert();
      },
      onError: () => {
        onHideAlert();
      },
    });

  const onDisplayAlert = async (active: boolean) => {
    if (!active) {
      return setOpenAlert(() => true);
    }
    return mutateUpdateEventStatus({ id, active: true });
  };

  const onDisableEvent = () => {
    mutateUpdateEventStatus({ id, active: false });
  };

  return (
    <IonPage id="main-content">
      <IonHeader>
        <IonToolbar>
          <div className={styles.header}>
            <IonButtons slot="start">
              <IonBackButton text="" defaultHref="/outbound" />
            </IonButtons>
            <IonTitle>{outboundEventLogsData?.outboundEvent.name}</IonTitle>
            <div
              className={`flex align-items-center ${styles.headerToggle}`}
              slot="end"
            >
              <IonToggle
                checked={outboundEventLogsData?.outboundEvent.active}
                onIonChange={(ev) => onDisplayAlert(ev.detail.checked)}
                mode="ios"
                className="ios visito-toggle"
              />
            </div>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen color="light" className="ion-padding">
        {isLoadingEventLogs ? (
          <SkeletonLoader rows={8} />
        ) : (
          <IonList inset>
            {outboundEventLogsData?.logs.map((o) => (
              <IonItem
                key={o.id}
                lines="full"
                button
                disabled={!o.booking.contactId}
                detail
                routerLink={`/chat/${o.booking.contactId}`}
              >
                <IonLabel className="ion-text-wrap">
                  <div className="flex flex-column gap-3 py-2">
                    <p className="fs-1">{o.timestamp}</p>
                    <div className="flex flex-column gap-2">
                      <p className="fs-3 mb-0">
                        {o.booking.fullName || o.contact.name}
                      </p>
                      {o?.booking?.phoneNumber && (
                        <div className="flex align-items-center gap-1">
                          <div
                            className="user-platform whatsapp-logo"
                            style={{ height: "16px", width: "16px" }}
                          />
                          <p className={`${styles.phone} fs-2`}>
                            {o.booking.phoneNumber}
                          </p>
                        </div>
                      )}
                    </div>
                    <MessageStatus type={o.message?.status || "error"} />
                  </div>
                </IonLabel>
              </IonItem>
            ))}
          </IonList>
        )}
      </IonContent>

      <IonLoading
        isOpen={isUpdatingEvent && openAlert}
        message={formatMessage({ id: "outbound.disabling" })}
        spinner="circles"
      />
      <IonAlert
        isOpen={openAlert}
        header={formatMessage({ id: "outbound.inactiveTitle" })}
        message={formatMessage({ id: "outbound.inactiveDesc" })}
        buttons={[
          {
            text: formatMessage({ id: "common.cancel" }),
            role: "cancel",
            handler: onHideAlert,
            cssClass: `${outboundStyles.alertCancel} ${outboundStyles.alertBtn}`,
          },
          {
            text: formatMessage({ id: "common.confirmInactive" }),
            role: "confirm",
            handler: onDisableEvent,
            cssClass: `${outboundStyles.alertConfirm} ${outboundStyles.alertBtn}`,
          },
        ]}
        onDidDismiss={onHideAlert}
      />
    </IonPage>
  );
};

export default OutboundLogs;
