import { IconTrash } from "@tabler/icons-react";

import styles from "./styles.module.css";

type Props = {
  file: string | File;
  disableDelete?: boolean;
  onDelete?: () => void;
};

export default function ImagePreview({ file, disableDelete = false, onDelete }: Props) {
  const fileUrl = file instanceof File ? URL.createObjectURL(file) : file;

  return (
    <div className={`w-100 flex flex-column gap-2`}>
      {onDelete && !disableDelete && (
        <button type="button" onClick={onDelete} className="flex align-items-center justify-content-end gap-1 color-danger bg-color-0 w-max ml-auto">
          Delete File
          <IconTrash size={20} />
        </button>
      )}
      <img
        src={fileUrl}
        className={`w-100 object-contain ${styles.imagePreview}`}
      />
    </div>
  );
}
