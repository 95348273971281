import { IonToggle } from "@ionic/react";
import { IconTrash } from "@tabler/icons-react";

import { Embedding } from "../../../interfaces";
import extractFilename from "./utils";

type FileProps = {
  file: Embedding;
  onDelete: (data: { embeddingId: string; fileName: string }) => void;
  onEnableFile: (data: { embeddingId: string; active: boolean }) => void;
};

const File = ({ file, onDelete, onEnableFile }: FileProps) => (
  <div className="flex align-items-center justify-content-between gap-2">
    <a href={file.url} target="_blank" rel="noreferrer" className="fs-3 break-all">
      {extractFilename(String(file.url))}
    </a>
    <div className="flex align-items-center gap-3">
      <IonToggle
        checked={file?.active || false}
        onIonChange={(ev) => onEnableFile({ embeddingId: file?._id || file?.embeddingId || "", active: ev.detail.checked  })}
        mode="ios"
        className="ios visito-toggle"
      />
      <IconTrash
        size={21}
        style={{ color: "var(--visito-color-danger)" }}
        role="button"
        onClick={() => onDelete({
          embeddingId: file?._id || file?.embeddingId || "",
          fileName: extractFilename(String(file.url)) as string })
        }
      />
    </div>
  </div>
);

export default File;
