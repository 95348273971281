import classNames from "classnames";
import { Contact } from "../../../interfaces";

interface PropTypes {
  contact: Contact;
  defaultClass: string;
}

const ContactPlatform = ({ contact, defaultClass }: PropTypes) => {
  return (
    <div
      className={classNames(defaultClass, {
        "instagram-logo": contact.type === "ig",
        "whatsapp-logo": contact.type === "wa",
        "messenger-logo": contact.type === "fm",
        "airbnb-logo": contact.type === "ab",
        "web-logo": contact.type === "web" || contact.type === "play",
        "voice-logo": contact.type === "voice",
        online: contact.type === "web" && contact.isOnline,
        offline: contact.type === "web" && !contact.isOnline,
      })}
    ></div>
  );
};

export default ContactPlatform;
