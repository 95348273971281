import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import {
  IonHeader,
  IonToolbar,
  isPlatform,
  IonPage,
  IonContent,
  IonTitle,
  IonList,
  IonItem,
  IonLabel,
  IonText,
  useIonToast,
} from "@ionic/react";

import styles from "../../../pages/auth/styles.module.css";

// Reac int
import { FormattedMessage, useIntl } from "react-intl";

// Types
import { User, Settings, apiResult } from "../../../interfaces";

// Styles
import headerStyles from "../../../theme/header.module.css";

// Api
import Api from "../../../api/account";

// Components
import SkeletonLoader from "../../SkeletonLoader";

import { deserialize } from "../../../utils/serialize";

// Other
const isMobile = isPlatform("android") || isPlatform("ios");

const Header: React.FC = () => {
  return (
    <div>
      <h1 className={headerStyles.pageTitle}>
        <FormattedMessage id="account.createAccount.title" />
      </h1>
      <h3 className={headerStyles.pageDescription}>
        <FormattedMessage id="account.createAccount.pageDescription" />
      </h3>
    </div>
  );
};

const MobileHeader: React.FC = () => {
  return (
    <div>
      <div className={headerStyles.header}>
        <h1 className={headerStyles.mobileTitle}>
          <FormattedMessage id="account.createAccount.title" />
        </h1>
        <h3 className={headerStyles.mobileDescription}>
          <FormattedMessage id="account.createAccount.pageDescription" />
        </h3>
      </div>
    </div>
  );
};

const MobileTitle = () => {
  return (
    <IonHeader>
      <IonToolbar>
        <IonTitle>
          <FormattedMessage id="account.createAccount.title" />
        </IonTitle>
      </IonToolbar>
    </IonHeader>
  );
};

interface AcountForm {
  name: string;
}

const CreateAccount: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const history = useHistory();
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [present] = useIonToast();
  const [formData, setFormData] = useState<AcountForm>({
    name: "",
  });

  const createAccount = async () => {
    const { data, err } = await Api.createAccount({ account: formData });
    setLoading(false);
    if (data) {
      props.refreshUser();
      history.push("/ai");
      props.switchCompany({ companyId: data.id });

      return present({
        message: intl.formatMessage({ id: "common.success" }),
        duration: 5000,
        color: "light",
        position: "top",
        cssClass: "success-toast",
      });
    }
    if (err) {
      return present({
        message:
          err.error[intl.locale] ||
          intl.formatMessage({ id: "common.tryItLater" }),
        duration: 10000,
        color: "danger",
        position: "top",
      });
    }
  };

  const handleForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    createAccount();
  };

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value, id } = e.currentTarget;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  return (
    <IonPage id="main-content">
      {isMobile && <MobileTitle />}
      <IonContent
        {...(isMobile
          ? { fullscreen: true, color: "light", className: "ion-padding" }
          : {})}
      >
        <div className={isMobile ? "view-wrapper" : "page-wrapper container"}>
          {isMobile ? (
            <>
              <div className="view-pane">
                <MobileHeader />
              </div>
            </>
          ) : (
            <>
              <div className="d-flex justify-content-center align-items-center">
                <div className="col-12 col-md-6 col-lg-4 mt-4">
                  <Header />
                  <form className={styles.authForm} onSubmit={handleForm}>
                    <div className={styles.inputHolder}>
                      <label>
                        <FormattedMessage id="auth.register.company" />
                      </label>
                      <input
                        type="text"
                        id="name"
                        placeholder="ACME Inc"
                        value={formData.name}
                        onChange={(e) => handleChange(e)}
                        className="form-control"
                        required
                      />
                    </div>
                    <button
                      type="submit"
                      className="btn btn-visito-primary w-100 py-8 mb- mt-4 rounded-2"
                      disabled={loading}
                    >
                      {loading ? (
                        <Spinner animation="border" role="status" size="sm">
                          <span className="visually-hidden">
                            <FormattedMessage id="common.loading" />
                            ...
                          </span>
                        </Spinner>
                      ) : (
                        <FormattedMessage id="common.create" />
                      )}
                    </button>
                  </form>
                </div>
              </div>
            </>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

interface ILayoutProps {
  refreshUser: Function;
  switchCompany: Function;
}

export default CreateAccount;

/*

<form className={styles.authForm} onSubmit={handleForm}>
                <div className={styles.inputHolder}>
                  <label>
                    <FormattedMessage id="auth.register.company" />
                  </label>
                  <input
                    type="text"
                    id="hotelName"
                    placeholder="ACME Inc"
                    value={formData.hotelName}
                    onChange={(e) => handleChange(e)}
                    className="form-control"
                    required
                  />
                </div>
                <div className={styles.inputHolder}>
                  <label>
                    <FormattedMessage id="auth.register.web" />
                  </label>
                  <input
                    placeholder="https://example.com"
                    type="text"
                    id="website"
                    value={formData.website}
                    onChange={(e) => handleChange(e)}
                    className="form-control"
                    autoComplete="off"
                  />
                </div>

                <div className={styles.inputHolder}>
                  <label>
                    <FormattedMessage id="auth.register.email" />
                  </label>
                  <input
                    placeholder="name@example.com"
                    type="email"
                    id="email"
                    value={formData.email}
                    onChange={(e) => handleChange(e)}
                    className="form-control"
                    required
                  />
                </div>
                <div
                  className={`${styles.inputHolder} ${styles.passwordHolder}`}
                >
                  <label>
                    <FormattedMessage id="auth.register.password" />
                  </label>
                  <input
                    type={show ? "text" : "password"}
                    placeholder="Password"
                    className="form-control"
                    id="password"
                    value={formData.password}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                  <span
                    onClick={() => setShow(!show)}
                    className={styles.togglePassword}
                  ></span>
                </div>

                <div className="color-step-500 fs-2 mt-3">
                  <FormattedMessage id="auth.register.continuing" />{" "}
                  <a
                    href="https://www.visitoai.com/en/terms"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FormattedMessage id="auth.register.loginTerms" />
                  </a>{" "}
                  <FormattedMessage id="common.and" />{" "}
                  <a
                    href="https://www.visitoai.com/en/privacy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FormattedMessage id="auth.register.loginPrivacy" />
                  </a>
                </div>

                {logInError && (
                  <p>
                    <IonText className="mb-1" color="danger">
                      {logInError}
                    </IonText>
                  </p>
                )}

                <button
                  type="submit"
                  className="btn btn-visito-primary w-100 py-8 mb- mt-4 rounded-2"
                >
                  {loading ? (
                    <Spinner animation="border" role="status" size="sm">
                      <span className="visually-hidden">
                        <FormattedMessage id="common.loading" />
                        ...
                      </span>
                    </Spinner>
                  ) : (
                    <FormattedMessage id="auth.register.register" />
                  )}
                </button>

                <div className="text-center mt-4">
                  <div className="color-step-500">
                    <FormattedMessage id="auth.register.loginLabel" />
                  </div>
                  <div>
                    <Link to={registerLink}>
                      <FormattedMessage id="auth.register.login" />
                    </Link>
                  </div>
                </div>
              </form>
              */
